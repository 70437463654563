// @mui
import { AppBar, Box, Button, Card, CardContent, CardHeader, Container, Divider, FormControlLabel, Grid, IconButton, Menu, MenuItem, Select, Stack, Switch, TextField, Toolbar, Typography } from '@mui/material';

// components
import Page from '../../components/Page';
import { useCallback, useEffect, useRef, useState } from 'react';
import _ from "lodash";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '@ag-grid-community/styles/ag-grid.min.css';
import '@ag-grid-community/styles/ag-theme-alpine.min.css';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useLocales from '../../hooks/useLocales';
import SmartPulseWorkspace from '../../components/test/SmartPulseWorkspace';
import GipPlanningContent from '../../components/test/GipPlanningContent';

// ----------------------------------------------------------------------

const GipPlanningTest = () => {

    const { translate } = useLocales();
    const themeStretch = true
    const [tab, setTab] = useState(0)
    const [time, setTime] = useState('');
    const [workspace, setWorkspace] = useState('smartPulse Workspace');
    const [anchorWidget, setAnchorWidget] = useState<null | HTMLElement>(null);
    const [anchorWorkspace, setAnchorWorkspace] = useState<null | HTMLElement>(null);
    const openWidget = Boolean(anchorWidget);
    const openWorkspace = Boolean(anchorWorkspace);
    const [company, setCompany] = useState(0);

    const workspaceOneRef = useRef<any>();
    const workspaceTwoRef = useRef<any>();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, type: string) => {

        if (type == 'widget') {
            setAnchorWidget(event.currentTarget);
        }
        else if (type == 'workspace') {
            setAnchorWorkspace(event.currentTarget);
        }

    };

    const handleClose = () => {
        setAnchorWidget(null);
        setAnchorWorkspace(null);
    };

    const handleCompanyChange = (event: any) => {
        setCompany(event.target.value);
    };

    const selectWorkspace = (workspace: number) => {
        setTab(workspace)
        setAnchorWorkspace(null);

        if (workspace == 0) {
            setWorkspace("smartPulse Workspace")
        }
        else if (workspace == 1) {
            setWorkspace("Workspace 1")
        }
        else if (workspace == 2) {
            setWorkspace("Workspace 2")
        }

        setTimeout(
            () => { window.dispatchEvent(new Event('resize')); },
            25
        )
    }

    const addWidget = (widget: number) => {
        if (tab == 1) {
            if (widget == 1) {
                workspaceOneRef.current.addOffer();
            }
            else if (widget == 2) {
                workspaceOneRef.current.addTicker();
            }
            else if (widget == 3) {
                workspaceOneRef.current.addBuySell();
            }
            else if (widget == 4) {
                workspaceOneRef.current.addGraph();
            }
            else if (widget == 5) {
                workspaceOneRef.current.addGrid();
            }
            else if (widget == 6) {
                workspaceOneRef.current.addMyOffers();
            }
        }
        else if (tab == 2) {
            if (widget == 1) {
                workspaceTwoRef.current.addOffer();
            }
            else if (widget == 2) {
                workspaceTwoRef.current.addTicker();
            }
            else if (widget == 3) {
                workspaceTwoRef.current.addBuySell();
            }
            else if (widget == 4) {
                workspaceTwoRef.current.addGraph();
            }
            else if (widget == 5) {
                workspaceTwoRef.current.addGrid();
            }
            else if (widget == 6) {
                workspaceTwoRef.current.addMyOffers();
            }
        }
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace("AM", "").replace("PM", ""));
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        // <Page title={"Gip Planning Test"} style={{ marginTop: '10px' }}>
            <Container maxWidth={themeStretch ? false : 'lg'} >
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" color='default'>
                        <Toolbar variant='dense'>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <AccountBalanceIcon style={{ fontSize: '18px' }} />
                                <Select variant="standard"
                                    value={company}
                                    onChange={handleCompanyChange}
                                    sx={{ mr: 2, ml: 2 }}
                                    size="small">
                                    <MenuItem value={0}>
                                        <Typography gutterBottom variant="caption">
                                            Gruptaki Tüm Sirketlerin Verilerini Göster
                                        </Typography>
                                    </MenuItem>
                                </Select>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <ArrowBackIosNewIcon style={{ fontSize: '18px' }} />
                                <div style={{ marginLeft: '3px', marginRight: '3px' }}>
                                    <Typography gutterBottom variant="caption">
                                        03.08.22 07:00 - 03.08.22 23:00
                                    </Typography>
                                </div>
                                <ArrowForwardIosIcon style={{ fontSize: '18px' }} />
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div style={{ flex: 1 }}></div>
                            <Divider orientation="vertical" flexItem />
                            <Button variant="text" startIcon={<MiscellaneousServicesIcon />} sx={{ pr: 2, pl: 2 }}>
                                İşlemler
                            </Button>
                            <Divider orientation="vertical" flexItem />
                            <Button
                                sx={{ pr: 1 }}
                                aria-controls={openWorkspace ? 'workspace-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openWorkspace ? 'true' : undefined}
                                onClick={event => handleClick(event, 'workspace')}
                            >
                                <PersonalVideoIcon />
                                <TextField variant="standard" value={workspace} sx={{ pl: 1 }} />
                            </Button>
                            <Menu
                                id="workspace-menu"
                                anchorEl={anchorWorkspace}
                                open={openWorkspace}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose}> <AddToQueueIcon sx={{ pr: 1 }} /> Add New Workspace</MenuItem>
                                <Divider flexItem />
                                <MenuItem onClick={() => selectWorkspace(0)}> <PersonalVideoIcon sx={{ pr: 1 }} /> smartPulse Workspace</MenuItem>
                                <MenuItem onClick={() => selectWorkspace(1)}> <LooksOneIcon sx={{ pr: 1 }} />
                                    Workspace 1
                                    <EditIcon fontSize="small" sx={{ pl: 1 }} />
                                    <DeleteIcon fontSize="small" sx={{ pl: 1 }} />
                                </MenuItem>
                                <MenuItem onClick={() => selectWorkspace(2)}> <LooksTwoIcon sx={{ pr: 1 }} />
                                    Workspace 2
                                    <EditIcon fontSize="small" sx={{ pl: 1 }} />
                                    <DeleteIcon fontSize="small" sx={{ pl: 1 }} />
                                </MenuItem>
                            </Menu>
                            <Divider orientation="vertical" flexItem />
                            <Button sx={{ pr: 1 }}>
                                <CastConnectedIcon />
                            </Button>
                            <Divider orientation="vertical" flexItem />
                            <Button
                                sx={{ pr: 1 }}
                                aria-controls={openWidget ? 'widget-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openWidget ? 'true' : undefined}
                                onClick={event => handleClick(event, 'widget')}
                            >
                                <DashboardCustomizeIcon />
                            </Button>
                            <Menu
                                id="widget-menu"
                                anchorEl={anchorWidget}
                                open={openWidget}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => addWidget(1)}><AddCircleOutlineIcon sx={{ pr: 1 }} fontSize="small" /> Tablo</MenuItem>
                                <MenuItem onClick={() => addWidget(2)}><AddCircleOutlineIcon sx={{ pr: 1 }} fontSize="small" /> Ticker</MenuItem>
                                <MenuItem onClick={() => addWidget(3)}><AddCircleOutlineIcon sx={{ pr: 1 }} fontSize="small" /> İşlemler</MenuItem>
                                <MenuItem onClick={() => addWidget(4)}><AddCircleOutlineIcon sx={{ pr: 1 }} fontSize="small" /> Derinlik Grafiği</MenuItem>
                                <MenuItem onClick={() => addWidget(5)}><AddCircleOutlineIcon sx={{ pr: 1 }} fontSize="small" /> Derinlik Tablosu</MenuItem>
                                <MenuItem onClick={() => addWidget(6)}><AddCircleOutlineIcon sx={{ pr: 1 }} fontSize="small" /> Tekliflerim</MenuItem>
                            </Menu>
                            <Divider orientation="vertical" flexItem />
                            <Typography variant="subtitle2" component="div" sx={{ pl: 1, width: '60px' }}>
                                {time}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Box>
                    {/* <Grid container spacing={1}>
                        <Grid item>
                            <Button type="button" variant={tab == 1 ? "contained" : "text"} onClick={() => {
                                setTab(1)
                                setTimeout(
                                    () => { window.dispatchEvent(new Event('resize')); },
                                    25
                                )
                            }} size="small">
                                Workspace 1
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button type="button" variant={tab == 2 ? "contained" : "text"} onClick={() => {
                                setTab(2)
                                setTimeout(
                                    () => { window.dispatchEvent(new Event('resize')); },
                                    25
                                )
                            }} size="small">
                                Workspace 2
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button type="button" variant="outlined" onClick={() => console.log('burda')} size="small">
                                <AddIcon />
                            </Button>
                        </Grid>
                    </Grid> */}
                    {/*  */}
                    <Box style={{ padding: '10px' }}>
                        {
                            <div style={{ display: tab == 0 ? '' : 'none' }}>
                                <SmartPulseWorkspace />
                            </div>
                        }
                        {
                            <div style={{ display: tab == 1 ? '' : 'none' }}>
                                <GipPlanningContent ref={workspaceOneRef} />
                            </div>
                        }
                        {
                            <div style={{ display: tab == 2 ? '' : 'none' }}>
                                <GipPlanningContent ref={workspaceTwoRef} />
                            </div>
                        }
                    </Box>
                </Box>
            </Container>
        // </Page>
    )
};

export default GipPlanningTest;