import { CellStyle, ColDef, GridApi } from '@ag-grid-community/core';
import { IProgressInfo } from 'src/components/ProgressList/types';

export enum MeasureUnit {
  KW,
  MW,
}

export enum EnabledPeriods {
  P60 = 60,
  P30 = 30,
  P15 = 15,
}

export const enum PfPopup {
  None,
  PredictionHistory,
  LockSettings,
  PredictionUploads,
  ChangeSummary,
  TemplateDownload,
  LockProgress,
}

export const enum UnitType {
  PP,
  CMP,
  GRP,
}

export type PowerPlantColDef = ColDef & { powerPlantData: IPowerPlantData };

interface IDeliveryBase<T = Date> {
  deliveryStart: T;
  deliveryStartOffset: number;
  deliveryEnd: T;
  deliveryEndOffset: number;
}

export interface IDelivery extends IDeliveryBase {
  period: number;
}

export interface IPrediction<T = Date> extends IDeliveryBase<T> {
  value: number | null;
}

export interface IProductionForecastData {
  forecastData: IForecastData[];
  measureUnit: MeasureUnit | string;
  providers: IProvider[];
  expiration?: Date | null;
  gopExpiration?: Date | null;
  groupAutoLockSettings: boolean;
  groupLockStart?: Date;
  groupLockEnd?: Date;
  groupLockIntradayTime?: number;
  lockedPlants: number[];
}

export interface IProvider {
  providerName: string;
  providerKey: string;
  unitNo?: number | null;
}

export interface IForecastData extends IPowerPlantData {
  // date: string;
  description: string;
  predictions: IPrediction[];
  // predictionHistories: IPredictionHistory;
  deliveryLocks: IDeliveryLock[];
  updateUsername: string;
  updateUserId: number;
  updateDate?: Date;
  productionDiversity: string;
}

export interface IPowerPlantData {
  companyId: number;
  powerPlantId: number;
  powerPlantName: string;
  powerPlantType: number;
  totalPrediction: number;
  limit: number;
  lowerLimit: number;
  updateInfo?: string;
}

export interface ICellInfo {
  rowId: string;
  cellKey: string;
  powerPlantId: number;
}

export interface IPredictionHistory {
  // unitNo: number;
  history: IPredictionHistoryDetails[];
  lastPredictionBeforeLock?: IPredictionHistoryDetails | null;
}

export interface IPredictionHistoryDetails extends IPrediction {
  predictionSource: string;
  isLastPrediction: boolean;
  createDate: Date;
  // createDateOffset: number;
  createUser?: number;
  username: string;
}

export interface ILockStatus {
  isLocked: boolean;
  isTopNeighborLocked: boolean;
  isBottomNeighborLocked: boolean;
}

export interface ILockedCell {
  fieldName: string;
  delivery: IDelivery;
}

export type RowType = 'source' | 'prediction' | 'total'; //| 'save' | 'note' | 'update';
export interface IRowData {
  delivery?: IDelivery;
  type: RowType;
  rowTitle?: string;
  isHeaderRow?: boolean;
  isUtilRow?: boolean;
  cellStyle?: CellStyle;
  [key: string]: any;
}

export interface IUnitLockInfo {
  unitNo: number;
  unitName: string;
  deliveryLocks: IDeliveryLock[];
  delivery: IDelivery;
}

export interface IDeliveryLock<T = Date> extends IDeliveryBase<T> {
  lockState: number;
}

export interface IPowerPlantPredictionLocks {
  powerPlantId: number;
  deliveryLocks: IDeliveryLock[];
}

export interface IUnitForecastData<T = Date> {
  unitNo: number;
  // unitType: number; in the future maybe
  predictions: IPrediction<T>[];
}

export interface IChangeSummary {
  powerPlantId: number;
  name: string;
  total: number;
  newTotal: number;
}

export interface IAgGridApiContainer {
  grid?: GridApi;
}

enum UploadDataTypes {
  ProductionForecast = 0,
  ConsumptionForecast = 1,
  MCPForecast = 2,
  PaymentPercentage = 3,
  CounterpartyBa = 4,
  BilateralAgreement = 5,
  PowerPlantQuestion = 6,
  ExtraPosition = 7,
  PowerPlantBalancing = 8,
}
export interface IUploadedFile {
  Id: number;
  DataType: UploadDataTypes;
  StartDate: Date;
  EndDate: Date;
  FileName: string;
  ExcelData: string;
  PredictionKey: string;
  IsProcessed: boolean;
  CreateDate: Date;
  CreateUser: number;
  SaveAsGop: boolean;
  ResultMessage: string;
  CreateUserName: string;
}

export interface ILockProgressInfo {
  actionType: 'lock' | 'unlock';
  list: IProgressInfo[];
}
