import { ColDef, ColGroupDef, Column, GridApi } from "@ag-grid-community/core";
import _ from "lodash";
import { IPreviewWidgetSettings } from "src/components/gip-planning/WorkSpacePreviewTemplate";
import { Constants } from "src/constants";
import { EContractType } from "src/enums/contract-type.enum";
import { WidgetSettingsEnum } from "src/enums/widget-settings-enum";
import { EWidgetType } from "src/enums/widget-type.enum";
import { WidgetContractSourceTypeEnum, WidgetSelectOption } from "src/hooks/useContractDetails";
import { UserWidgetLimit } from "src/models/gip-planning/user-widget-limit";
import { UserColumn } from "src/models/gip-planning/UserColumnsModel";
import { GridLayoutModel, GridWidgetModel } from "src/models/grid-layout.model";
import { IContractShortInfo } from "src/old/src/redux/api/types/gipPlanningWebSocketTypes";
import { IContractSelectOption } from "src/old/src/redux/slice/contractSelectData";
import { ActiveBoardWidgetInfo, WorkSpaceData } from "src/old/src/redux/slice/workspaceData";
import store from "src/redux/store";
import * as Utils from "src/utils";

export function handleColumnsVisible<T extends ColDef | ColGroupDef>(
   userSelectedColumns: Array<UserColumn>,
   filteredColumns: Array<T>,
   alwaysShowColumns?: Array<string>,
   hideAlwaysButShownOnExcelColumns?: Array<string>
): Array<T> {
   if (userSelectedColumns.length === 0) {
      return filteredColumns;
   }
   // TODO: yeni yapıya geçişte ekran patlamaması için try catch eklendi. Bir süre sonra kaldırılabilir.
   if (!userSelectedColumns.every((col) => _.has(col, "colId"))) return filteredColumns;
   try {
      let isFirstDynamicColumn = true;
      return sortColumnsWithOrder(filteredColumns, userSelectedColumns).map((column) => {
         if (isGroupCol(column)) {
            if (alwaysShowColumns && alwaysShowColumns.includes((column as ColGroupDef)?.groupId ?? "")) {
               (column as ColGroupDef).children.forEach((c: ColDef) => {
                  c.hide = false;
               });
            } else {
               const groupId = (column as ColGroupDef)?.groupId ?? "";
               const isDynamicColumn = groupId.includes("dynamicgroup_");
               let isFirstDynamicColumnChild = true;
               (column as ColGroupDef).children.forEach((c: ColDef) => {
                  const hide =
                     userSelectedColumns.find((userCol) => userCol.colId === getColIdOfColumn(c))?.hide ??
                     !isDynamicColumn;
                  c.hide = hide;
                  if (isDynamicColumn && !hide) {
                     c.headerClass =
                        isFirstDynamicColumnChild && isFirstDynamicColumn ? "first-dynamic-header" : "right-header-np";
                     c.cellClass =
                        isFirstDynamicColumnChild && isFirstDynamicColumn ? "first-dynamic-column" : "right-aligned";
                     isFirstDynamicColumnChild = false;
                  }
               });
               if (
                  isDynamicColumn &&
                  (column as ColGroupDef).children &&
                  (column as ColGroupDef).children.length > 0 &&
                  !isFirstDynamicColumnChild
               ) {
                  column.headerClass = isFirstDynamicColumn ? "first-dynamic-header" : "right-header-np";
                  isFirstDynamicColumn = false;
               }
            }
         } else if (
            hideAlwaysButShownOnExcelColumns &&
            hideAlwaysButShownOnExcelColumns.includes(getColIdOfColumn(column))
         ) {
            (column as ColDef).hide = true;
         } else {
            (column as ColDef).hide =
               userSelectedColumns.find((userCol) => userCol.colId === getColIdOfColumn(column))?.hide ?? true;
         }
         return column;
      });
   } catch {
      return filteredColumns;
   }
}

export const getColIdOfColumn = (column: ColDef | ColGroupDef) => {
   if (isGroupCol(column)) {
      return (column as ColGroupDef)?.groupId ?? "";
   }
   return (column as ColDef)?.colId ?? (column as ColGroupDef)?.groupId ?? (column as ColDef)?.field ?? "";
};

export function sortColumnsWithOrder<T extends ColDef | ColGroupDef>(columns: T[], orderedColumns: UserColumn[]) {
   return _.sortBy(columns, (column) => {
      if (isGroupCol(column) && (column as ColGroupDef).children.length > 0) {
         const groupCol = column as ColGroupDef;
         groupCol.children = _.orderBy(groupCol.children, (child) => {
            return orderedColumns.findIndex((c) => c.colId === getColIdOfColumn(child));
         });
         return orderedColumns.findIndex((c) => c.colId === getColIdOfColumn(groupCol.children[0]));
      }
      return orderedColumns.findIndex((c) => c.colId === getColIdOfColumn(column));
   });
}

export const isGroupCol = (column: ColDef | ColGroupDef) => Array.isArray((column as ColGroupDef).children);

export const saveNewUserSelectedColumns = async (
   allColumnState: (ColDef | ColGroupDef)[],
   allColumnOrder: Column[],
   setUserSelectedColumns: Function,
   options: {
      showLoading?: boolean;
      successMessage: string;
   },
   gridColumnApi?: GridApi
) => {
   const selectedColumns: UserColumn[] = [];
   allColumnState.forEach((column) => {
      if ((column as ColGroupDef)?.children) {
         for (const childColumn of (column as ColGroupDef).children as ColDef[]) {
            selectedColumns.push({
               colId: childColumn.colId!,
               hide: childColumn.hide ?? false,
               width: gridColumnApi?.getColumn(getColIdOfColumn(childColumn))?.getActualWidth(),
            });
         }
      } else {
         selectedColumns.push({
            colId: (column as ColDef).colId!,
            hide: (column as ColDef).hide ?? false,
            width: gridColumnApi?.getColumn(getColIdOfColumn(column))?.getActualWidth(),
         });
      }
   });

   const newUserSelectedColumns = _.orderBy(selectedColumns, (col) =>
      allColumnOrder.findIndex((colState) => colState.getColId() === col.colId)
   );

   const showLoading = options?.showLoading ?? true;

   setTimeout(async () => {
      if (showLoading) {
         Utils.ShowLoadingOverlay();
      }
      try {
         await setUserSelectedColumns(newUserSelectedColumns);
         Utils.showSuccessMessage(options.successMessage);
      } catch (error) {
         let message = "";
         if (error instanceof Error) message = error.message;
         Utils.showErrorMessage(message);
      } finally {
         if (showLoading) {
            Utils.HideLoadingOverlay();
         }
      }
   }, 0);
};

const showingContractWidgets = [
   EWidgetType.DepthWidget,
   EWidgetType.Bots,
   EWidgetType.HistoryWidget,
   EWidgetType.MyOffers,
   EWidgetType.OrderWidget,
];

const DEFAULT_ORDER = 1;

const getContract = (beforeSetting: WidgetSelectOption, openContracts: IContractShortInfo[]) => {
   if (beforeSetting.dependType === WidgetContractSourceTypeEnum.DEPEND_BOARD) return beforeSetting.contractKey;
   if (beforeSetting.dependType === WidgetContractSourceTypeEnum.USE_OPEN_CONTRACT_INDEX)
      return getNextOpenContract(
         getOpenContractIndex(beforeSetting.openContractIndex),
         openContracts,
         beforeSetting.openContractChangeType
      );

   return beforeSetting.contractKey ?? openContracts[0]?.contractDataKey ?? "";
};

export interface IWidgetContract {
   widgetId: string;
   contractName: string;
   useOpenContractIndex: boolean;
   openContractIndex?: number;
}

const getOpenContractIndex = (openContractIndex: number) => (openContractIndex < 0 ? 0 : openContractIndex);

export const getNextOpenContract = (
   contractIndex: number,
   openContracts: IContractShortInfo[],
   openContractChangeType?: EContractType
) => {
   if (openContractChangeType)
      return (
         openContracts.filter((x) => x.contractType === openContractChangeType).at(contractIndex)?.contractDataKey ??
         openContracts[0]?.contractDataKey ??
         ""
      );
   return openContracts[contractIndex]?.contractDataKey ?? "";
};

export const getWidgetContracts = (
   widgets: GridWidgetModel[],
   contractList: IContractShortInfo[],
   contractSelectData: Record<string, IContractSelectOption>,
   activeBoardWidgetList: ActiveBoardWidgetInfo[]
): IWidgetContract[] => {
   const openContracts = contractList.filter((c) => c.isOpenForTrade);
   if (openContracts.length === 0) return [];

   return widgets
      .filter((w) => w.order !== DEFAULT_ORDER && showingContractWidgets.includes(w.widgetType))
      .map<IWidgetContract>((w) => {
         const widgetContractData = contractSelectData[w.id];

         if (widgetContractData) {
            if (widgetContractData.useOpenContractIndex) {
               const contractIndex = getOpenContractIndex(widgetContractData.openContractIndex);
               return {
                  widgetId: w.id,
                  contractName: getNextOpenContract(
                     contractIndex,
                     openContracts,
                     widgetContractData.openContractChangeType
                  ),
                  useOpenContractIndex: true,
                  openContractIndex: contractIndex,
               };
            }
            return {
               widgetId: w.id,
               contractName: widgetContractData.contractKey,
               useOpenContractIndex: false,
            };
         }

         const beforeSetting: WidgetSelectOption | undefined =
            w?.settings?.[WidgetSettingsEnum.ContractSelectOptionKey];
         if (beforeSetting) {
            const contract = getContract(beforeSetting, openContracts);
            return {
               widgetId: w.id,
               contractName: contract ?? "",
               useOpenContractIndex: beforeSetting.dependType === WidgetContractSourceTypeEnum.USE_OPEN_CONTRACT_INDEX,
               openContractIndex:
                  beforeSetting.dependType === WidgetContractSourceTypeEnum.USE_OPEN_CONTRACT_INDEX
                     ? beforeSetting.openContractIndex
                     : undefined,
            };
         }
         return {
            widgetId: w.id,
            contractName: activeBoardWidgetList.length === 1 ? activeBoardWidgetList[0]?.contract ?? "" : "",
            useOpenContractIndex: false,
         };
      });
};

export const tryAddContractColumnAndExcelExport = (
   gridApi: GridApi,
   columnDefs: (ColDef | ColGroupDef)[],
   exportedColumnKeys: string[],
   addedColumns: (ColDef | ColGroupDef)[],
   addedColumnKeys: string[]
) => {
   const newColumns = [...addedColumns, ...columnDefs];
   gridApi.setGridOption("columnDefs", newColumns);
   gridApi.exportDataAsExcel({ columnKeys: [...addedColumnKeys, ...exportedColumnKeys] });
   gridApi.setGridOption("columnDefs", columnDefs);
};

export const getDefaultWidgetLimits = (): UserWidgetLimit[] =>
   Object.values(EWidgetType)
      .filter((x) => _.isNumber(x))
      .map((x) => ({ widgetType: x, allowedCounts: Constants.intraday.defaultWidgetLimit }));

export const getWidgetLimit = (widget: EWidgetType) => {
   const { userWidgetLimits } = store.getState().workspaceData as WorkSpaceData;
   return userWidgetLimits.find((x) => x.widgetType === widget)?.allowedCounts ?? Constants.intraday.defaultWidgetLimit;
};

export const canAddSelectedWidget = (selectedWidget: EWidgetType) => {
   const { workSpaceData, selectedWorkspaceId } = store.getState().workspaceData as WorkSpaceData;
   const ws = workSpaceData.find((x) => x.id === selectedWorkspaceId);
   if (!ws) return false;

   let count = 0;
   for (const layout of ws.layouts) {
      let asCounted = false;
      for (const widget of layout.widgets) {
         if (widget.widgetType === selectedWidget && (!asCounted || widget.isOpenPopUp)) {
            count++;
            asCounted = true;
         }
      }
   }
   // const count = ws.layouts.filter((x) => x.widgets.some((w) => w.widgetType === selectedWidget));
   const limit = getWidgetLimit(selectedWidget);
   return count < limit;
};

export const getPreviewWidgetSettingsFromLocalStorage = (): IPreviewWidgetSettings => {
   try {
      const settings = localStorage.getItem(Constants.intraday.previewWidgetSettingsKey);
      if (!settings) return {};
      return JSON.parse(settings) as IPreviewWidgetSettings;
   } catch {
      return {};
   }
};

export const setPreviewWidgetSettingsToLocalStorage = (widgetId: string, key: string, newValue: any) => {
   const oldValue = getPreviewWidgetSettingsFromLocalStorage();
   const newSettings: IPreviewWidgetSettings = { ...oldValue };
   if (newSettings[widgetId]) {
      newSettings[widgetId][key] = newValue; 
   } else {
      newSettings[widgetId] = {
         [key]: newValue
      };
   }
   localStorage.setItem(Constants.intraday.previewWidgetSettingsKey, JSON.stringify(newSettings));
};

export const clearPreviewWidgetSettings = () => localStorage.removeItem(Constants.intraday.previewWidgetSettingsKey);
