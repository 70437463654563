import React from "react";

// import { ModuleRegistry, AllModules } from "@ag-grid-enterprise/all-modules";
import { LicenseManager } from "@ag-grid-enterprise/core";

import ApplicationWithRouted from "./ApplicationWithRouted";
import { ModuleRegistry } from "@ag-grid-community/core";

// ModuleRegistry.registerModules(AllModules);
LicenseManager.setLicenseKey("Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-061271}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SMARTPULSE_TEKNOLOJI_A.S_(IKITELLI_VD,_VN:7721078553)}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{smartpulse}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{smartpulse}_need_to_be_licensed___{smartpulse}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{6_June_2025}____[v3]_[0102]_MTc0OTE2NDQwMDAwMA==c43f3db419119ce170172748b0f7ff39");

export class Application extends React.PureComponent<{}, {}> {
    render() {
        return (<ApplicationWithRouted>
            {this.props.children}
        </ApplicationWithRouted>);
    }
}

export default Application;