import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-balham.css";

import { AgGridReact, AgGridReactProps } from "@ag-grid-community/react";
import { Box, Dialog } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { GetRowIdFunc, GetRowNodeIdFunc, GetRowIdParams, GridApi, ColDef } from "@ag-grid-community/core";
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";

import { AG_GRID_LOCALE_EN, AG_GRID_LOCALE_ES, AG_GRID_LOCALE_TR } from "@ag-grid-community/locale";
import { ErrorIcon } from "../../old/src/components/icons";
import { GridThemes } from "./GridThemes";

declare module "react" {
   function forwardRef<T, P = {}>(
     render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
   ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IGridProps<TData = any> = AgGridReactProps<TData> & {
   height?: string | 0;
   width?: string;
};

function getThemeName() {
   let themeMode = "light";

   const settingsJson = localStorage.getItem("settings");
   if (settingsJson) {
      const settings = JSON.parse(settingsJson);
      themeMode = settings.themeMode;
   }

   const themeName = themeMode === "dark" ? GridThemes.BalhamDark : GridThemes.Balham;

   return themeName;
}

function getLocaleTexts() {
   const locale = localStorage.getItem("locale") || "tr";
   const localeText = locale === "tr" ? AG_GRID_LOCALE_TR : locale === "es" ? AG_GRID_LOCALE_ES : AG_GRID_LOCALE_EN;

   return localeText;
}

function createGetRowIdProxy(getRowNodeId: GetRowNodeIdFunc): GetRowIdFunc {
   return function (params: GetRowIdParams) {
      return getRowNodeId(params.data);
   };
}

export interface WrappedGridRef<TData = any> {
   api: GridApi<TData> | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WrappedGrid = forwardRef(<TData=any,>(props: IGridProps<TData>, ref: React.Ref<WrappedGridRef<TData>>) => {
   const [warningPasteDialogOpen, setWarningPasteDialogOpen] = useState<boolean>(false);
   const { formatMessage } = useIntl();
   const gridComponentRef = useRef<HTMLDivElement>(null);
   const gridRef = useRef<AgGridReact<TData>>(null);
   const theme = getThemeName();
   const localeTexts = getLocaleTexts();

   const { height = "100%", width = "100%" } = props;

   useImperativeHandle(ref, () => ({
      api: gridRef.current?.api,
   }));

   const getRowIdFunction: GetRowIdFunc | undefined = props.getRowId
      ? props.getRowId
      : props.getRowId
        ? createGetRowIdProxy(props.getRowId)
        : undefined;

   const getContextMenuItems = () => {
      const result = [
         "copy",
         "copyWithHeaders",
         "copyWithGroupHeaders",
         {
            name: formatMessage({ id: "ftpFileEditor.paste", defaultMessage: "Paste" }),
            action: () => {
               setWarningPasteDialogOpen(true);
            },
            shortcut: "Ctrl V",
         },
         "separator",
         "export",
      ];
      return result;
   };
   const defaultColumnDefs = useMemo<ColDef>(
      () => ({
         menuTabs: ["generalMenuTab", "columnsMenuTab"],
         sortable: props.gridOptions?.defaultColDef?.sortable ?? false,
         resizable: props.gridOptions?.defaultColDef?.resizable ?? false,
         filter: false,
         suppressHeaderMenuButton: false,
         suppressHeaderContextMenu: false,
         suppressAutoSize:false,
         suppressColumnsToolPanel:false,
         ...props.defaultColDef,
      }),
      []
   );

   return (
      <>
         <div ref={gridComponentRef} className={theme} style={{ height, width }}>
            <AgGridReact<TData>
               ref={gridRef}
               columnMenu= "legacy"
               localeText={localeTexts}
               getRowId={getRowIdFunction}
               {...props}
               getContextMenuItems={props.getContextMenuItems ?? props.gridOptions?.getContextMenuItems ?? getContextMenuItems}
               defaultColDef={defaultColumnDefs}
            />
         </div>
         {warningPasteDialogOpen && (
            <Dialog open={warningPasteDialogOpen} onClose={() => setWarningPasteDialogOpen(false)}>
               <Box
                  style={{
                     padding: "10px",
                     width: "400px",
                  }}
               >
                  <h2 style={{ fontSize: "16px", marginBottom: "5px" }}>
                     <ErrorIcon style={{ float: "left", color: "blue" }} />{" "}
                     <FormattedMessage id="global.inform" defaultMessage="Inform" />
                  </h2>
                  <br />
                  <p style={{ fontSize: "14px", lineHeight: "1.4", textAlign: "left" }}>
                     <FormattedMessage id="global.informmessage" />
                  </p>
                  <br />
                  <span
                     style={{
                        float: "right",
                        color: "blue",
                        bottom: "10px",
                        right: "10px",
                        position: "absolute",
                        cursor: "pointer",
                     }}
                     onClick={() => setWarningPasteDialogOpen(false)}
                  >
                     <FormattedMessage id="global.close" defaultMessage="Close" />
                  </span>
               </Box>
            </Dialog>
         )}
      </>
   );
});

export default WrappedGrid;
