import {
  CellValueChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
} from '@ag-grid-community/core';
import {
  Alert,
  AppBar,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Popover,
  Select,
  Switch,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  DownloadFileIcon,
  HistoryIcon,
  InfoIcon,
  KeyboardArrowDownIcon,
  SaveIcon,
  UploadFileIcon,
} from 'src/old/src/components/icons';
import DatePicker from 'src/old/src/components/pickers/DatePickerComponent';
import ConsumptionSource from './ConsumptionSource';

import '@ag-grid-community/styles/ag-grid.min.css';
import '@ag-grid-community/styles/ag-theme-balham.min.css';
import moment from 'moment';
import React from 'react';

import WrappedGrid, { WrappedGridRef } from 'src/components/grid';
import styled from '@emotion/styled';
import { Chip, Table, tableCellClasses } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import _ from 'lodash';
import { connect } from 'react-redux';
import { ForecastType } from 'src/enums/forecast-type.enum';
import {
  ExportExcelModel,
  GetConsumptionForecastRequest,
  GetConsumptionProvidersRequest,
  GetMultipleConsumptionForecastsQueryByFacilityDto,
  PredictionModel,
  SaveConsumptionForecastData,
  SaveConsumptionForecastRequest,
} from 'src/models/consumption-forecast.model';
import { convertToBase64 } from 'src/old/src/base/FileUtils';
import * as consumptionForcastService from 'src/services/consumptionForcast/consumptionForecast.service';
import { getConsumptionForecastBots } from 'src/services/alerting/alerting.service';
import * as Utils from 'src/utils';
import GopLock from '../../old/static/img/gopLock.png';
import ConsumptionForecastApproveSave from './ConsumptionForecastApproveSave';
import { ICellInfo } from './types';
import $ from 'jquery';
import { consumptionForecastServiceName } from "src/services/consumptionForcast/consumptionForecast.service";
import NumericCellEditor from 'src/components/grid/NumericCellEditor';

const HistoryTableCell = styled(TableCell)(() => ({
  fontSize: '12px',
  fontWeight: 'bold',
  flex: 1,
  textAlign: 'center',
  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderRadius: 0,
    paddingBottom: '4px',
    paddingTop: '4px',
  },
  [`&.${tableCellClasses.head}:last-of-type`]: {
    borderRadius: 0,
    paddingBottom: '2px',
    paddingTop: '2px',
  },
}));

export const RenderReferanceCell = (row: any) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
      {row?.value}
    </div>
  );
};

type AgGridApi = {
  grid?: GridApi;
};

const StyledTextField = styled(TextField)`
  > input {
    padding: '10px !important';
  }
`;

const ConsumptionForecast = injectIntl(({ common }) => {
  //#region Constants

  const themeStretch = true;

  const styles = {
    page: { marginTop: '10px' },
    topBarIcon: { fontSize: '18px' },
    companySelect: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    dateText: { marginLeft: '3px', marginRight: '3px' },
  };

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

  const getTheme = () => {
    let themeMode = 'light;';
    const settingsjson = localStorage.getItem('settings');
    if (settingsjson) {
      const settings = JSON.parse(settingsjson);
      themeMode = settings.themeMode;
    }
    return themeMode;
  };

  //#endregion Constants

  //#region States
  const [currentDate, setCurrentDate] = useState<Date | null>();
  const [rangeStartDate, setRangeStartDate] = useState<Date | null>();
  const [rangeEndDate, setRangeEndDate] = useState<Date | null>();
  const [referanceDate, setReferanceDate] = useState<Date | null>();
  const [selectedUnit, setSelectedUnit] = useState<number>(1);
  const [selectedConsumers, setSelectedConsumers] = useState<any[]>(
    // JSON.parse(localStorage.getItem('consumptionForecast_selectedConsumers') ?? '[]')
    []
  );
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>();
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [facilityTotalConsumption, setFacilityTotalConsumption] = useState<any[]>([]);
  const [facilityTotalConsumptionChanged, setFacilityTotalConsumptionChanged] = useState<any[]>([]);
  const [isMultipleSave, setIsMultipleSave] = useState<boolean>(false);
  const [singleSaveProps, setSingleSaveProps] = useState<any>();
  //@ts-ignore
  const [commonPermissionCompanies, setCommonPermissionCompanies] = useState(
    //@ts-ignore
    common?.permissions?.companies ?? []
  );
  const [showReferance, setShowReferance] = useState<boolean>(
    localStorage.getItem('consumptionForecast_showReferance') === 'false' ? false : true
  );
  const [showAll, setShowAll] = useState<boolean>(false);
  const [multiply, setMultiply] = useState(1); // 1 hourly, 2 half an hour ,4 quarter an hour
  const [facilityList, setFacilityList] = useState<any>([]);
  const [showPatternDate, setShowPatternDate] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [templateUrl, setTemplateUrl] = useState<string>('');
  const unitRef = useRef(selectedUnit);
  const autocompleteRef = useRef(null);
  // const [selectedForecastType, setSelectedForecastType] = useState(ForecastType.FinalForecast);
  const [unitNoWithProviderKey, setUnitNoWithProviderKey] = useState<any[]>([]);
  const [isFinalForecast, setIsFinalForecast] = useState<boolean>(true);
  const [isAddFinalForecast, setAddIsFinalForecast] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [showUploads, setShowUploads] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const emptyCellsRef = useRef<ICellInfo[]>([]);

  const [extraProvidersLoad, setExtraProvidersLoad] = useState<boolean>(false);
  const [extraProviders, setExtraProviders] = useState<any[]>([]);
  const [providers, setProviders] = useState<any[]>([]);
  const [providersLoad, setProvidersLoad] = useState<boolean>(false);

  //@ts-ignore
  // const commonPermissionCompanies = common?.permissions?.companies ?? [];
  //@ts-ignore
  const isAdmin = common?.permissions?.roles?.includes('ForecastUnlock') ?? false;
  //@ts-ignore
  const appuserid = common?.appuserid ?? 0;
  //@ts-ignore
  const appusername = common?.appusername ?? '';
  //@ts-ignore
  const usergroupid = common?.permissions?.groups[0]?.id ?? 0;

  const [units, setUnits] = useState([
    // { label: 'KW', value: 0 },
    { label: 'MW', value: 1 },
  ]);

  const [menuAnchorElementUploads, setMenuAnchorElementUploads] = useState<null | HTMLElement>(
    null
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  //#endregion States

  const changeCurrentDate = (date?: Date | null) => {
    setCurrentDate(date);
    emptyCellsRef.current = [];
  };

  const changeReferenceDate = (date?: Date | null) => {
    setReferanceDate(date);
    emptyCellsRef.current = [];
  };

  // #region Removed
  //
  //? Removed this option to prevent confusion for users because deleting a value is not allowed at saving.
  //
  /* 
  const handleDeleteSelectedCell = () => {
    //@ts-ignore
    let selectedRange = gridRef?.current?.api?.getCellRanges();
    if (!selectedRange || !(selectedRange?.length > 0) || selectedRange === undefined) return;

    selectedRange.forEach((range) => {
      let startRowIndex = range?.startRow?.rowIndex;
      let endRowIndex = range?.endRow?.rowIndex;

      range?.columns?.forEach((column) => {
        //@ts-ignore
        let startColIndex = column?.colId;

        if (
          startRowIndex === undefined ||
          endRowIndex === undefined ||
          startColIndex === undefined
        ) {
          return;
        }

        //@ts-ignore
        if (column.userProvidedColDef?.headerName === 'Ref Tahmin') return;

        let limit = 24 * multiply;
        //@ts-ignore
        if (startRowIndex >= limit) return;
        //@ts-ignore
        if (endRowIndex >= limit) endRowIndex = limit - 1;

        gridRef?.current?.api?.forEachNode((node) => {
          if (
            node?.rowIndex != null &&
            node?.rowIndex > -1 &&
            node?.rowIndex < limit &&
            //@ts-ignore
            node?.rowIndex >= startRowIndex &&
            //@ts-ignore

            node?.rowIndex <= endRowIndex
          ) {
            node.setDataValue(startColIndex, null);
          }
        });
      });
    });

    // refresh cell
    gridRef?.current?.api?.refreshCells({ force: true });
  };
  */
  // #endregion Removed

  //#region Grid
  const gridOptions: GridOptions = {
    getRowId: (params) => params.data.field_1,
    enableRangeSelection: true,
    getContextMenuItems: ()=>  [
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      // {
      //   name: 'Sil',
      //   action: handleDeleteSelectedCell.bind(this),
      // },
      'separator',
      'export',
    ],
  };
  const [rowData, setRowData] = useState<any[]>();

  const gridRef = useRef<WrappedGridRef>(null);
  const gridRowHeight = 24;
  const gridHeight = gridRowHeight * 25 + 75 + 'px';

  useEffect(() => {
    generateRows();
  }, []);

  const generateRows = () => {
    let tempRowData: any = [];

    for (var clock: number = 0; clock < 24; clock++) {
      let startClock = clock;
      let endClock = clock + 1 === 24 ? 0 : clock + 1;
      let startClockText = '';
      let endClockText = '';

      if (startClock > 9) startClockText = startClock.toString();
      else startClockText = `0${startClock.toString()}`;

      if (endClock > 9) endClockText = endClock.toString();
      else endClockText = `0${endClock.toString()}`;

      switch (multiply) {
        case 1:
          tempRowData.push({
            field_1: `${startClockText}:00-${endClockText}:00`,
            field_3: '',
            field_4: '',
          });
          break;

        case 2:
          tempRowData.push({
            field_1: `${startClockText}:00-${startClockText}:30`,
            field_3: '',
            field_4: '',
          });
          tempRowData.push({
            field_1: `${startClockText}:30-${endClockText}:00`,
            field_3: '',
            field_4: '',
          });
          break;

        case 4:
          tempRowData.push({
            field_1: `${startClockText}:00-${startClockText}:15`,
            field_3: '',
            field_4: '',
          });
          tempRowData.push({
            field_1: `${startClockText}:15-${startClockText}:30`,
            field_3: '',
            field_4: '',
          });
          tempRowData.push({
            field_1: `${startClockText}:30-${startClockText}:45`,
            field_3: '',
            field_4: '',
          });
          tempRowData.push({
            field_1: `${startClockText}:45-${endClockText}:00`,
            field_3: '',
            field_4: '',
          });
          break;

        default:
          console.log('default');
      }
    }

    tempRowData.push({
      field_1: `Total`,
      field_3: '',
      field_4: '',
    });

    // tempRowData.push({
    //   field_1: ``,
    //   field_3: '',
    //   field_4: '',
    // });

    setRowData(tempRowData);
  };

  const toMoneyString = (value, decimal) => {
    value = Number(value);
    if (value === null || value === undefined) {
      return '-';
    }

    if (parseFloat(value) === +parseFloat(value)?.toFixed(0)) {
      decimal = 0;
    }

    return value.toLocaleString('tr', {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  };

  const numberFormatter = (params) => {
    let limit = 24 * multiply;

    const colId: string = params?.colDef?.colId ?? '';
    if (colId === undefined || colId === null || colId == '') return;
    if (
      params.data[colId] === undefined ||
      params.data[colId] === null ||
      params.data[colId] === ''
    )
      return '';
    if (params.data[colId] === 0) return 0;
    let newValue = params.data[colId];
    if (
      params?.node?.rowIndex != null &&
      params?.node?.rowIndex > -1 &&
      params?.node?.rowIndex < limit
    ) {
      let convertedResult = toMoneyString(newValue, unitRef.current === 0 ? 2 : 1);
      return decimalLocalizeFormatter(convertedResult);
    } else {
      return null;
    }
  };

  const onCellValueChanged = (event: CellValueChangedEvent) => {
    if (event.node.rowIndex == 24 * multiply) return;
    const colId: string = event?.colDef?.colId ?? '';

    let operationFacilityId = +colId.split('_')[2];
    let operationFacility = facilityList.find((x) => x.id === operationFacilityId);
    let maxValue = +operationFacility?.maxConsumptionLimitMW;

    if (unitRef.current === 0) {
      maxValue = maxValue * 1000;
    }

    //console.log('event.newValue', event.newValue, 'event.oldValue', event.oldValue);

    if (event.newValue === event.oldValue) return;

    if (
      event.newValue === null ||
      event.newValue === undefined ||
      event.newValue === '' ||
      Number.isNaN(Number(decimalCalculationFormatter(event.newValue))) ||
      decimalCalculationFormatter(event.newValue) < 0 ||
      event.newValue === '-'
    ) {
      if (event.newValue === null || event.newValue === undefined) {
        event.data.value = null;
        event.node.setDataValue(colId, null);
      } else {
        if (
          event.oldValue === null ||
          event.oldValue === undefined ||
          event.oldValue === '' ||
          event.oldValue === '-' ||
          decimalCalculationFormatter(event.oldValue) < 0
        ) {
          event.data.value = null;
          event.node.setDataValue(colId, null);
        } else {
          event.data.value = event.oldValue;
          event.node.setDataValue(colId, event.oldValue);
        }

        Utils.showUniqueErrorMessage(
          `Lütfen pozitif tahmin değeri giriniz!`,
          'ConsumptionForecastValueChangeError'
        );
      }

      let limit = 24 * multiply;
      if (event?.node?.rowIndex === limit) return;
      let total = 0;

      if (colId === undefined || colId === null || colId == '') return;
      event.api.forEachNode((node) => {
        if (node?.rowIndex != null && node?.rowIndex > -1 && node?.rowIndex < limit) {
          total += node.data[colId] ? decimalCalculationFormatter(node.data[colId]) : 0;
        } else if (node?.rowIndex != null && node?.rowIndex === limit) {
          node.setDataValue(colId, decimalLocalizeFormatter(total));
        }
      });

      return;
    }

    if (maxValue < 0 && decimalCalculationFormatter(event.newValue) > 0) {
      event.data.value = 0;
      event.node.setDataValue(colId, 0);

      Utils.showUniqueErrorMessage(
        `${operationFacility.facilityName} - Lütfen Tüketim tahminini tesis üst limitinden düşük giriniz!`,
        'ConsumptionForecastValueChangeError'
      );
      return;
    }

    if (
      decimalCalculationFormatter(event.newValue) > maxValue &&
      decimalCalculationFormatter(event.newValue) > 0
    ) {
      let newValue = event.oldValue != undefined ? event.oldValue : null;

      if (newValue && decimalCalculationFormatter(newValue) > maxValue) {
        newValue = maxValue;
      }

      event.data.value = newValue;
      event.node.setDataValue(colId, newValue);

      Utils.showUniqueErrorMessage(
        `${operationFacility.facilityName} - Lütfen Tüketim tahminini tesis üst limitinden düşük giriniz!`,
        'ConsumptionForecastValueChangeError'
      );

      return;
    }

    let newCellValue = decimalLocalizeFormatter(
      (Math.floor(decimalCalculationFormatter(event.newValue) * 100) / 100).toFixed(1)
    );

    event.data.value = newCellValue;
    event.node.setDataValue(colId, newCellValue);

    let limit = 24 * multiply;
    if (event?.node?.rowIndex === limit) return;
    let total = 0;

    if (colId === undefined || colId === null || colId == '') return;

    event.api.forEachNode((node) => {
      if (node?.rowIndex != null && node?.rowIndex > -1 && node?.rowIndex < limit) {
        total += node.data[colId] ? decimalCalculationFormatter(node.data[colId]) : 0;
      } else if (node?.rowIndex != null && node?.rowIndex === limit) {
        node.setDataValue(colId, decimalLocalizeFormatter(total));
      }
    });
  };

  function RenderRawValueCell(params: ICellRendererParams & { value: any }) {
    const colId = params?.column?.getColId()!;
    const id = colId.split('_')[2]!;

    let enabledEmptyCheck: boolean = false;
    if (emptyCellsRef.current.some((ec) => ec.rowId === params.data.field_1 && ec.colId === colId)) {
      enabledEmptyCheck = true;
    }

    // #region Popper
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [data, setData] = useState<any[]>([]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
      if (open) {
        handleGetLogs();
      }
    }, [open]);

    const handleGetLogs = async () => {
      try {
        let processDate = currentDate ? new Date(currentDate) : new Date();
        processDate?.setHours(params.node.rowIndex!);
        processDate?.setMinutes(0);
        processDate?.setSeconds(0);
        processDate?.setMilliseconds(0);
        let req = {
          unitType: '0',
          unitNo: +id,
          deliveryStart: processDate,
        };

        const result = await consumptionForcastService.getContractHistory(req);
        setData(result?.data ?? []);
      } catch (error) { }
    };
    //#endregion Popper

    return (
      <>
        <div>
          <Popover
            open={open}
            canAutoPosition={false}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <HistoryTableCell colSpan={3}>
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography style={{ fontWeight: 550, fontSize: '14px' }}>
                        Tüketim Tahmini Geçmişi
                      </Typography>
                    </span>
                  </HistoryTableCell>
                </TableRow>
                <TableRow>
                  <HistoryTableCell>
                    <FormattedMessage id="global.date" defaultMessage="Date" />
                  </HistoryTableCell>
                  <HistoryTableCell>
                    <FormattedMessage id="global.user" defaultMessage="User" />
                  </HistoryTableCell>
                  <HistoryTableCell>
                    <FormattedMessage id="global.value" defaultMessage="Value" />
                  </HistoryTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => {
                  const deliveryStart = moment(item?.deliveryStart)
                    .utcOffset(item.deliveryStart)
                    .format('DD.MM.YYYY HH:mm:ss ([UTC] Z)');
                  const modificationDateText = `${deliveryStart}`;

                  return (
                    <TableRow style={{ height: '20px' }}>
                      <TableCell>
                        <div
                          style={{
                            textAlign: 'center',
                            fontSize: '12px',
                          }}
                        >
                          {modificationDateText}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            textAlign: 'center',
                            fontSize: '12px',
                          }}
                        >
                          {item?.userName}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            textAlign: 'center',
                            fontSize: '12px',
                          }}
                        >
                          {item.value == null ? '' : item.value}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Popover>
        </div>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            backgroundColor: enabledEmptyCheck && params?.value == null ? (theme) => theme.palette.error.main : undefined,
          }}
        >
          {params?.value != null
            ? decimalLocalizeFormatter(
              parseFloat(decimalCalculationFormatter(params?.value).toFixed(1))
            )
            : ''}
          <IconButton style={{ width: '24px', height: '24px' }} onClick={handleClick}>
            <HistoryIcon style={{ width: '16px', height: '16px' }} />
          </IconButton>
        </Box>
      </>
    );
  }

  function RenderLockedCell(row: any, isLocked = false) {
    //@ts-ignore
    const id = row?.column?.colId.split('_')[2];

    // #region Popper
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [data, setData] = useState<any[]>([]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
      if (open) {
        handleGetLogs();
      }
    }, [open]);

    const handleGetLogs = async () => {
      try {
        let processDate = currentDate ? new Date(currentDate) : new Date();
        //@ts-ignore
        processDate?.setHours(row?.node?.rowIndex);
        processDate?.setMinutes(0);
        processDate?.setSeconds(0);
        processDate?.setMilliseconds(0);
        let req = {
          unitType: '0',
          unitNo: +id,
          deliveryStart: processDate,
        };

        const result = await consumptionForcastService.getContractHistory(req);
        setData(result?.data ?? []);
      } catch (error) { }
    };
    //#endregion Popper

    return (
      <>
        <div>
          <Popover
            open={open}
            canAutoPosition={false}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <HistoryTableCell colSpan={3}>
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography style={{ fontWeight: 550, fontSize: '14px' }}>
                        Tüketim Tahmini Geçmişi
                      </Typography>
                    </span>
                  </HistoryTableCell>
                </TableRow>
                <TableRow>
                  <HistoryTableCell>
                    <FormattedMessage id="global.date" defaultMessage="Date" />
                  </HistoryTableCell>
                  <HistoryTableCell>
                    <FormattedMessage id="global.user" defaultMessage="User" />
                  </HistoryTableCell>
                  <HistoryTableCell>
                    <FormattedMessage id="global.value" defaultMessage="Value" />
                  </HistoryTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => {
                  const deliveryStart = moment(item?.deliveryStart)
                    .utcOffset(item.deliveryStart)
                    .format('DD.MM.YYYY HH:mm:ss ([UTC] Z)');
                  const modificationDateText = `${deliveryStart}`;

                  return (
                    <TableRow style={{ height: '20px' }}>
                      <TableCell>
                        <div
                          style={{
                            textAlign: 'center',
                            fontSize: '12px',
                          }}
                        >
                          {modificationDateText}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            textAlign: 'center',
                            fontSize: '12px',
                          }}
                        >
                          {item?.userName}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            textAlign: 'center',
                            fontSize: '12px',
                          }}
                        >
                          {item.value == null ? '' : item.value}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {/* <TableRow class="line" style={{ height: '3px' }}>
                  <TableCell
                    class="line"
                    style={{ height: '3px', backgroundColor: 'red' }}
                    colSpan={3}
                  >
                    <div style={{ textAlign: 'center', fontSize: '12px' }}>
                      {
                        <hr
                          style={{
                            marginTop: '0px',
                            marginBottom: '0px',
                            height: '3px',
                            backgroundColor: 'red',
                          }}
                        ></hr>
                      }
                    </div>
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </Popover>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: isLocked ? '#c7edfc' : '',
          }}
        >
          <img src={GopLock} width="20px" height="20px" style={{ float: 'left' }}></img>
          <div style={{ display: 'flex' }}>
            {row?.value}
            <IconButton style={{ width: '24px', height: '24px' }} onClick={handleClick}>
              <HistoryIcon style={{ width: '16px', height: '16px' }} />
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function RenderTotalCell(row: any) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingRight: '24px',
        }}
      >
        <strong>
          {decimalLocalizeFormatter(parseFloat(decimalCalculationFormatter(row?.total).toFixed(1)))}
        </strong>
      </div>
    );
  }

  const columnPrimary = {
    colId: 'field_1',
    field: 'field_1',
    headerName: 'Tesis',
    editable: false,
    pinned: 'left',
    width: 150,
  };

  const CustomHeaderButton = ({ providerKey, id, unitExtraProviders, unitProviders }) => {
    const handleForecastTypeChange = (value) => {
      // setSelectedForecastType(value);

      setUnitNoWithProviderKey((prev) => {
        let temp = [...prev];
        let index = temp.findIndex((x) => x.id === id);
        if (index > -1) {
          temp[index].providerKey = value;
        } else {
          temp.push({ id: id, providerKey: value });
        }
        return temp;
      });
    };

    return (
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
        <>Tahmin</>
        <ConsumptionSource providerKey={providerKey} onChange={handleForecastTypeChange} unitExtraProviders={unitExtraProviders} unitProviders={unitProviders} />
      </div>
    );
  };

  const CustomHeaderButtonRenderer = ({ providerKey, id, unitExtraProviders, unitProviders }) => {
    return <CustomHeaderButton providerKey={providerKey} id={id} unitExtraProviders={unitExtraProviders} unitProviders={unitProviders} />;
  };

  function CustomHeaderGroup(props: { displayName: string }) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
      >
        <span>{props.displayName}</span>
        <Tooltip title={<FormattedMessage id="global.save" defaultMessage="Save" />}>
          <IconButton
            style={{ width: '32px', height: '32px' }}
            onClick={(e) => {
              //@ts-ignore
              if (!(selectedConsumers?.length > 0) && !showAll) {
                Utils.showErrorMessage('Lütfen en az bir tüketici seçiniz.');
                return;
              }
              //@ts-ignore
              let id = props.columnGroup.children[0].colId?.split('_')[2];
              setSingleSaveProps(props);
              generateFacilityTotalConsumptionChangedList(id, props);
              setIsMultipleSave(false);
              setShowPrompt(true);
            }}
          >
            <SaveIcon style={{ width: '16px', height: '16px' }} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  const generateColumn = (id, name, lockList, providerKey, unitExtraProviders, unitProviders) => {
    let column = {
      colId: 'field_2_' + id,
      field: 'field_2_' + id,
      headerName: name,
      headerGroupComponent: CustomHeaderGroup, //
      children: [
        {
          colId: 'field_3_' + id,
          field: 'field_3_' + id,
          headerName: 'Tahmin',
          headerComponent: CustomHeaderButtonRenderer,
          headerComponentParams: { providerKey: providerKey, id: id, unitExtraProviders: unitExtraProviders, unitProviders: unitProviders },
          width: 150,
          colSpan: (params) => {
            switch (params.data.field_1) {
              case 'Güncelleme':
              case 'Kaynak':
              case 'Total':
                return 1;
              default:
                return 1;
            }
          },
          editable: (params) => {
            if (params?.node?.rowIndex != null && lockList[params?.node?.rowIndex] === -1) {
              return isAdmin ? true : false;
            }

            let startLimit = -1;
            let endLimit = 24 * multiply;
            if (
              params?.node?.rowIndex != null &&
              params?.node?.rowIndex > startLimit &&
              params?.node?.rowIndex < endLimit
            ) {
              return true;
            } else {
              return false;
            }
          },
          cellRendererSelector: (p: ICellRendererParams) => {
            if (p?.node?.rowIndex != null && lockList[p?.node?.rowIndex] === -1 && !isAdmin) {
              return {
                component: RenderLockedCell,
                params: { value: p?.value },
              };
            }

            let endLimit = 24 * multiply;
            const type = p?.node?.rowIndex;

            if (type === endLimit) {
              return {
                component: RenderTotalCell,
                params: { total: p?.value },
              };
            } else {
              return {
                component: RenderRawValueCell,
                params: { value: p?.value },
              };
            }
          },
          onCellValueChanged: onCellValueChanged,
          // valueFormatter: numberFormatter,
          cellEditor: NumericCellEditor,
        },
        {
          colId: 'field_4_' + id,
          field: 'field_4_' + id,
          headerName: 'Ref Tahmin',
          editable: false,
          width: 150,
          cellRendererSelector: (p) => {
            let endLimit = 24 * multiply;
            const type = p?.node?.rowIndex;
            if (type === endLimit) {
              return {
                component: RenderTotalCell,
                params: { total: p?.value },
              };
            } else {
              return {
                component: RenderReferanceCell,
                params: { value: p?.value },
              };
            }
          },
        },
      ],
    };

    return column;
  };

  const [columnDefs, setColumnDefs] = useState([columnPrimary]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: false,
      resizable: true,
      filter: false,
    };
  }, []);

  const apiRef = React.useRef<GridApi>({
    grid: undefined
  });

  const onGridReady = (params: GridReadyEvent) => {
    const { api } = params;

    apiRef.current.grid = api;
  };

  //#endregion Grid

  //#region Methods

  const handleSave = async (props) => {
    const colId: string = props.columnGroup.children[0].colId ?? '';
    const id = colId.split('_')[2];
    let limit = 24 * multiply;
    let forecastDataList: SaveConsumptionForecastData[] = Object.assign(
      [],
      facilityTotalConsumption
    );
    let facilityData = forecastDataList.find((x) => x.unitNo === +id) ?? null;

    if (gridRef?.current?.api) {
      gridRef?.current?.api?.forEachNode((node, index) => {
        if (node?.rowIndex != null && node?.rowIndex > -1 && node?.rowIndex < limit) {
          let cellValue = node.data[colId];
          cellValue =
            cellValue === null || cellValue === undefined
              ? props.nullSubstitute ?? null // 0 expected as nullSubstitute so no conversion done
              : selectedUnit === 1
                ? decimalCalculationFormatter(cellValue)
                : decimalCalculationFormatter(cellValue) / 1000;

          //@ts-ignore
          facilityData.forecasts[index].value = cellValue;
        }
      });

      let request = new SaveConsumptionForecastRequest();
      let tempForecastDataList: SaveConsumptionForecastData[] = [];
      request.groupId = usergroupid;
      request.userId = appuserid;
      request.interval = multiply;
      request.period = 1;
      if (facilityData) {
        facilityData.forecastDay = moment(currentDate).format('YYYY-MM-DD');
        facilityData?.providerKey ? facilityData.providerKey = ForecastType.UserForecast : null;
        tempForecastDataList.push(facilityData);
      }
      request.forecastDataList = tempForecastDataList;

      const result = await consumptionForcastService.saveConsumptionForecasts(request);
      if (emptyCellsRef.current.length > 0) emptyCellsRef.current = [];

      //@ts-ignore
      if (result?.isError == false) {
        if (result?.data?.errorNotModifiedUnitNoList?.length > 0) {
          let errorNotModifiedUnitNoList = result?.data?.errorNotModifiedUnitNoList;
          // find facility name from facilityList
          let errorNotModifiedUnitNoListString = errorNotModifiedUnitNoList
            .map((q) => facilityList.find((x) => x.id === q)?.facilityName)
            .join(',');

          Utils.showErrorMessage(
            `Tesislerin tahminleri güncellenemedi. Tesisler: ${errorNotModifiedUnitNoListString}`
          );
        } else if (result?.data?.errorNotAddedUnitNoList?.length > 0) {
          let errorNotAddedUnitNoList = result?.data?.errorNotAddedUnitNoList;
          // find facility name from facilityList
          let errorNotAddedUnitNoListString = errorNotAddedUnitNoList
            .map((q) => facilityList.find((x) => x.id === q)?.facilityName)
            .join(',');
          Utils.showErrorMessage(
            `Tesislerin tahminleri eklenemedi. Tesisler: ${errorNotAddedUnitNoListString}`
          );
        } else {
          Utils.showSuccessMessage('Tesislere ait tahminleriniz başarıyla kaydedildi.');
        }
        showAll ? getAllConsumptionForecastLockAndReferances() : regenerateDynamicColumn();
      } else {
        Utils.showErrorMessage('Tesislere ait tahminleriniz kaydedilirken bir hata oluştu.');
      }
    }
  };

  const regenerateDynamicColumn = async () => {
    // ? Generate columns again

    if (apiRef.current) {
      if (selectedConsumers && selectedConsumers?.length > 0) {
        setColumnDefs([columnPrimary]);

        let request = new GetConsumptionForecastRequest();
        request.fromDate = moment(currentDate).format('YYYY-MM-DD');

        request.referenceDate = moment(referanceDate).format('YYYY-MM-DD');
        request.showReferenceDate = showReferance;
        request.groupId = usergroupid;
        request.userId = appuserid;
        request.interval = multiply;
        request.period = 1;
        request.unitType = 0;
        request.units = selectedConsumers.map((q) => {
          return {
            unitNo: +q.id,
            providerKey:
              unitNoWithProviderKey.find((x) => x.id === q.id)?.providerKey ??
              ForecastType.FinalForecast,
          };
        });

        const result = await consumptionForcastService.getConsumptionForecasts(request);
        await loadExtraProviders();
        await loadProviders();


        setFacilityTotalConsumption(result?.data ?? []);
        setFacilityTotalConsumptionChanged([]);

        if (result?.data?.length > 0) {
          //@ts-ignore
          result.data.forEach((element) => {
            let selectedFacility = facilityList.find((q) => q.id == element.unitNo);
            let orderedForecasts = element.forecasts?.sort((a, b) => {
              return a.order - b.order;
            });
            let valueList = orderedForecasts ?? [];
            let lockList = valueList?.map((q) => (q.lock ? -1 : 0)) ?? [];
            let providerKey = element.providerKey;
            let unitExtraProviders = extraProviders?.filter(q => q.UnitNo === element.unitNo) || [];
            let unitProviders = providers?.filter(q => q.unitNo === element.unitNo) || [];
            let generatedColumn: any = generateColumn(
              selectedFacility?.id,
              selectedFacility.facilityName,
              lockList,
              providerKey,
              unitExtraProviders,
              unitProviders
            );
            let tempArray = columnDefs;

            if (!tempArray.find((q) => q.field == generatedColumn.field)) {
              tempArray.push(generatedColumn);

              if (gridRef?.current?.api != null || gridRef?.current?.api != undefined) {
                gridRef?.current?.api?.setGridOption("columnDefs", tempArray);
                let limit = 24 * multiply;
                let colId = generatedColumn?.children[0].colId;
                let refCoId = generatedColumn?.children[1].colId;
                let rowData: any[] = [];
                let refRowData: any[] = [];

                if (valueList && valueList.length > 0) {
                  valueList.forEach((item) => {
                    let tempValue = item.value;

                    if (unitRef.current === 0) {
                      tempValue = tempValue * 1000;
                    }

                    rowData.push(decimalLocalizeFormatter(tempValue));
                  });
                }

                const resultReferance =
                  result?.data?.find((q) => q.unitNo == element.unitNo).references ?? [];

                if (showReferance && resultReferance?.length > 0) {
                  resultReferance.forEach((item) => {
                    let tempValue = item.value;

                    if (unitRef.current === 0) {
                      tempValue = tempValue * 1000;
                    }

                    refRowData.push(decimalLocalizeFormatter(tempValue));
                  });
                }

                if (gridRef?.current?.api) {
                  let total = 0;
                  let refTotal = 0;
                  gridRef?.current?.api?.forEachNode((node, index) => {
                    if (node?.rowIndex != null && node?.rowIndex > -1 && node?.rowIndex < limit) {
                      node.data[colId] = rowData[index];
                      node.data[refCoId] = refRowData[index];
                      total += decimalCalculationFormatter(rowData[index] ?? 0);
                      refTotal += decimalCalculationFormatter(refRowData[index] ?? 0);
                    } else if (node?.rowIndex === limit) {
                      node.data[colId] = decimalLocalizeFormatter(total);
                      node.data[refCoId] = decimalLocalizeFormatter(refTotal);
                    }
                  });
                }
              }
            }
          });
        }
      } else {
        // ? Clear all columns
        gridRef?.current?.api?.setGridOption("columnDefs", [columnPrimary]);
        // gridRef?.current?.api?.refreshCells({ force: true });
      }
      gridRef?.current?.api?.refreshCells({ force: true });
      regenerateShowHideColumn(showReferance);
    }
  };

  const handleSaveAll = async (nullSubstitute?: number) => {
    let requestList = showAll ? facilityList : selectedConsumers ?? [];

    //@ts-ignore
    if (!(requestList?.length > 0)) {
      Utils.showErrorMessage('Lütfen en az bir tüketici seçiniz.');
      return;
    }

    let request = new SaveConsumptionForecastRequest();
    request.groupId = usergroupid;
    request.userId = appuserid;
    request.interval = multiply;
    request.period = 1;

    requestList?.forEach(async (element) => {
      //@ts-ignore
      let selectedFacility = facilityList.find((q) => q.id === element.id);

      //@ts-ignore
      if (selectedFacility && selectedFacility?.id !== 0) {
        let limit = 24 * multiply;
        let colId = `field_3_${selectedFacility?.id}`;
        let forecastDataList: SaveConsumptionForecastData[] = Object.assign(
          [],
          facilityTotalConsumption
        );
        let facilityData = forecastDataList.find((x) => x.unitNo === +selectedFacility?.id) ?? null;

        gridRef?.current?.api?.forEachNode((node, index) => {
          if (node?.rowIndex != null && node?.rowIndex > -1 && node?.rowIndex < limit) {
            let cellValue = node.data[colId];
            cellValue =
              cellValue === null || cellValue === undefined
                ? nullSubstitute ?? null // 0 expected as nullSubstitute so no conversion done
                : selectedUnit === 1
                  ? decimalCalculationFormatter(cellValue)
                  : decimalCalculationFormatter(cellValue) / 1000;

            //@ts-ignore
            facilityData.forecasts[index].value = cellValue;
          }
        });
        forecastDataList.forEach((item) => {
          item.forecastDay = moment(currentDate).format('YYYY-MM-DD');
          item.providerKey = ForecastType.UserForecast;
        });
        request.forecastDataList = forecastDataList;
      }
    });

    const result = await consumptionForcastService.saveConsumptionForecasts(request);
    if (emptyCellsRef.current.length > 0) emptyCellsRef.current = [];

    //@ts-ignore
    if (result?.isError == false) {
      if (result?.data?.errorNotModifiedUnitNoList?.length > 0) {
        let errorNotModifiedUnitNoList = result?.data?.errorNotModifiedUnitNoList;
        // find facility name from facilityList
        let errorNotModifiedUnitNoListString = errorNotModifiedUnitNoList
          .map((q) => facilityList.find((x) => x.id === q)?.facilityName)
          .join(',');

        Utils.showErrorMessage(
          `Tesislerin tahminleri güncellenemedi. Tesisler: ${errorNotModifiedUnitNoListString}`
        );
      } else if (result?.data?.errorNotAddedUnitNoList?.length > 0) {
        let errorNotAddedUnitNoList = result?.data?.errorNotAddedUnitNoList;
        // find facility name from facilityList
        let errorNotAddedUnitNoListString = errorNotAddedUnitNoList
          .map((q) => facilityList.find((x) => x.id === q)?.facilityName)
          .join(',');
        Utils.showErrorMessage(
          `Tesislerin tahminleri eklenemedi. Tesisler: ${errorNotAddedUnitNoListString}`
        );
      } else {
        Utils.showSuccessMessage('Tesislere ait tahminleriniz başarıyla kaydedildi.');
      }
      showAll ? getAllConsumptionForecastLockAndReferances() : regenerateDynamicColumn();
    } else {
      Utils.showErrorMessage('Tesislere ait tahminleriniz kaydedilirken bir hata oluştu.');
    }
  };

  const getMultipleReferenceDataResult = async () => {
    let request = new GetMultipleConsumptionForecastsQueryByFacilityDto();
    let fromDate = referanceDate ? new Date(referanceDate) : new Date();
    let toDate = referanceDate ? new Date(referanceDate) : new Date();
    toDate?.setDate(toDate?.getDate() + 1);

    fromDate?.setHours(0);
    fromDate?.setMinutes(0);
    fromDate?.setSeconds(0);
    fromDate?.setMilliseconds(0);

    toDate?.setHours(0);
    toDate?.setMinutes(0);
    toDate?.setSeconds(0);
    toDate?.setMilliseconds(0);

    request.unitType = 0;
    //@ts-ignore
    // request.unitNoList = selectedConsumers.map((q) => +q.id);
    // request.providerKey =
    //   selectedForecastType?.length > 1 ? selectedForecastType : ForecastType.FinalForecast;

    request.unitNoWithProviderKey = selectedConsumers.map((q) => {
      return {
        unitNo: +q.id,
        providerKey:
          unitNoWithProviderKey.find((x) => x.id === q.id)?.providerKey ??
          ForecastType.FinalForecast,
      };
    });

    request.from = fromDate;
    request.to = toDate;
    request.period = 1;
    request.groupId = usergroupid;

    const result = await consumptionForcastService.getMultipleConsumptionForecastByFacility(
      request
    );
    return result?.data?.data ?? [];
  };

  const getAllFacilities = async () => {
    let req = commonPermissionCompanies?.map((item) => item.id) ?? [];
    const result = await consumptionForcastService.getAllActiveFacilities(req, appuserid);
    // setFacilityList(result?.data ?? []);

    if (result?.data?.length > 0) {
      // add first item  to commonPermissionCompanies
      let temp = [...commonPermissionCompanies];
      temp.unshift({ id: 0, fullname: 'Tümü' });
      setCommonPermissionCompanies(temp);

      // add first item  to facilityList
      let temp2 = [...result?.data];
      temp2.unshift({ id: 0, facilityName: 'Tümü' });
      setFacilityList(temp2);
    }

    // setShowAll(true);

    // setSelectedConsumers(result?.data ?? []);
  };

  const getAllConsumptionForecastLockAndReferances = async (companies: any[] = []) => {
    let request = new GetConsumptionForecastRequest();
    request.fromDate = moment(currentDate).format('YYYY-MM-DD');

    request.referenceDate = moment(referanceDate).format('YYYY-MM-DD');
    request.showReferenceDate = showReferance;
    request.groupId = usergroupid;
    request.userId = appuserid;
    request.interval = multiply;
    request.period = 1;
    request.unitType = 0;
    request.units = facilityList
      ?.filter((q) => q?.id > 0)
      ?.map((q) => {
        return {
          unitNo: +q.id,
          providerKey:
            unitNoWithProviderKey.find((x) => x.id === q.id)?.providerKey ??
            ForecastType.FinalForecast,
        };
      });

    const result = await consumptionForcastService.getConsumptionForecasts(request);
    await loadExtraProviders();
    await loadProviders();

    setFacilityTotalConsumption(result?.data ?? []);
    setFacilityTotalConsumptionChanged([]);

    let companyFacilities =
      companies.length > 0
        ? facilityList
          ?.filter((q) => companies?.includes(q?.parentUnitNo ?? 0))
          .filter((q) => q.id !== 0)
        : facilityList?.filter((q) => q.id !== 0);

    if (result?.statusCode == 200 && apiRef.current) {
      if (companyFacilities?.length > 0) {
        setColumnDefs([columnPrimary]);

        for (let i = 0; i < companyFacilities.length; i++) {
          let element = companyFacilities[i];
          //@ts-ignore
          let selectedFacility = companyFacilities.find((q) => q.id === element.id);

          if (selectedFacility) {
            let foundFacilityData = result?.data?.find((q) => q.unitNo == element.id);
            let orderedForecasts = foundFacilityData?.forecasts?.sort((a, b) => {
              return a.order - b.order;
            });
            let valueList = orderedForecasts ?? [];
            let lockList = orderedForecasts?.map((q) => (q.lock ? -1 : 0)) ?? [];
            let unitExtraProviders = extraProviders?.filter(q => q.UnitNo === element.id) || [];
            let unitProviders = providers?.filter(q => q.unitNo === element.id) || [];
            // let providerKey = element.providerKey ?? ForecastType.UserForecast;

            let generatedColumn: any = generateColumn(
              selectedFacility?.id,
              selectedFacility.facilityName,
              lockList,
              foundFacilityData?.providerKey,
              unitExtraProviders,
              unitProviders
            );
            let tempArray = columnDefs;

            if (!tempArray.find((q) => q.field == generatedColumn.field)) {
              tempArray.push(generatedColumn);

              if (gridRef?.current?.api != null || gridRef?.current?.api != undefined) {
                gridRef?.current?.api?.setGridOption("columnDefs", tempArray);
                let limit = 24 * multiply;
                let colId = generatedColumn?.children[0].colId;
                let refCoId = generatedColumn?.children[1].colId;
                let rowData: any[] = [];
                let refRowData: any[] = [];

                if (valueList && valueList.length > 0) {
                  valueList.forEach((item) => {
                    let tempValue = item.value;

                    if (unitRef.current === 0) {
                      tempValue = tempValue * 1000;
                    }

                    rowData.push(decimalLocalizeFormatter(tempValue));
                  });
                }

                const resultReferance =
                  result?.data?.find((q) => q.unitNo == element.id)?.references ?? [];

                if (showReferance && resultReferance?.length > 0) {
                  resultReferance.forEach((item) => {
                    let tempValue = item.value;

                    if (unitRef.current === 0) {
                      tempValue = tempValue * 1000;
                    }

                    refRowData.push(decimalLocalizeFormatter(tempValue));
                  });
                }

                if (gridRef?.current?.api) {
                  let total = 0;
                  let refTotal = 0;
                  gridRef?.current?.api?.forEachNode((node, index) => {
                    if (node?.rowIndex != null && node?.rowIndex > -1 && node?.rowIndex < limit) {
                      node.data[colId] = rowData[index];
                      node.data[refCoId] = refRowData[index];
                      total += decimalCalculationFormatter(rowData[index] ?? 0);
                      refTotal += decimalCalculationFormatter(refRowData[index] ?? 0);
                    } else if (node?.rowIndex === limit) {
                      node.data[colId] = decimalLocalizeFormatter(total);
                      node.data[refCoId] = decimalLocalizeFormatter(refTotal);
                    }
                  });
                }
              }
            }
          }
        }
      } else {
        // ? Clear all columns
        gridRef?.current?.api?.setGridOption("columnDefs", [columnPrimary]);
        // gridRef?.current?.api?.refreshCells({ force: true });
      }
      gridRef?.current?.api?.refreshCells({ force: true });
      regenerateShowHideColumn(showReferance);
    }
  };

  const handleShowHideReferance = () => {
    setShowReferance(!showReferance);
    regenerateShowHideColumn(!showReferance);
  };

  const handleFacilityChange = (event: any, newValue: any) => {
    if (showAll) {
      setSelectedConsumers([]);
      setSelectedCompanies([]);
      setShowAll(false);
      return;
    }

    if (!showAll && newValue?.length > 0 && newValue?.find((q) => q?.id === 0)) {
      setShowAll(true);
    } else {
      setSelectedConsumers(newValue ?? []);
      setSelectedCompanies([]);
      setShowAll(false);
    }
  };

  const regenerateShowHideColumn = (currentShowReference: boolean) => {
    if (apiRef.current) {
      // @ts-ignore
      const columnDefs = apiRef.current?.api?.columnModel?.columnDefs;
      if (currentShowReference) {
        columnDefs?.forEach((item) => {
          if (item != undefined && item.colId != 'field_1') {
            apiRef.current.api?.setColumnVisible(item.children[1]?.field, true);
          }
        });
      } else {
        columnDefs?.forEach((item) => {
          if (item != undefined && item.colId != 'field_1') {
            apiRef.current.api?.setColumnVisible(item.children[1]?.field, false);
          }
        });
      }
    }
  };

  const generateFacilityTotalConsumptionChangedList = (id: number = 0, props: any = {}) => {
    const emptyCells: ICellInfo[] = [];
    if (id > 0) {
      const colId: string = props.columnGroup.children[0].colId ?? '';
      const id = colId.split('_')[2];
      let limit = 24 * multiply;
      let rows: Array<any> = [];

      gridRef?.current?.api?.forEachNode((node) => {
        if (node?.rowIndex != null && node?.rowIndex > -1 && node?.rowIndex < limit) {
          let cellValue = node.data[colId];

          if (cellValue === null || cellValue === undefined) {
            emptyCells.push({
              rowId: node.data.field_1,
              colId,
            });
          }

          cellValue =
            selectedUnit === 1
              ? decimalCalculationFormatter(cellValue)
              : decimalCalculationFormatter(cellValue) / 1000;
          let rowvalue = cellValue ?? 0;
          let tempPredictionModel = new PredictionModel();
          let processDate = currentDate ? new Date(currentDate) : new Date();
          processDate?.setHours(node?.rowIndex);
          processDate?.setMinutes(0);
          processDate?.setSeconds(0);
          processDate?.setMilliseconds(0);
          tempPredictionModel.deliveryStart = processDate;
          tempPredictionModel.value = rowvalue;
          rows.push(tempPredictionModel);
        }
      });

      setFacilityTotalConsumptionChanged([
        {
          facility: facilityList.find((q) => q.id === +id),
          total: rows.reduce((a, b) => a + (b['value'] || 0), 0),
        },
      ]);
    } else {
      let requestList = showAll ? facilityList : selectedConsumers ?? [];

      let limit = 24 * multiply;

      let changedList = Array.from(facilityTotalConsumptionChanged);

      requestList?.forEach((element) => {
        let selectedFacility = facilityList.find((q) => q.id === element.id);

        let colId = `field_3_${selectedFacility?.id}`;

        gridRef?.current?.api?.forEachNode((node, index) => {
          if (node?.rowIndex != null && node?.rowIndex > -1 && node?.rowIndex < limit) {
            const cellValue = node.data[colId];
            if (cellValue === null || cellValue === undefined) {
              emptyCells.push({
                rowId: node.data.field_1,
                colId,
              });
            }
          } else if (node?.rowIndex === limit) {
            let facility = changedList.find((x) => x.facility.id === selectedFacility?.id);

            if (facility) {
              facility.total = node.data[colId];
            } else {
              changedList.push({
                facility: selectedFacility,
                total: node.data[colId],
              });
            }
          }
        });
      });

      setFacilityTotalConsumptionChanged(changedList ?? []);
    }

    emptyCellsRef.current = emptyCells;
  };

  const decimalCalculationFormatter = (value) => {
    if (value != null && value != undefined && value != '' && value != 0) {
      return +(value?.toString().replace(',', '.') ?? 0);
    } else {
      return +value;
    }
  };

  const decimalLocalizeFormatter = (value) => {
    if (value != null && value != undefined && value != '' && value != 0) {
      return value?.toString().replace('.', ',');
    } else {
      return value;
    }
  };

  const groupedFacilities: Record<number, any[]> = facilityList.reduce((groups, facility) => {
    const parentUnitNo = facility.parentUnitNo;
    if (!groups[parentUnitNo]) {
      groups[parentUnitNo] = [];
    }
    groups[parentUnitNo].push(facility);
    return groups;
  }, {});

  const renderGroupedFacilities = Object.entries(groupedFacilities).map(
    ([parentUnitNo, facilities]) => [
      <ListSubheader key={parentUnitNo}>
        {commonPermissionCompanies.find((company) => company.id === parseInt(parentUnitNo))
          ?.fullname ?? ''}
      </ListSubheader>,
      ...facilities.map((facility, index) => (
        <MenuItem key={index} value={facility}>
          <Checkbox
            checked={
              selectedConsumers?.some(
                //@ts-ignore
                (selectedFacility) => selectedFacility.id === facility.id
              ) ?? false
            }
          />
          <ListItemText primary={facility.facilityName} />
        </MenuItem>
      )),
    ]
  );

  const GroupHeader = styled('div')(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold',
    position: 'sticky',
    top: '0px',
    backgroundColor: getTheme() === 'dark' ? '#212B36' : '#F4F6F8',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0px 10px 18px',
    zIndex: '2',
  }));

  const GroupItems = styled('ul')({
    padding: '0px 10px',
  });

  const handleOpenPatterDate = (event) => {
    //@ts-ignore
    if (selectedConsumers?.length > 0 || showAll) {
      setShowPatternDate(true);
      setAnchorEl(event.target);
    } else {
      Utils.showErrorMessage('Lütfen en az bir tüketici seçiniz.');
    }
  };

  const exportForecastTemplate = async () => {
    let req: ExportExcelModel = new ExportExcelModel();
    let startDate = rangeStartDate ? new Date(rangeStartDate) : new Date();
    let endDate = rangeEndDate ? new Date(rangeEndDate) : new Date();

    startDate?.setHours(0);
    startDate?.setMinutes(0);
    startDate?.setSeconds(0);
    startDate?.setMilliseconds(0);

    endDate?.setHours(0);
    endDate?.setMinutes(0);
    endDate?.setSeconds(0);
    endDate?.setMilliseconds(0);

    req.startDate = startDate;
    req.endDate = endDate;
    req.predictionKey = ForecastType.ForecastImport;
    req.saveAsGop = false;
    req.saveAsFinalForecast = isFinalForecast;
    req.addFinalForecast = isAddFinalForecast;

    req.unitNoList = showAll
      ? facilityList?.map((q) => q.id) ?? []
      : selectedConsumers?.map((q) => q.id) ?? [];

    try {
      const response = await consumptionForcastService.exportForecastTemplate(req);
    } catch (error) {
      Utils.showErrorMessage('Excel dosyası oluşturulurken bir hata oluştu.');
      console.log('API error', error);
    }
  };

  const readExcel = (value) => {
    let req = {
      type: 9,
      id: 0,
      groupId: usergroupid,
      period: 1,
      fileName: value.target.files[0].name,
      userId: appuserid,
    };

    buildFileUploadForm(value.target.files[0], req)
      .then((formData) => {
        if (formData === null) {
          throw new Error('Dosya içeriği belirlenemedi!');
        }
        $('#loader').addClass('whirl');
        fetch(consumptionForcastService.getServiceUrl("/api/consumption-forecast/excel-import"), {
          // mode: 'no-cors',
          method: 'POST',
          body: formData,
          headers: {
            "X-SmartPulse-Service": consumptionForecastServiceName
          },
        })
          .then((res) => {
            $('#file-upload').val('');

            return res.json();
          })
          .then((res) => {
            $('#loader').removeClass('whirl');
            if (res?.statusCode === 200) {
              showAll ? getAllConsumptionForecastLockAndReferances() : regenerateDynamicColumn();
              Utils.showSuccessMessage('Tahminleriniz başarıyla kaydedildi.');
            } else {
              Utils.showErrorMessage(`${res?.message}`);
            }
          })
          .catch((reason) => {
            $('#file-upload').val('');
            $('#loader').removeClass('whirl');
            Utils.showErrorMessage('Beklenmeyen bir hata oluştu');
          });
      })
      .catch((reason) => {
        $('#file-upload').val('');
        $('#loader').removeClass('whirl');
        Utils.showErrorMessage(`Beklenmeyen bir hata oluştu: ${reason}`);
      });
  };

  const buildFileUploadForm = (file: File, req: any): Promise<FormData | null> => {
    return new Promise((resolve) => {
      return convertToBase64(file).then((fileContents) => {
        if (fileContents === null || fileContents.length <= 0) {
          throw new Error('Dosya içeriği belirlenemedi!');
        }

        const payloadForm = new FormData();
        payloadForm.append('FileBase64', fileContents[1]);
        payloadForm.append('FileName', file.name);
        payloadForm.append('Type', req.type);
        payloadForm.append('Id', req.id);
        payloadForm.append('GroupId', req.groupId);
        payloadForm.append('Period', req.period);
        payloadForm.append('UserId', req.userId);

        resolve(payloadForm);
      });
    });
  };

  const loadUploadedFiles = async () => {
    const request = {
      userId: appuserid,
      uploadedFileType: 9,
    };

    const result = await consumptionForcastService.getUploadedFiles(request);
    setUploadedFiles(result ?? []);
  };

  const loadExtraProviders = async () => {
    if (!extraProvidersLoad) {
      const extraProvidersListResponse = await getConsumptionForecastBots({ UnitIdList: facilityList?.map(q => q.id) || [] });
      if (Array.isArray(extraProvidersListResponse))
        setExtraProviders(extraProvidersListResponse);
      setExtraProvidersLoad(true);
    }
  }

  const loadProviders = async () => {
    if (!providersLoad) {

      let request = new GetConsumptionProvidersRequest();
      request.units = selectedConsumers.map((q) => {
        return {
          unitNo: +q.id,
          providerKey:
            unitNoWithProviderKey.find((x) => x.id === q.id)?.providerKey ??
            ForecastType.FinalForecast,
        };
      });
      const result = await consumptionForcastService.getProviders(request);
      setProviders(result?.data.providers || []);
      setProvidersLoad(true);
    }
  }

  //TODO: be tarafında düzenleme yapılacak ve burası kaldırılacak
  const loadTotalForecast = async () => {
    let request: any[] = [];

    selectedConsumers?.forEach(async (element) => {
      let req = {
        unitNo: element.id,
        from: currentDate ? new Date(currentDate) : new Date(),
        to: currentDate ? new Date(currentDate) : new Date(),
        providerKey: ForecastType.FinalForecast,
      };
      req.to.setDate(req.to?.getDate() + 1);

      req.from.setHours(0);
      req.from.setMinutes(0);
      req.from.setSeconds(0);
      req.from.setMilliseconds(0);
      req.to.setHours(0);
      req.to.setMinutes(0);
      req.to.setSeconds(0);
      req.to.setMilliseconds(0);

      request.push(req);
    });

    const result = await consumptionForcastService.getTotalFinalForecast(request);
    return result?.data ?? [];
  };
  //#endregion

  //#region useEffects

  useEffect(() => {
    if (!showUploads) return;

    loadUploadedFiles();
  }, [showUploads]);

  useEffect(() => {
    let calculatedDate = new Date();
    calculatedDate.setDate(calculatedDate.getDate() + 1);
    changeCurrentDate(calculatedDate);
    setRangeStartDate(calculatedDate);
    setRangeEndDate(calculatedDate);

    let calcaulatedReferanceDate = new Date();
    calcaulatedReferanceDate.setDate(calcaulatedReferanceDate.getDate() - 6);
    changeReferenceDate(calcaulatedReferanceDate);
  }, []);

  useEffect(() => {
    if (initialLoad) return;

    if (showAll) {
      getAllConsumptionForecastLockAndReferances();
    } else {
      regenerateDynamicColumn();
    }
  }, [referanceDate, currentDate]);

  useEffect(() => {
    if (!providersLoad && extraProvidersLoad) {
      regenerateDynamicColumn();
    }
  }, [providers]);

  useEffect(() => {
    if (initialLoad) return;

    gridRef?.current?.api?.refreshCells({ force: true });
  }, [emptyCellsRef.current]);

  useEffect(() => {
    if (initialLoad) return;

    // localStorage.setItem(
    //   'consumptionForecast_selectedConsumers',
    //   JSON.stringify(selectedConsumers)
    // );

    //@ts-ignore
    if (selectedConsumers?.find((q) => q.id === 0)) {
      return;
    } else {
      regenerateDynamicColumn();
    }
  }, [selectedConsumers]);

  useEffect(() => {
    if (initialLoad) return;

    if (showAll) {
      getAllConsumptionForecastLockAndReferances();
      setSelectedCompanies(commonPermissionCompanies ?? []);
    } else {
    }

    //@ts-ignore
    if (!(selectedConsumers?.length > 0)) {
      setFacilityTotalConsumptionChanged([]);
      emptyCellsRef.current = [];
    }
  }, [showAll]);

  useEffect(() => {
    getAllFacilities();
  }, []);

  useEffect(() => {
    if (initialLoad) return;

    unitRef.current = selectedUnit;
    regenerateDynamicColumn();
  }, [selectedUnit]);

  useEffect(() => {
    let generatedTemplateUrl =
      window.location.origin +
      '/ConsumptionForecast/PredictionExcelExport?startDate=' +
      rangeStartDate +
      '&endDate=' +
      rangeEndDate +
      '&predictionKey=ForecastImport';

    setTemplateUrl(generatedTemplateUrl);
  }, [rangeStartDate, rangeEndDate]);

  useEffect(() => {
    if (initialLoad) return;

    if (showAll) {
      getAllConsumptionForecastLockAndReferances();
    } else {
      regenerateDynamicColumn();
    }
  }, [unitNoWithProviderKey]);

  useEffect(() => {
    localStorage.setItem('consumptionForecast_showReferance', showReferance.toString());
    if (showReferance) {
      showAll ? getAllConsumptionForecastLockAndReferances() : regenerateDynamicColumn();
    }
  }, [showReferance]);

  useEffect(() => {
    if (initialLoad && facilityList?.length > 0) {
      setTimeout(() => {
        // remove facilities from selectedConsumers which are not incldued in facilityList
        let newSelectedConsumers = selectedConsumers?.filter((q) =>
          facilityList?.find((f) => f.id === q.id)
        );
        setSelectedConsumers(newSelectedConsumers);

        regenerateDynamicColumn();

        setInitialLoad(false);
      }, 10);
    }
  }, [facilityList]);

  //#endregion useEffects

  const onChangeSelectedDate = (newDate: Date | null) => {
    changeCurrentDate(newDate);
    if (newDate) {
      const calcaulatedReferanceDate = new Date(newDate.getTime());
      calcaulatedReferanceDate.setDate(calcaulatedReferanceDate.getDate() - 7);
      changeReferenceDate(calcaulatedReferanceDate);
    }
  };

  return (
    // <Page title={'Consumption Forecast'} style={styles.page}>
    <Container maxWidth={themeStretch ? false : 'lg'}>
      <Box>
        <ConsumptionForecastApproveSave
          facilityTotalConsumption={facilityTotalConsumption}
          facilityTotalConsumptionChanged={facilityTotalConsumptionChanged}
          emptyCellsRef={emptyCellsRef}
          facilities={selectedConsumers ?? []}
          showPrompt={showPrompt}
          setShowPrompt={setShowPrompt}
          isMultipleSave={isMultipleSave}
          handleSaveAll={handleSaveAll}
          handleSave={handleSave}
          singleSaveProps={singleSaveProps}
          loadTotalForecast={loadTotalForecast}
          unitNoWithProviderKey={unitNoWithProviderKey}
        />
        <AppBar position="static" color="default">
          <Toolbar variant="dense" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" color="inherit">
              <FormattedMessage
                id="menu.app.title.consumptionforecasts"
                defaultMessage="Consumption Forecasts"
              />
            </Typography>

            <div style={{ display: 'flex', gap: '10px' }}>
              <Button
                id="uploads-button"
                variant="contained"
                color="primary"
                onClick={(event) => {
                  setMenuAnchorElementUploads(event.currentTarget);
                }}
              >
                <UploadFileIcon style={{ marginRight: '10px' }} />
                <FormattedMessage id="menu.app.button.uploads" defaultMessage="Uploads" />
                <KeyboardArrowDownIcon />
              </Button>

              <Menu
                anchorEl={menuAnchorElementUploads}
                open={Boolean(menuAnchorElementUploads)}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={() => {
                  setMenuAnchorElementUploads(null);
                  // this.setState({ MenuAnchorElementUploads: null });
                }}
              >
                <MenuItem
                  onClick={() => {
                    setShowUploads(true);
                  }}
                >
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <FormattedMessage id="menu.app.button.uploads" defaultMessage="Uploads" />
                  </ListItemText>
                </MenuItem>

                <label
                  htmlFor="file-upload"
                  style={{ cursor: 'pointer', width: '200px', display: 'contents' }}
                >
                  <MenuItem>
                    <ListItemIcon>
                      <UploadFileIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <FormattedMessage
                        id="menu.app.button.uploaddata"
                        defaultMessage="Upload Data (.xlsx)"
                      />
                    </ListItemText>
                  </MenuItem>
                </label>

                <MenuItem onClick={handleOpenPatterDate}>
                  <ListItemIcon>
                    <DownloadFileIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <FormattedMessage
                      id="menu.app.button.downloadtemplate"
                      defaultMessage="Download Template (.xlsx)"
                    />
                  </ListItemText>
                </MenuItem>
              </Menu>
              {showUploads ? (
                <Popover
                  open={showUploads}
                  canAutoPosition={false}
                  anchorPosition={{ top: 0, left: 50 }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  onClose={() => {
                    setAnchorEl(null);
                    setShowUploads(false);
                  }}
                >
                  <Table style={{ width: '1200px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: 'center', width: '12%' }}>
                          <FormattedMessage
                            id="table.cell.uploaddate"
                            defaultMessage="Upload Date"
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: 'center', width: '12%' }}>
                          <FormattedMessage
                            id="table.cell.uploadeduser"
                            defaultMessage="Uploaded User"
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: 'center', width: '15%' }}>
                          <FormattedMessage id="global.filename" defaultMessage="Filename" />
                        </TableCell>
                        <TableCell style={{ textAlign: 'center', width: '8%' }}>
                          <FormattedMessage id="global.startdate" defaultMessage="Start Date" />
                        </TableCell>
                        <TableCell style={{ textAlign: 'center', width: '8%' }}>
                          <FormattedMessage id="global.enddate" defaultMessage="End Date" />
                        </TableCell>
                        <TableCell style={{ textAlign: 'center', width: '8%' }}>
                          <FormattedMessage
                            id="table.cell.processingstatus"
                            defaultMessage="Processing Status"
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          <FormattedMessage
                            id="table.cell.errormessages"
                            defaultMessage="Error Messages"
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: 'center', width: '8%' }}>
                          <FormattedMessage
                            id="table.cell.downloadthefile"
                            defaultMessage="Download The File"
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {uploadedFiles.map((fileItem, fileIndex) => {
                        const createDateText = moment(new Date(fileItem.createDate))
                          .tz('Europe/Istanbul')
                          .format('DD-MM-YYYY HH:mm:ss');
                        const startDateText = moment(new Date(fileItem.startDate))
                          .tz('Europe/Istanbul')
                          .format('DD-MM-YYYY');
                        const endDateText = moment(new Date(fileItem.endDate))
                          .tz('Europe/Istanbul')
                          .format('DD-MM-YYYY');
                        const downloadUrl =
                          window.location.origin + '/UploadFile/DownloadFile?id=' + fileItem.id;
                        const buttonID = 'file-export' + fileItem.id;

                        return (
                          <TableRow key={fileItem.Id} style={{ height: '30px' }}>
                            <TableCell style={{ border: '0' }}>
                              <p
                                style={{
                                  textAlign: 'center',
                                  overflowWrap: 'anywhere',
                                  display: 'block',
                                }}
                              >
                                {createDateText}
                              </p>
                            </TableCell>
                            <TableCell style={{ border: '0' }}>
                              <p
                                style={{
                                  textAlign: 'center',
                                  overflowWrap: 'anywhere',
                                  display: 'block',
                                }}
                              >
                                {fileItem.createUserName}
                              </p>
                            </TableCell>
                            <TableCell style={{ border: '0' }}>
                              <p
                                style={{
                                  textAlign: 'center',
                                  overflowWrap: 'anywhere',
                                  display: 'block',
                                }}
                              >
                                {fileItem.fileName}
                              </p>
                            </TableCell>
                            <TableCell style={{ border: '0' }}>
                              <p
                                style={{
                                  textAlign: 'center',
                                  overflowWrap: 'anywhere',
                                  display: 'block',
                                }}
                              >
                                {startDateText}
                              </p>
                            </TableCell>
                            <TableCell style={{ border: '0' }}>
                              <p
                                style={{
                                  textAlign: 'center',
                                  overflowWrap: 'anywhere',
                                  display: 'block',
                                }}
                              >
                                {endDateText}
                              </p>
                            </TableCell>
                            <TableCell style={{ border: '0', textAlign: 'center' }}>
                              <Checkbox
                                disabled
                                checked={fileItem.isProcessed}
                                value={fileItem.isProcessed}
                                inputProps={{ 'aria-label': 'disabled checked checkbox' }}
                              />
                            </TableCell>
                            <TableCell style={{ border: '0', width: '20%' }}>
                              <p
                                multiline
                                style={{
                                  width: '100%',
                                  textAlign: 'center',
                                  overflowWrap: 'anywhere',
                                  display: 'block',
                                }}
                              >
                                {fileItem.resultMessage}
                              </p>
                            </TableCell>
                            <TableCell style={{ border: '0' }}>
                              <label
                                htmlFor={buttonID}
                                className="custom-file-download"
                                style={{
                                  color: 'white',
                                  border: '1px solid #ccc',
                                  display: 'inline-block',
                                  padding: '6px 12px',
                                  cursor: 'pointer',
                                  backgroundColor: '#888888',
                                  width: 'auto',
                                }}
                              >
                                <i className="fa fa-cloud-download"></i>{' '}
                                <FormattedMessage id="global.download" defaultMessage="Download" />
                              </label>
                              <a href={downloadUrl} target="blank">
                                <button id={buttonID} style={{ display: 'none' }} />
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Popover>
              ) : null}

              <input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
                onChange={(event) => {
                  readExcel(
                    //@ts-ignore
                    event
                  );
                }}
              />

              {showPatternDate ? (
                <Popover
                  open={showPatternDate}
                  canAutoPosition={false}
                  anchorPosition={{ top: 100, left: 50 }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  onClose={() => {
                    setAnchorEl(null);
                    setShowPatternDate(false);
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow style={{ height: '20px' }}>
                        <TableCell style={{ border: '0', textAlign: 'center' }} colSpan={2}>
                          <DatePicker
                            controlId={'start-date'}
                            key="start-date"
                            label={
                              <FormattedMessage id="global.startdate" defaultMessage="Start Date" />
                            }
                            value={rangeStartDate ?? undefined}
                            onChange={(date) => {
                              setRangeStartDate(date);
                            }}
                            maxValue={rangeEndDate ?? undefined}
                            disabledNextButton={
                              //@ts-ignore
                              rangeStartDate >= rangeEndDate
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ height: '20px' }}>
                        <TableCell style={{ border: '0', textAlign: 'center' }} colSpan={2}>
                          <DatePicker
                            key="end-date"
                            controlId={'end-date'}
                            label={
                              <FormattedMessage id="global.enddate" defaultMessage="End Date" />
                            }
                            value={rangeEndDate ?? undefined}
                            onChange={(date) => {
                              setRangeEndDate(date);
                            }}
                            minValue={
                              //@ts-ignore
                              rangeStartDate ?? undefined
                            }
                            disabledPrevButton={
                              //@ts-ignore
                              rangeStartDate >= rangeEndDate
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ height: '20px' }}>
                        <TableCell style={{ border: '0', textAlign: 'center' }} colSpan={2}>
                          <Select
                            id="import-forecast-dropdown"
                            value="0"
                            style={{ width: '200px', fontSize: '12px' }}
                          //onChange={(event) => this.setState({ selectedPowerPlantId: event.target.value })}
                          >
                            <MenuItem style={{ fontSize: '12px' }} value="0">
                              <FormattedMessage
                                id="global.forecastimport"
                                defaultMessage="Import Forecast"
                              />
                            </MenuItem>
                          </Select>
                        </TableCell>
                      </TableRow>
                      {
                        // rangeEndDate > rangeStartDate + 3 months
                        rangeStartDate &&
                          rangeEndDate &&
                          rangeEndDate?.getTime() > rangeStartDate?.getTime() + 7889400000 ? (
                          <TableRow style={{ height: '20px' }}>
                            <TableCell style={{ border: '0', textAlign: 'center' }} colSpan={2}>
                              <Alert variant="outlined" severity="error">
                                En fazla 3 aylık tahmin yükleyebilirsiniz!
                              </Alert>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            <TableRow style={{ height: '20px' }}>
                              <TableCell>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      id="save-as-dam-checkbox"
                                      checked={isFinalForecast}
                                      onChange={(event) => {
                                        if (event.target.checked) {
                                          setIsFinalForecast(true);
                                        } else {
                                          setIsFinalForecast(false);
                                        }
                                      }}
                                      value="allDayHours"
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <FormattedMessage
                                      id="global.saveasfinalforecast"
                                      defaultMessage="Nihai Tahmin Olarak Kaydet"
                                    />
                                  }
                                  style={{ width: '100%' }}
                                />
                              </TableCell>
                            </TableRow>

                            <TableRow style={{ height: '20px' }}>
                              <TableCell>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      id="save-as-ff-checkbox"
                                      checked={isAddFinalForecast}
                                      onChange={(event) => {
                                        if (event.target.checked) {
                                          setAddIsFinalForecast(true);
                                        } else {
                                          setAddIsFinalForecast(false);
                                        }
                                      }}
                                      value="allDayHours"
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <FormattedMessage
                                      id="global.saveasfinalforecast"
                                      defaultMessage="Nihai Tahmin Varsa Getir"
                                    />
                                  }
                                  style={{ width: '100%' }}
                                />
                              </TableCell>
                            </TableRow>

                            <TableRow style={{ height: '20px' }}>
                              <TableCell style={{ textAlign: 'right', border: '0' }}>
                                <label
                                  id="download-button"
                                  htmlFor="file-export"
                                  className="custom-file-download"
                                  style={{
                                    color: 'white',
                                    border: '1px solid #ccc',
                                    display: 'inline-block',
                                    padding: '6px 12px',
                                    cursor: 'pointer',
                                    backgroundColor: '#888888',
                                    width: 'auto',
                                  }}
                                >
                                  <i className="fa fa-cloud-download"></i>{' '}
                                  <FormattedMessage
                                    id="global.download"
                                    defaultMessage="Download"
                                  />
                                </label>
                                {/* <a href={templateUrl} target="blank"  */}
                                {/* > */}
                                <button
                                  id="file-export"
                                  style={{ display: 'none' }}
                                  onClick={exportForecastTemplate}
                                />
                                {/* </a> */}
                              </TableCell>
                            </TableRow>
                          </>
                        )
                      }
                    </TableBody>
                  </Table>
                </Popover>
              ) : null}

              <Tooltip title={<FormattedMessage id="global.save" defaultMessage="Save" />}>
                <Fab
                  style={{ width: '76px' }}
                  id="save-button"
                  variant="extended"
                  size="small"
                  color="default"
                  onMouseEnter={() => { }}
                  onClick={() => {
                    //@ts-ignore
                    if (!(selectedConsumers?.length > 0) && !showAll) {
                      Utils.showErrorMessage('Lütfen en az bir tüketici seçiniz.');
                      return;
                    }
                    generateFacilityTotalConsumptionChangedList();
                    setIsMultipleSave(true);
                    setShowPrompt(true);
                  }}
                >
                  <SaveIcon style={{ width: '25px', height: '25px' }} />
                </Fab>
              </Tooltip>

              {/* <Fab
                style={{ width: '76px' }}
                id="lock-button"
                variant="extended"
                size="small"
                color="default"
                onMouseEnter={() => {}}
                onClick={() => {}}
              >
                <LockIcon style={{ width: '25px', height: '25px' }} />
              </Fab> */}
            </div>
          </Toolbar>
        </AppBar>

        <Grid
          container
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px 0',
            justifyContent: 'space-between',
          }}
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FormControl fullWidth={true}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key="selected-date"
                  controlId={'selected-date'}
                  inputFormat="DD-MM-YYYY"
                  mask="__-__-____"
                  label={<FormattedMessage id="global.date" defaultMessage="Date" />}
                  value={currentDate ?? undefined}
                  onChange={onChangeSelectedDate}
                  renderInput={(params) => <StyledTextField {...params} />}
                  style={{ width: 'initial' }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FormControl fullWidth={true}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key="referance-date"
                  controlId={'referance-date'}
                  inputFormat="DD-MM-YYYY"
                  mask="__-__-____"
                  label={
                    <FormattedMessage id="global.reference.date" defaultMessage="Reference Date" />
                  }
                  value={referanceDate ?? undefined}
                  onChange={(newDate) => {
                    changeReferenceDate(newDate);
                  }}
                  renderInput={(params) => <StyledTextField {...params} />}
                  style={{ width: 'initial' }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2}>
            <FormControl fullWidth={true}>
              <Autocomplete
                options={units}
                disableClearable={true}
                placeholder={'Seçiniz'}
                sytle={{ marginTop: '16px' }}
                multiple={false}
                value={units.find((e) => e.value == selectedUnit)}
                onChange={(selected, event) => {
                  setSelectedUnit(event?.value ?? 0);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<FormattedMessage id="global.unit" defaultMessage="Unit" />}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl fullWidth={true}>
              <Autocomplete
                key={'facility-list'}
                ref={autocompleteRef}
                limitTags={2}
                multiple={true}
                options={facilityList?.sort((a, b) => a.parentUnitNo - b.parentUnitNo) ?? undefined}
                noOptionsText={
                  <FormattedMessage id="global.datanotfound" defaultMessage="Not found any data" />
                }
                //@ts-ignore
                groupBy={(option) => option?.parentUnitNo}
                //@ts-ignore
                getOptionLabel={(option) => option?.facilityName}
                // sx={{ width: 300 }}
                //@ts-ignore
                isOptionEqualToValue={(option, value) => option.id === value.id}
                fullWidth={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<FormattedMessage id="global.facilities" defaultMessage="Tesisler" />}
                  />
                )}
                renderOption={(props, option) => {
                  //@ts-ignore
                  if (option?.id === 0) {
                    let checked: boolean = showAll;
                    props['aria-selected'] = checked;

                    return (
                      //@ts-ignore
                      <li
                        {...props}
                        key={
                          'selectall_' +
                          //@ts-ignore
                          option?.id
                        }
                        onClick={(e) => {
                          setShowAll(!showAll);
                          if (checked) {
                            setSelectedConsumers([]);
                            setSelectedCompanies([]);
                          }
                        }}
                      >
                        {props['aria-selected'] ? (
                          <Checkbox checked={showAll} fontSize="small" style={{ zIndex: 1 }} />
                        ) : (
                          <Checkbox checked={showAll} fontSize="small" style={{ zIndex: 1 }} />
                        )}
                        {
                          //@ts-ignore
                          showAll ? 'Tümünü Kaldır' : 'Tümünü Seç'
                        }
                      </li>
                    );
                  } else {
                    if (showAll) return null;

                    let checked: boolean =
                      showAll ||
                        //@ts-ignore
                        selectedConsumers?.find((q) => q.id == option?.id)?.id > 0
                        ? true
                        : false;

                    props['aria-selected'] = checked;
                    return (
                      <li
                        {...props}
                        //@ts-ignore
                        key={option?.id}
                        onClick={() => {
                          if (checked) {
                            //@ts-ignore
                            const consumers = selectedConsumers?.filter((x) => x.id !== option.id);
                            //@ts-ignore
                            const companyInSelected = (selectedCompanies ?? []).find(
                              (x) =>
                                x ===
                                //@ts-ignore
                                option?.parentUnitNo
                            );
                            if (companyInSelected) {
                              //@ts-ignore
                              setSelectedCompanies(
                                selectedCompanies?.filter(
                                  (x) =>
                                    x !==
                                    //@ts-ignore
                                    option.parentUnitNo
                                )
                              );
                            }
                            setSelectedConsumers(consumers);
                            setExtraProvidersLoad(false);
                            setProvidersLoad(false);
                          } else {
                            const consumers = [...(selectedConsumers ?? []), option];
                            //@ts-ignore
                            const isAddedAllFacilityForCompany = facilityList
                              .filter(
                                (x) =>
                                  x.parentUnitNo ===
                                  //@ts-ignore
                                  option.parentUnitNo
                              )
                              .every((x) => consumers.some((cons) => cons.id === x.id));

                            setSelectedConsumers(consumers);
                            setExtraProvidersLoad(false);
                            setProvidersLoad(false);
                            //@ts-ignore
                            if (isAddedAllFacilityForCompany)
                              setSelectedCompanies([
                                ...(selectedCompanies ?? []),
                                //@ts-ignore
                                option.parentUnitNo,
                              ]);
                          }
                        }}
                      >
                        <Checkbox checked={checked} fontSize="small" style={{ zIndex: 1 }} />

                        {
                          //@ts-ignore
                          option?.facilityName
                        }
                      </li>
                    );
                  }
                }}
                renderGroup={(params) => {
                  //@ts-ignore
                  if (params?.key === 0) {
                    return (
                      <li key={'li_' + params.key}>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    );
                  } else {
                    if (showAll) return null;

                    return (
                      <li key={params.key}>
                        <GroupHeader>
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked) {
                                let selected = facilityList?.filter(
                                  (q) => q.parentUnitNo == params.group
                                );

                                if (
                                  selectedConsumers === undefined ||
                                  selectedConsumers?.length === 0
                                ) {
                                  setSelectedConsumers(selected);
                                  setExtraProvidersLoad(false);
                                  setProvidersLoad(false);
                                } else {
                                  setSelectedConsumers(
                                    _.uniqBy([...selectedConsumers, ...selected], 'id')
                                  );
                                  setExtraProvidersLoad(false);
                                  setProvidersLoad(false);
                                }

                                if (
                                  selectedCompanies === undefined ||
                                  selectedConsumers?.length === 0
                                ) {
                                  setSelectedCompanies([+params.group]);
                                } else {
                                  setSelectedCompanies([...selectedCompanies, +params.group]);
                                }
                              } else {
                                let operationList = [];

                                let unselected = facilityList?.filter(
                                  (q) => q.parentUnitNo == params.group
                                );

                                if (showAll) {
                                  operationList = facilityList?.filter(
                                    //@ts-ignore
                                    (q) => !unselected?.some((w) => w.id === q.id)
                                  );
                                  setShowAll(false);
                                } else {
                                  //@ts-ignore
                                  operationList = selectedConsumers?.filter(
                                    //@ts-ignore
                                    (q) => !unselected?.some((w) => w.id === q.id)
                                  );
                                }

                                setSelectedConsumers(operationList ?? []);
                                setExtraProvidersLoad(false);
                                setProvidersLoad(false);
                                setSelectedCompanies(
                                  //@ts-ignore
                                  selectedCompanies?.filter((q) => q !== +params.group)
                                );
                              }
                            }}
                            checked={
                              //@ts-ignore
                              showAll
                                ? true
                                : selectedCompanies?.some(
                                  //@ts-ignore
                                  (selected) => selected === +params.group
                                ) ?? false
                            }
                          />
                          {commonPermissionCompanies.find((company) => company.id === +params.group)
                            ?.fullname ?? ''}
                        </GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    );
                  }
                }}
                renderTags={(selectedFacilities, getTagProps) => {
                  if (showAll) {
                    return [
                      <Chip
                        {...getTagProps({ index: 0 })}
                        label={
                          <FormattedMessage
                            id="global.all"
                            defaultMessage="Selected"
                            values={{ count: facilityList?.length }}
                          />
                        }
                        size={'small'}
                      />,
                    ];
                  }
                  //@ts-ignore
                  if (selectedConsumers?.length < 2) {
                    return selectedConsumers?.map((e, i) => (
                      <Chip
                        {...getTagProps({ index: i })}
                        // @ts-ignore
                        label={e?.facilityName}
                        size={'small'}
                        style={{ maxWidth: '150px' }}
                        key={i}
                      />
                    ));
                  } else {
                    return [
                      <Chip
                        {...getTagProps({ index: 0 })}
                        label={
                          <FormattedMessage
                            id="global.itemsselected"
                            defaultMessage="Selected"
                            values={{ count: selectedConsumers?.length }}
                          />
                        }
                        size={'small'}
                      />,
                    ];
                  }
                }}
                // onChange={handleFacilityChange}
                ListboxProps={{ style: { maxHeight: 550 } }}
              ></Autocomplete>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={2} lg={2}>
            <FormGroup>
              <FormControlLabel
                disabled={false}
                control={<Switch checked={showAll} onClick={handleShowHideAll} />}
                label={
                  <FormattedMessage
                    id="global.hide.reference.facilities"
                    defaultMessage="Tüm Tesisler"
                  />
                }
              />
            </FormGroup>
          </Grid> */}

          <Grid item xs={12} sm={6} md={2} lg={2}>
            <FormGroup>
              <FormControlLabel
                disabled={false}
                control={<Switch checked={!showReferance} onClick={handleShowHideReferance} />}
                label={
                  <FormattedMessage id="global.hide.reference.forecast" defaultMessage="Date" />
                }
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Divider variant="middle" />
        <div style={containerStyle}>
          <WrappedGrid
            ref={gridRef}
            height={gridHeight}
            rowHeight={gridRowHeight}
            gridOptions={gridOptions}
            rowData={rowData}
            headerHeight={30}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            suppressLastEmptyLineOnPaste
            onSelectionChanged={() => {
              const selectedData = gridRef.current?.api.getSelectedRows();
              console.log('Selected Rows:', selectedData);
            }}
          />
        </div>
      </Box>
    </Container>
    // </Page>
  );
});

export default connect((state) => {
  return {
    //@ts-ignore
    common: state?.common,
  };
})(ConsumptionForecast);
