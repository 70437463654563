import { memo, useCallback, useEffect, useRef } from "react";
import { CustomCellEditorProps, useGridCellEditor } from "@ag-grid-community/react";

const KEY_BACKSPACE = "Backspace";
const KEY_F2 = "F2";
const KEY_ENTER = "Enter";
const KEY_TAB = "Tab";
const KEY_ARROW_LEFT = "ArrowLeft";
const KEY_ARROW_RIGHT = "ArrowRight";

const NumericCellEditor = ({ value, onValueChange, eventKey, stopEditing }: CustomCellEditorProps) => {
   const refInput = useRef<HTMLInputElement>(null);

   useEffect(() => {
      let startValue;
      let highlightAllOnFocus = true;

      if (eventKey === KEY_BACKSPACE) {
         startValue = "";
      } else if (eventKey && eventKey.length === 1) {
         startValue = eventKey;
         highlightAllOnFocus = false;
      } else {
         startValue = value;
         if (eventKey === KEY_F2) {
            highlightAllOnFocus = false;
         }
      }
      if (startValue == null) {
         startValue = "";
      }

      updateValue(startValue);

      const eInput = refInput.current!;
      eInput.focus();
      if (highlightAllOnFocus) {
         eInput.select();
      } else {
         const length = eInput.value ? eInput.value.length : 0;
         if (length > 0) {
            eInput.setSelectionRange(length, length);
         }
      }
   }, []);

   const updateValue = (val: string) => onValueChange(val === "" ? null : val);

   const onKeyDown = (event) => {
      const key = event.key;

      if (key === KEY_ARROW_LEFT || key === KEY_ARROW_RIGHT || key === KEY_BACKSPACE) {
         event.stopPropagation();
         return;
      }

      if (!(key === KEY_ENTER || key === KEY_TAB) && !/[0-9.,]/.test(key)) {
         if (event.preventDefault) event.preventDefault();
      }

      if (key === KEY_ENTER || key === KEY_TAB) {
         stopEditing();
      }
   };

   const isCancelBeforeStart = useCallback(() => {
      return !!eventKey && eventKey.length === 1 && "1234567890".indexOf(eventKey) < 0;
   }, [eventKey]);

   const isCancelAfterEnd = useCallback(() => {
      return value != null && value > 1000000;
   }, [value]);

   useGridCellEditor({
      isCancelBeforeStart,
      isCancelAfterEnd,
   });

   return (
      <input
         ref={refInput}
         value={value || ""}
         onChange={(event) => updateValue(event.target.value)}
         onKeyDown={(event) => onKeyDown(event)}
         className="numeric-input"
      />
   );
};

export default memo(NumericCellEditor);
