import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Constants } from "src/constants";
import { EContractType } from "src/enums/contract-type.enum";
import {
   ContractDetailsLoadedStatus,
   GipPlanningWebSocketConnectionStatus,
   IAlertRule,
   IAlertRuleListener,
   IAlertRuleListenerContract,
   IBoardContract,
   IBoardContractConstant,
   IBoardContractSmartBotListenerInfo,
   IBoardContractUpdateModel,
   IContractRucValueCompany,
   IContractShortInfo,
   IContractTradeDetail,
   IDepthInfo,
   IGipPlanningCalculateRuc,
   IGipPlanningCalculateRucHour,
   IGipPlanningCalculatedValues,
   IGipPlanningCalculatedWallValues,
   IGipPlanningDynamicColumn,
   IGipPlanningLiveSettings,
   IGipPlanningRadRiskInfo,
   IGipPlanningTickerInfo,
   IGipPlanningWebSocketCompany,
   IGipPlanningWebSocketStoreModel,
   ILibraryScript,
   IMyProposal,
   IMyProposalDetail,
   IPowerPlant,
   IProposalLimit,
   ISystemStatus,
   ISystemStatusServices,
   LoadingStateEnum,
   SocketConnectionType,
} from "../api/types/gipPlanningWebSocketTypes";
import _ from "lodash";
import moment from "moment-timezone";
import * as CommonActions from "src/old/src/redux/common/action";
import { isEligible } from "src/utils";
import { ETickerType } from "../../pages/intraday/enums/ETickerType";
import { v4 as uuidv4 } from "uuid";
import {
   clearGipPlanningDepthInfo,
   clearGipPlanningMyProposals,
   clearGipPlanningTickers,
   clearGipPlanningTrades,
   setGipPlanningTicker,
   setGipPlanningTickers,
   updateGipPlanningDepthInfo,
   updateGipPlanningMyProposals,
   updateGipPlanningTrades,
} from "../reducers/gipPlanning/gipPlanningActions";
import { ERegion } from "src/enums/region.enum";
import { EContractState } from "src/enums/contract-state.enum";
import { services as SERVICE_CONSTANTS } from "../../pages/intraday/constants";
import { current } from "@reduxjs/toolkit";
import PQueue from 'p-queue';
export enum GIP_WS_SUBJECTS {
   USER_GLOBAL_ERROR = "global?error",
   USER_GLOBAL_SESSIONID = "global?sessionid",
   USER_GLOBAL_SUCCESS = "global?success",
   USER_SYSTEM_MESSAGE = "system?message",
   USER_WALL_DATA = "smartpulse.intradayplanning?walldata",
   USER_WALL_UPDATE = "smartpulse.intradayplanning?wallupdate",
   USER_SERVER_TIME = "smartpulse.intradayplanningcontract?serverTime",
   USER_CONTRACT_DETAILS = "smartpulse.intradayplanningcontract?contractdetails",
   USER_SYSTEM_STATUS_UPDATE = "smartpulse.intradayplanning?systemstatusupdate",
   USER_TICKER = "volt.alerting?ticker",
   USER_DYNAMIC_COLUMN_EVAL = "smartpulse.intradayplanning?dynamiccolumneval",
   USER_VERSION_LISTENERS = "volt.alerting?version-listeners",
   USER_POWER_PLANT_DATA = "smartpulse.intradayplanning?powerplantdata",
   USER_MESSAGE_INFO = "smartpulse.idm.message?info",
   USER_MESSAGE_WARNING = "smartpulse.idm.message?warning",
   USER_SUBMIT_OFFER_ERROR = "intradayplanning.warning.submitoffererror",
   USER_PONG = "smartpulse.pinglistener?pong",
   USER_EPIAS_KOPI_INFO = "volt.epias.kopi?info",
   USER_RUC_RISK_DATA = "smartpulse.rucradreport?rucriskdata",
   USER_RAD_RISK_DATA = "smartpulse.rucradreport?radriskdata",
   GROUP_ENTER_USER = "global?enteruser",
}
export const WS_HANDLE_NAMES = {
   intradayPlanning: {
      handleName: "smartpulse/v2/intradayplanning",
      method: {
         wallData: "walldata",
         wallUpdate: "wallupdate",
         contractDetails: "contractdetails",
         enterUser: "enteruser",
         powerPlantData: "powerplantdata",
         leaveWidget: "leaveWidget",
      },
   },
   intradayPlanningContract: {
      handleName: "smartpulse/v2/intradayplanningcontract",
      method: {
         selectContract: "selectcontract",
         leaveWidget: "leaveWidget",
      },
   },
   alerting: {
      handleName: "volt/alerting",
      method: {
         ticker: "ticker,version-listeners",
      },
   },
};
const twoDigitThreshold = 0.01;
const deliveryStartFormat = "DD/MM/YYYY HH:mm:ss";
const socketFetchUrl = `${import.meta.env.REACT_APP_WS_UR}`;
// const tickerLimit = 250;

const contractTypeSortOrder = {
   1: 0,
   3: 1,
   2: 2,
};

interface IWebSocketManager {
   socket: WebSocket | undefined;
   heartbeatChecker: NodeJS.Timeout | undefined;
   dynamicColumnUpdater: NodeJS.Timeout | undefined;
   wallUpdater: NodeJS.Timeout | undefined;
}
const socketMap: Map<SocketConnectionType, IWebSocketManager> = new Map();
const dynamicColumnValueContainer: IGipPlanningDynamicColumn[] = [];
let wallUpdateContainer: any[] = [];
export async function socketSendMessage(
   widgetId: string,
   socketConnectionType: SocketConnectionType,
   to: string,
   subject: string,
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   body: any
): Promise<boolean> {
   const socketManager = await socketMap.get(socketConnectionType);
   if (socketManager && socketManager.socket && socketManager.socket.readyState === WebSocket.OPEN) {
      socketManager.socket.send(
         JSON.stringify({
            to: to,
            subject: subject,
            body: { ...body, widgetId: widgetId },
         })
      );

      return true;
   }
   return false;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapProposal = (proposal: any): IMyProposalDetail => {
   return {
      createDate: proposal.CreateDate,
      companyId: proposal.CompanyId,
      proposalId: proposal.ProposalId,
      proposalXBidId: proposal.ProposalXBidId,
      duration: proposal.Duration,
      contractIndex: proposal.ContractIndex,
      proposalType: proposal.ProposalType,
      price: proposal.Price,
      quantity: proposal.Quantity,
      remainingQuantity: proposal.RemainingQuantity,
      matchedPrice: proposal.MatchedPrice,
      matchedQuantity: proposal.MatchedQuantity,
      totalMatchedQuantity: proposal.TotalMatchedQuantity,
      status: proposal.Status,
      explanation: proposal.Explanation,
      message: proposal.Message,
      matches:
         proposal.Matches?.map((z) => {
            return {
               quantity: z.Quantity,
               price: z.Price,
               createDate: z.CreateDate,
            };
         }) ?? [],
      createUser: proposal.CreateUser,
      createUserName: proposal.CreateUserName,
      source: proposal.Source,
      smartBotId: proposal.SmartBotId,
      relatedProposalId: proposal.RelatedProposalId,
      tags:
         proposal.Tags?.map((z) => {
            return {
               title: z.Title,
               value: z.Value,
            };
         }) ?? [],
      isIceberg: proposal.IsIceberg,
      optionType: proposal.OptionType,
      validityDate: proposal.ValidityDate,
      lastModifyTimestamp: proposal.LastModifyTimestamp,
      integrationUniqueId: proposal.IntegrationUniqueId,
      revisionNo: proposal.RevisionNo,
      totalQuantity: proposal.TotalQuantity,
      peakPriceDelta: proposal.PeakPriceDelta,
      clipSize: proposal.ClipSize,
      isHibernated: proposal.IsHibernated,
      privateTradeSyncFailed: proposal.PrivateTradeSyncFailed,
   };
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapLibraryScript = (libraryScript: any): ILibraryScript => {
   const libraryScriptDraft: ILibraryScript = {
      id: libraryScript.Id,
      name: libraryScript.Name,
      parameters:
         libraryScript.Parameters?.map((x) => {
            return {
               id: x.Id,
               name: x.Name,
            };
         }) ?? [],
   };

   return libraryScriptDraft;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapAlertRule = (alertRule: any): IAlertRule => {
   const alertRuleDraft: IAlertRule = {
      id: alertRule.Id,
      name: alertRule.Name,
      // description: alertRule.Description,
      // botType: alertRule.BotType,
      // botTypeText: alertRule.BotTypeText,
      // createDate: alertRule.CreateDate,
      // createDateText: alertRule.CreateDateText,
      createUser: alertRule.CreateUser,
      createUserName: alertRule.CreateUserName,
      // createUserName: alertRule.CreateUserName,
      // exceptionMessage: alertRule.ExceptionMessage,
      // enabled: alertRule.Enabled,
      // exceptionStackTrace: alertRule.ExceptionStackTrace,
      // exceptionStage: alertRule.ExceptionStage,
      // exceptionStageText: alertRule.ExceptionStageText,
      // executionType: alertRule.ExecutionType,
      mode: alertRule.Mode,
      modeText: alertRule.ModeText,
      sharedStatus: alertRule.SharedStatus,
      listeners:
         alertRule.Listeners?.map((x) => {
            return {
               id: x.Id,
               sourceId: x.SourceId,
               sourceName: x.SourceName,
               selectedContracts: (x.SelectedContracts ?? []).map((y) => {
                  return {
                     contractId: y.ContractId,
                     deliveryStart: y.DeliveryStart,
                  } as IAlertRuleListenerContract;
               }),
            } as IAlertRuleListener;
         }) ?? [],
      actions:
         alertRule.Actions?.map((x) => {
            return {
               id: x.Id,
               scriptType: x.ScriptType,
               alertRuleLibraryScriptId: x.AlertRuleLibraryScriptId,
               parameters:
                  x.Parameters?.map((y) => {
                     return {
                        parameterId: y.ParameterId,
                        expression: y.Expression,
                     };
                  }) ?? [],
               ruleText: x.RuleText,
               ruleAction: x.RuleAction,
               companyId: x.CompanyId,
            };
         }) ?? [],
   };
   return alertRuleDraft;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapInstrumentToBoardContract = (
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   instrument: any,
   contractTitleDisplayType: number
): IBoardContractUpdateModel => {
   let contractConstant: IBoardContractConstant | undefined = undefined;
   if (instrument.ContractInfo) {
      const contractDuration = moment(instrument.ContractInfo.DeliveryEnd, deliveryStartFormat).diff(
         moment(instrument.ContractInfo.DeliveryStart, deliveryStartFormat),
         "minutes"
      );
      const displayName =
         contractTitleDisplayType == 1 && instrument.ContractInfo.PeriodName
            ? //.regionId == ERegion.Tr
              instrument.ContractInfo.PeriodName
            : instrument.ContractInfo.ContractName;

      contractConstant = {
         contractDataKey: instrument.ContractDataKey,
         contractId: instrument.ContractInfo.ContractId ?? instrument.ContractDataKey,
         contractName: instrument.ContractInfo.ContractName,
         deliveryStart: instrument.ContractInfo.DeliveryStart,
         deliveryEnd: instrument.ContractInfo.DeliveryEnd,
         periodId: instrument.ContractInfo.PeriodId,
         periodName: instrument.ContractInfo.PeriodName,
         tradingPhaseEnd: instrument.ContractInfo.TradingPhaseEnd,
         durationMinutes: contractDuration,
         contractType:
            contractDuration == 15
               ? EContractType.Quarterly
               : contractDuration == 30
                 ? EContractType.HalfHourly
                 : EContractType.Hourly,
         displayName: displayName,
         displayShortName:
            instrument.CurrentRegion === ERegion.Es
               ? displayName
               : instrument.ContractInfo.ContractName?.slice(0, 2) +
                 "-" +
                 instrument.ContractInfo.ContractName?.slice(-2),
      };
   }

   const contractDraft: IBoardContractUpdateModel = {
      contractDataKey: instrument.ContractDataKey,
      contractConstant: contractConstant,

      contractDynamic: instrument.ContractInfo
         ? {
              contractDataKey: instrument.ContractDataKey,
              isOpenForTrade: instrument.ContractInfo.IsOpenForTrade,
              remainingTime: instrument.ContractInfo.RemainingTime,
              remainingTimeSeconds: instrument.ContractInfo.RemainingTimeSeconds,
              remainingTimePercentage: instrument.ContractInfo.RemainingTimePercentage,
              state: instrument.ContractInfo.State,
           }
         : undefined,
      contractBests: instrument.ContractInfo
         ? {
              contractDataKey: instrument.ContractDataKey,
              buyContractBestPrice: instrument.ContractInfo.BuyContractBestPrice,
              buyContractBestQuantity: instrument.ContractInfo.BuyContractBestQuantity,
              sellContractBestPrice: instrument.ContractInfo.SellContractBestPrice,
              sellContractBestQuantity: instrument.ContractInfo.SellContractBestQuantity,
           }
         : undefined,
      depthSummary: instrument?.DepthSummary
         ? {
              buyDepth:
                 instrument.DepthSummary.BuyDepth?.map((x) => {
                    return { index: x.Index, rating: x.Rating };
                 }) ?? [],
              sellDepth:
                 instrument.DepthSummary.SellDepth?.map((x) => {
                    return { index: x.Index, rating: x.Rating };
                 }) ?? [],
           }
         : undefined,
      marketInfo: instrument.MarketInfo
         ? {
              contractDataKey: instrument.ContractDataKey,
              downwardImbalancesPaymentPrice: instrument.MarketInfo.DownwardImbalancesPaymentPrice,
              downwardImbalancesPaymentQuantity: instrument.MarketInfo.DownwardImbalancesPaymentQuantity,
              intradayAuctionPrice: instrument.MarketInfo.IntradayAuctionPrice,
              intradayAuctionSessions: instrument.MarketInfo.IntradayAuctionSessions ?? [],
              mcp: instrument.MarketInfo.MCP,
              smp: instrument.MarketInfo.SMP,
              systemDirection: instrument.MarketInfo.SystemDirection,
              systemDirectionHistory:
                 instrument.MarketInfo.SystemDirectionHistory?.map((x) => {
                    return {
                       date: x.Date,
                       deloading: x.Deloading,
                       loading: x.Loading,
                       value: x.Value,
                    };
                 }) ?? [],
              systemDirectionType: instrument.MarketInfo.SystemDirectionType,
              systemMarginalPriceHistory: instrument.MarketInfo.SystemMarginalPriceHistory ?? [],
              upwardImbalancesPaymentPrice: instrument.MarketInfo.UpwardImbalancesPaymentPrice,
              upwardImbalancesPaymentQuantity: instrument.MarketInfo.UpwardImbalancesPaymentQuantity,
           }
         : undefined,
      positionInfo: instrument.PositionInfo
         ? {
              wallSelectionKey: instrument.PositionInfo.WallSelectionKey,
              actualConsumption: instrument.PositionInfo.ActualConsumption,
              biliteralAggreementQuantity: instrument.PositionInfo.BiliteralAggreementQuantity,
              dayAheadBlockQuantity: instrument.PositionInfo.DayAheadBlockQuantity,
              dayAheadDefaultQuantity: instrument.PositionInfo.DayAheadDefaultQuantity,
              dayAheadElasticQuantity: instrument.PositionInfo.DayAheadElasticQuantity,
              deliveredDeloading: instrument.PositionInfo.DeliveredDeloading,
              deliveredLoading: instrument.PositionInfo.DeliveredLoading,
              deliveredMwhDeloading: instrument.PositionInfo.DeliveredMWHDeloading,
              deliveredMwhLoading: instrument.PositionInfo.DeliveredMWHLoading,
              extraPosition: instrument.PositionInfo.ExtraPosition,
              intradayBlockQuantity: instrument.PositionInfo.IntradayBlockQuantity,
              intradayDefaultQuantity: instrument.PositionInfo.IntradayDefaultQuantity,
              netBlockVolume: instrument.PositionInfo.NetBlockVolume,
              netDefaultVolume: instrument.PositionInfo.NetDefaultVolume,
              netPosition: instrument.PositionInfo.NetPosition,
              productionPrediction: instrument.PositionInfo.ProductionPrediction,
              retailQuantity: instrument.PositionInfo.RetailQuantity,
              totalDeloadingInstructionAmount: instrument.PositionInfo.TotalDeloadingInstructionAmount,
              totalLoadingInstructionAmount: instrument.PositionInfo.TotalLoadingInstructionAmount,
              virtualBiliteralAggreementQuantity: instrument.PositionInfo.VirtualBiliteralAggreementQuantity,
           }
         : undefined,
      productionInfo: instrument.ProductionInfo
         ? {
              actualProduction: _.round(instrument.ProductionInfo.ActualProduction, 2),
           }
         : undefined,
      proposalInfo: instrument.ProposalInfo
         ? {
              buyContractMyPrice: instrument.ProposalInfo.BuyContractMyPrice,
              buyContractMyQuantity: instrument.ProposalInfo.BuyContractMyQuantity,
              sellContractMyPrice: instrument.ProposalInfo.SellContractMyPrice,
              sellContractMyQuantity: instrument.ProposalInfo.SellContractMyQuantity,
           }
         : undefined,
      tradeInfo: instrument.TradeInfo
         ? {
              contractDataKey: instrument.ContractDataKey,
              lastTradeDate: instrument.TradeInfo.LastTradeDate,
              lastTradePrice: instrument.TradeInfo.LastTradePrice,
              lastTradePriceChange: instrument.TradeInfo.LastTradePriceChange,
              lastTradeQuantity: instrument.TradeInfo.LastTradeQuantity,
           }
         : undefined,
   };
   return contractDraft;
};

const defaultRadValue: IGipPlanningCalculateRuc = {
   day1: {
      dateString: undefined,
      barProgress: 0,
      groupTotalNeedMatch: 0,
      isOpened: false,
      pureRad: 0,
   },
   day2: {
      dateString: undefined,
      barProgress: 0,
      groupTotalNeedMatch: 0,
      isOpened: false,
      pureRad: 0,
   },
   hourlyRucValues: [],
   rucRange: 0,
};
// Define a service using a base URL and expected endpoints
export const socketApi = createApi({
   reducerPath: "socketApi",
   baseQuery: fetchBaseQuery({ baseUrl: socketFetchUrl }),
   endpoints: (builder) => ({
      getMessages: builder.query<IGipPlanningWebSocketStoreModel, SocketConnectionType>({
         // eslint-disable-next-line @typescript-eslint/no-unused-vars
         queryFn: (socketConnectionType: SocketConnectionType) => {
            return {
               data: {
                  connectionStatus: GipPlanningWebSocketConnectionStatus.CLOSED,
                  heartbeat: true,
                  stateUpdatedStamp: moment().add(-10, "second").toDate(),
                  lastPongTime: moment().toDate(),
                  contracts: [],
                  powerPlants: [],
                  listedContractsInfo: [],
                  selectedWall: {},
                  selectedContractDetails: {},
                  calculatedWallValues: {},
                  calculatedValues: {
                     maxMcp: 0,
                     minMcp: 0,
                     maxDirection: 0,
                     minDirection: 0,
                  },
                  proposalLimits: [],
                  rucRiskData: {
                     unitData: [],
                     unitLabel: [],
                  },
               },
            };
         },

         async onCacheEntryAdded(
            socketConnectionType,
            { cacheDataLoaded, cacheEntryRemoved, updateCachedData, getCacheEntry }
         ) {
            try {
               if (socketConnectionType == undefined) {
                  throw new Error("socketConnectionType is undefined");
               }
               let autoFetch = false;
               let tryingConnect = false;
               let tryingConnectCount = 0;
               const queue = new PQueue({concurrency: 1});
               const heartbeatCheck = async () => {
                  const { data: currentState } = getCacheEntry();
                  if (!currentState) return;
                  const lastPongTime = moment(currentState.lastPongTime);
                  const now = moment();
                  const diff = now.diff(lastPongTime, "seconds");
                  if (diff > 30) {
                     console.log("Heartbeat check failed. try reconnecting...");
                     connectSocket(true);
                  } else if (diff > 15) {
                     socketSendMessage(
                        Constants.intraday.defaultWidgetId,
                        socketConnectionType,
                        "smartpulse/pinglistener",
                        "ping",
                        {}
                     );
                  }
               };

               const clearSocketConnection = async () => {
                  if (socketMap.has(socketConnectionType)) {
                     const socket = socketMap.get(socketConnectionType)!;

                     if (socket.heartbeatChecker) {
                        await clearInterval(socket.heartbeatChecker);
                     }
                     if (socket.dynamicColumnUpdater) {
                        await clearInterval(socket.dynamicColumnUpdater);
                     }

                     if (socket.wallUpdater) {
                        await clearInterval(socket.wallUpdater);
                     }
                     if (socket.socket) {
                        socket.socket.onopen = null;
                        socket.socket.onclose = null;
                        socket.socket.onerror = null;
                        socket.socket.onmessage = null;
                        await socket.socket.close();
                     }
                  }
                  await socketMap.set(socketConnectionType, {
                     socket: undefined,
                     heartbeatChecker: undefined,
                     dynamicColumnUpdater: undefined,
                     wallUpdater: undefined
                  });
               };
               const clearReduxStates = async () => {
                  await clearGipPlanningMyProposals();
                  await clearGipPlanningDepthInfo();
                  await clearGipPlanningTrades();
                  await clearGipPlanningTickers();
               };
               const activeUserId = CommonActions.getUserInformation().userId;

               await cacheDataLoaded;
               const setTickerMessage = (ticker: IGipPlanningTickerInfo | IGipPlanningTickerInfo[]) => {
                  if (Array.isArray(ticker)) {
                     setGipPlanningTickers(ticker);
                     // draft.tickers = [...ticker, ...draft.tickers.slice(0, tickerLimit)];
                  } else {
                     setGipPlanningTicker(ticker);
                     // draft.tickers = [ticker, ...draft.tickers.slice(0, tickerLimit)];
                  }
               };

               // const socketMessagesThrottle = _.throttle(async (e) => await webSocketOnMessage(e), 500);
               const socketContractDetailsThrottle = _.throttle(async (e) => await socketContractDetails(e), 250);
               const setTickerMessageSmartBotThrottle = _.throttle(setTickerMessage, 1000);
               const setTickerMessageThrottle = _.throttle(setTickerMessage, 1000);

               // eslint-disable-next-line @typescript-eslint/no-unused-vars
               const webSocketOnOpen = (event: Event) => {
                  tryingConnectCount = 0;

                  socketSendMessage(
                     Constants.intraday.defaultWidgetId,
                     socketConnectionType,
                     "smartpulse/pinglistener",
                     "ping",
                     {}
                  );

                  if (autoFetch) {
                     console.log("Auto fetch is enabled. Fetching data...");
                     autoFetch = false;
                     const { data: currentState } = getCacheEntry();
                     const selectedWalls = Object.keys(currentState?.selectedWall ?? {}).map(
                        (x) => currentState?.selectedWall[x]
                     );
                     const selecteContracts = Object.keys(currentState?.selectedContractDetails ?? {}).map(
                        (x) => currentState?.selectedContractDetails[x]
                     );
                     for (let i = 0; i < selectedWalls.length; i++) {
                        const selectedWall = selectedWalls[i];
                        if (!selectedWall) continue;
                        socketSendMessage(
                           selectedWall.widgetId,
                           socketConnectionType,
                           WS_HANDLE_NAMES.intradayPlanning.handleName,
                           WS_HANDLE_NAMES.intradayPlanning.method.wallData,
                           {
                              companyId: selectedWall.companyId,
                              portfolioId: selectedWall.portfolioId,
                              dateIndex: selectedWall.dateIndex,
                           }
                        );
                     }
                     for (let i = 0; i < selecteContracts.length; i++) {
                        const selectedContract = selecteContracts[i];
                        if (!selectedContract) continue;
                        socketSendMessage(
                           selectedContract.widgetId,
                           socketConnectionType,
                           WS_HANDLE_NAMES.intradayPlanningContract.handleName,
                           WS_HANDLE_NAMES.intradayPlanningContract.method.selectContract,
                           {
                              selectedInstrument: selectedContract.contractDataKey,
                              companyId: selectedContract.companyId,
                           }
                        );
                     }
                  }
               };
               const getCalculatedValues = (contracts: IBoardContract[]): IGipPlanningCalculatedValues => {
                  const mcpValues = contracts.filter((e) => e.marketInfo?.mcp > 0).map((item) => item.marketInfo.mcp);
                  const directionValues = contracts
                     .filter((e) => (e.marketInfo?.systemDirection ?? 0) !== 0)
                     .map((item) => item.marketInfo.systemDirection);

                  return {
                     maxMcp: _.max(mcpValues) ?? 0,
                     minMcp: _.min(mcpValues) ?? 0,
                     maxDirection: _.max(directionValues) ?? 0,
                     minDirection: _.min(directionValues) ?? 0,
                  };
               };

               const getCalculatedWallValues = (
                  contracts: IBoardContract[],
                  selectedWallKey: string
               ): IGipPlanningCalculatedWallValues => {
                  const { data: currentState } = getCacheEntry();

                  const netPositions = contracts
                     .filter((e) => e.contractDynamic?.isOpenForTrade)
                     .map((item) => Math.abs(item.positionInfo[selectedWallKey]?.netPosition ?? 0));
                  return {
                     ...{ radValue: defaultRadValue },
                     ...currentState!.calculatedWallValues[selectedWallKey],
                     maxNetPosition: _.max(netPositions) ?? 0,
                  };
               };

               const calculateRucRad = (
                  companyId: number,
                  radRiskInfo: IGipPlanningRadRiskInfo
               ): IGipPlanningCalculateRuc => {
                  const { data: currentState } = getCacheEntry();
                  if (!currentState) return defaultRadValue;
                  // const selectedCompanyId = currentState.selectedValues.selectedCompanyId;
                  //TODO: Ruc değerlerinin hesabı düşünülmeli
                  const selectedCompanyId = companyId;
                  if (radRiskInfo) {
                     const groupTotalNeedMatchDay1 = radRiskInfo.groupRucRadValues.groupTotalNeedMatchDay1;
                     const groupTotalNeedMatchDay2 = radRiskInfo.groupRucRadValues.groupTotalNeedMatchDay2;
                     const radDay1 = radRiskInfo.groupRucRadValues.groupRadDay1;
                     const radDay2 = radRiskInfo.groupRucRadValues.groupRadDay2;

                     const day1 = radRiskInfo.companyRucRadValues.companyRucList.find((x) => x.instrumentHour < 24);
                     const day1Date = !day1
                        ? undefined
                        : moment(day1.instrumentDate, "DD-MM-YYYY HH-mm-ss").format("DD.MM.YYYY");
                     const day2Date = !day1
                        ? undefined
                        : moment(day1.instrumentDate, "DD-MM-YYYY HH-mm-ss").add(1, "days").format("DD.MM.YYYY");
                     const isDay2Opened =
                        radRiskInfo.companyRucRadValues.companyRucList.find((x) => x.instrumentHour > 24) != null
                           ? false
                           : true;

                     const hourlyRuc = radRiskInfo.companyRucRadValues.companyRucList
                        .filter((a) => (selectedCompanyId === 0 ? true : a.companyId === selectedCompanyId))
                        .map((x) => {
                           const m: IGipPlanningCalculateRucHour = {
                              companyName:
                                 currentState.userInfo?.groupCompanies.find((a) => a.companyId == x.companyId)
                                    ?.companyName ?? "undefined",
                              companyId: x.companyId,
                              ruc: x.rucValue,
                              hour: x.instrumentHour,
                              totalNeedMatch: x.totalNeedMatchforRuc,
                              instrumentDate: x.instrumentDate,
                              remainingTransactionCount: x.remainingTransactionCount,
                           };
                           return m;
                        })
                        .sort((a, b) =>
                           moment(a.instrumentDate, "DD-MM-YYYY HH:mm:ss") >
                           moment(b.instrumentDate, "DD-MM-YYYY HH:mm:ss")
                              ? -1
                              : 1
                        );

                     const calculateRucRadBarProgress = (rad) => {
                        let _barProgress = 0;
                        const rucRange = currentState.liveSettings?.regionId == 1 ? 10 : 1;
                        const max_value = 100 * rucRange;
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const percentages: any[] = [];
                        for (let i = 1; i <= 8; i++) {
                           const b = 12.5 * i * rucRange;
                           percentages.push(b);
                        }
                        if (rad > max_value) {
                           _barProgress = 7;
                        } else {
                           for (let a = 0; a <= 7; a++) {
                              if (rad >= percentages[a] && rad <= percentages[a + 1]) {
                                 _barProgress = a;
                              }
                           }
                        }
                        return _barProgress;
                     };

                     const day1BarProgress = calculateRucRadBarProgress(radDay1);
                     const day2BarProgress = calculateRucRadBarProgress(radDay2);

                     return {
                        day1: {
                           dateString: day1Date,
                           isOpened: true,
                           pureRad: radDay1,
                           barProgress: day1BarProgress,
                           groupTotalNeedMatch: groupTotalNeedMatchDay1,
                        },
                        day2: {
                           dateString: day2Date,
                           isOpened: isDay2Opened,
                           pureRad: radDay2,
                           barProgress: day2BarProgress,
                           groupTotalNeedMatch: groupTotalNeedMatchDay2,
                        },
                        hourlyRucValues: hourlyRuc,
                        rucRange: currentState.liveSettings?.regionId == 1 ? 10 : 1,
                     };
                  }
                  return defaultRadValue;
               };

               const getSystemStatusOfCompanies = (systemServices: ISystemStatusServices[], serviceName: string) => {
                  const companies = CommonActions.getPermissions().companies;
                  const connectedCompanies: IGipPlanningWebSocketCompany[] = [];
                  const rejectedCompanies: IGipPlanningWebSocketCompany[] = [];
                  if (systemServices != null && systemServices.length > 0) {
                     const liveService = systemServices.find((query: { title: string }) => query.title == serviceName);

                     if (liveService && liveService.isConnected && liveService.additionalData) {
                        if (
                           liveService.additionalData.ConnectedCompanies != null &&
                           liveService.additionalData.ConnectedCompanies.length > 0
                        ) {
                           for (let i = 0; i < liveService.additionalData.ConnectedCompanies.length; i++) {
                              const company = companies?.find(
                                 (x) => x.id == liveService!.additionalData.ConnectedCompanies[i].Id
                              );
                              if (company) {
                                 connectedCompanies.push({
                                    id: company.id,
                                    name: company.name,
                                    fullName: company.fullname,
                                    powerPlants: company.powerplants,
                                    timeZone: company.timezone,
                                 });
                              }
                           }
                        }

                        if (
                           liveService.additionalData.RejectedCompanies != null &&
                           liveService.additionalData.RejectedCompanies.length > 0
                        ) {
                           for (let i = 0; i < liveService.additionalData.RejectedCompanies.length; i++) {
                              const company = companies?.find(
                                 (x) => x.id == liveService!.additionalData.RejectedCompanies[i].Id
                              );
                              if (company) {
                                 rejectedCompanies.push({
                                    id: company.id,
                                    name: company.name,
                                    fullName: company.fullname,
                                    powerPlants: company.powerplants,
                                    timeZone: company.timezone,
                                 });
                              }
                           }
                        }
                     }
                  }

                  return { ConnectedCompanies: connectedCompanies, RejectedCompanies: rejectedCompanies };
               };

               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketSystemStatusUpdate = async (incomingMessage: any) => {
                  const { data: currentState } = getCacheEntry();
                  const services: ISystemStatusServices[] =
                     incomingMessage.body.Services?.map((x) => {
                        return {
                           additionalData: x.AdditionalData != null ? JSON.parse(x.AdditionalData) : undefined,
                           isConnected: x.IsConnected,
                           isHealthy: x.IsHealthy,
                           regionId: currentState?.liveSettings?.regionId,
                           lastUpdateDate: x.LastUpdateDate,
                           message: x.Message ?? undefined,
                           title: x.Title,
                        };
                     }) ?? [];
                  const companyStatus = getSystemStatusOfCompanies(
                     services,
                     currentState?.liveSettings?.serviceName ?? ""
                  );
                  const systemStatus: ISystemStatus = {
                     isSystemHealthy: incomingMessage.body.IsSystemHealthy,
                     services: services,
                     connectedCompanies: companyStatus.ConnectedCompanies ?? [],
                     rejectedCompanies: companyStatus.RejectedCompanies ?? [],
                  };

                  updateCachedData((draft) => {
                     if (!draft.systemStatus) {
                        draft.systemStatus = systemStatus;
                     } else {
                        if (draft.systemStatus.isSystemHealthy !== systemStatus.isSystemHealthy)
                           draft.systemStatus.isSystemHealthy = systemStatus.isSystemHealthy;
                        if (!_.isEqual(systemStatus.services, draft.systemStatus.services)) {
                           draft.systemStatus.services = systemStatus.services;
                        }
                        if (!_.isEqual(systemStatus.connectedCompanies, draft.systemStatus.connectedCompanies)) {
                           draft.systemStatus.connectedCompanies = systemStatus.connectedCompanies;
                        }
                        if (!_.isEqual(systemStatus.rejectedCompanies, draft.systemStatus.rejectedCompanies)) {
                           draft.systemStatus.rejectedCompanies = systemStatus.rejectedCompanies;
                        }
                     }
                  });
               };

               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketDynamicColumnEval = async (incomingMessage: any) => {
                  let contractColumns = dynamicColumnValueContainer.find(
                     (x) => x.contractDataKey === incomingMessage.body.ContractDataKey
                  );
                  if (!contractColumns) {
                     dynamicColumnValueContainer.push({
                        contractDataKey: incomingMessage.body.ContractDataKey,
                        columns: [],
                     } as IGipPlanningDynamicColumn);
                     contractColumns = dynamicColumnValueContainer.find(
                        (x) => x.contractDataKey === incomingMessage.body.ContractDataKey
                     )!;
                  }
                  for (let i = 0; i < incomingMessage.body.Results.length; i++) {
                     const dynamicColumnResult = incomingMessage.body.Results[i];

                     const columnDetail = contractColumns.columns.find(
                        (x) =>
                           x.detailId === dynamicColumnResult.DetailId &&
                           x.companyId == dynamicColumnResult.CompanyId &&
                           x.portfolioId == dynamicColumnResult.PortfolioId
                     );
                     if (columnDetail && columnDetail.value !== dynamicColumnResult.Value) {
                        columnDetail.value = dynamicColumnResult.Value;
                     } else {
                        contractColumns.columns.push({
                           detailId: dynamicColumnResult.DetailId,
                           value: dynamicColumnResult.Value,
                           companyId: dynamicColumnResult.CompanyId,
                           portfolioId: dynamicColumnResult.PortfolioId,
                        });
                     }
                  }
               };
               const dynamicColumnStateUpdate = async () => {
                  updateCachedData((draft) => {
                     while (dynamicColumnValueContainer.length) {
                        const contractColumns = dynamicColumnValueContainer.shift();
                        if (!contractColumns) return;

                        const _draftContract = draft.contracts.find(
                           (x) => x.contractDataKey === contractColumns.contractDataKey
                        );
                        if (!_draftContract) return;

                        for (let i = 0; i < contractColumns.columns.length; i++) {
                           const dynamicColumnResult = contractColumns.columns[i];
                           const selectedWallKey = `_${dynamicColumnResult.companyId}_${dynamicColumnResult.portfolioId}`;
                           if (!_draftContract.dynamicColumn[selectedWallKey])
                              _draftContract.dynamicColumn[selectedWallKey] = {
                                 contractDataKey: contractColumns.contractDataKey,
                                 columns: [],
                              };
                           const _draftColumnDetail = _draftContract.dynamicColumn[selectedWallKey].columns.find(
                              (x) =>
                                 x.detailId === dynamicColumnResult.detailId &&
                                 x.companyId == dynamicColumnResult.companyId &&
                                 x.portfolioId == dynamicColumnResult.portfolioId
                           );
                           if (_draftColumnDetail && _draftColumnDetail.value !== dynamicColumnResult.value) {
                              _draftColumnDetail.value = dynamicColumnResult.value;
                           } else {
                              _draftContract.dynamicColumn[selectedWallKey].columns.push({
                                 detailId: dynamicColumnResult.detailId,
                                 value: dynamicColumnResult.value,
                                 companyId: dynamicColumnResult.companyId,
                                 portfolioId: dynamicColumnResult.portfolioId,
                              });
                           }
                        }
                        _draftContract.updatedStamp = moment().unix();
                     }
                  });
               };
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketRucRiskData = async (incomingMessage: any) => {
                  try {
                     const rucRiskData = JSON.parse(incomingMessage.body);
                     updateCachedData((draft) => {
                        draft.rucRiskData = {
                           unitData: [...rucRiskData.UnitData],
                           unitLabel: [...rucRiskData.UnitLabel],
                        };
                     });
                  } catch (error) {
                     /* empty */
                  }
               };
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketRadRiskData = async (incomingMessage: any) => {
                  try {
                     const radRiskData = JSON.parse(incomingMessage.body);

                     updateCachedData((draft) => {
                        draft.radRiskInfo = {
                           companyRucRadValues: {
                              companyRucList:
                                 radRiskData.CompanyRucRadValues?.CompanyRucList?.map((x) => {
                                    return {
                                       companyId: x.CompanyId,
                                       rucValue: x.RucValue,
                                       instrumentHour: x.InstrumentHour,
                                       totalNeedMatchforRuc: x.totalNeedMatchforRuc,
                                       instrumentDate: x.InstrumentDate,
                                       remainingTransactionCount: x.RemainingTransactionCount,
                                    };
                                 }) ?? [],
                           },
                           groupRucRadValues: {
                              groupRadDay1: radRiskData.GroupRucRadValues.GroupRadDay1,
                              groupRadDay2: radRiskData.GroupRucRadValues.GroupRadDay2,
                              groupRemainingTransactionCountDay1:
                                 radRiskData.GroupRucRadValues.GroupRemainingTransactionCountDay1,
                              groupRemainingTransactionCountDay2:
                                 radRiskData.GroupRucRadValues.GroupRemainingTransactionCountDay2,
                              groupTotalNeedMatchDay1: radRiskData.GroupRucRadValues.GroupTotalNeedMatchDay1,
                              groupTotalNeedMatchDay2: radRiskData.GroupRucRadValues.GroupTotalNeedMatchDay2,
                           },
                        };
                        const timeZone =
                           draft.liveSettings?.regionTimeZone == "Europe/Lisbon"
                              ? "Europe/Madrid"
                              : draft.liveSettings?.regionTimeZone ?? "Europe/Istanbul";

                        const groupSelection = _(draft.selectedWall)
                           .groupBy((x) => x.selectedWallKey)
                           .map((v, k) => ({
                              selectedWallKey: k,
                              companyId: v[0].companyId,
                              portfolioId: v[0].portfolioId,
                           }))
                           .value();
                        if (groupSelection.length == 0)
                           groupSelection.push({ selectedWallKey: "_0_0", companyId: 0, portfolioId: 0 });

                        for (let i = 0; i < groupSelection.length; i++) {
                           const selectedWallKey = groupSelection[i].selectedWallKey;
                           const companyId = groupSelection[i].companyId;
                           const radValues = calculateRucRad(companyId, draft.radRiskInfo);
                           const ranges = [
                              {
                                 color: "red",
                                 min:
                                    draft.liveSettings?.regionId == 1
                                       ? 100 * radValues.rucRange - 1
                                       : 100 * radValues.rucRange,
                                 max: Number.MAX_SAFE_INTEGER,
                                 needMatchMin: Number.MIN_SAFE_INTEGER,
                                 needMatchMax: 150,
                              },
                              {
                                 color: "orange",
                                 min: 80 * radValues.rucRange,
                                 max: 100 * radValues.rucRange,
                                 needMatchMin: 150,
                                 needMatchMax: 300,
                              },
                              {
                                 color: "yellow",
                                 min: 40 * radValues.rucRange,
                                 max: 80 * radValues.rucRange,
                                 needMatchMin: 300,
                                 needMatchMax: 600,
                              },
                              {
                                 color: "green",
                                 min: 0,
                                 max: 40 * radValues.rucRange,
                                 needMatchMin: 600,
                                 needMatchMax: Number.MAX_SAFE_INTEGER,
                              },
                           ];
                           for (let i = 0; i < draft.contracts.length; i++) {
                              const contract = draft.contracts[i];
                              if (!contract.contractDynamic.isOpenForTrade && contract.rucValue.companies.length > 0)
                                 continue;

                              const deliveryEndHour = moment(
                                 contract.contractConstant.deliveryStart,
                                 "DD/MM/YYYY HH:mm:ss"
                              )
                                 .utc(true)
                                 .tz(timeZone)
                                 .format("DD.MM.YYYY HH");
                              for (const range of ranges) {
                                 const rucValues = radValues.hourlyRucValues.filter(
                                    (x) =>
                                       x.ruc >= range.min &&
                                       x.ruc <= range.max &&
                                       moment.utc(x.instrumentDate, "DD-MM-YYYY HH-mm-ss").format("DD.MM.YYYY HH") ===
                                          deliveryEndHour
                                 );
                                 const rucValuesNeedMatch = radValues.hourlyRucValues.filter(
                                    (x) =>
                                       x.remainingTransactionCount > range.needMatchMin &&
                                       x.remainingTransactionCount <= range.needMatchMax &&
                                       moment.utc(x.instrumentDate, "DD-MM-YYYY HH-mm-ss").format("DD.MM.YYYY HH") ===
                                          deliveryEndHour
                                 );

                                 if (rucValues.length > 0) {
                                    if (draft.contracts[i].rucValue.color != range.color)
                                       draft.contracts[i].rucValue.color = range.color;
                                    if (draft.contracts[i].rucValue.min != range.min)
                                       draft.contracts[i].rucValue.min = range.min;
                                    if (draft.contracts[i].rucValue.max != range.max)
                                       draft.contracts[i].rucValue.max = range.max;
                                    draft.contracts[i].rucValue.companies = [
                                       ...rucValues.map((x) => {
                                          const rv: IContractRucValueCompany = {
                                             companyId: x.companyId,
                                             companyName: x.companyName,
                                             ruc: x.ruc,
                                             totalNeedMatch: x.totalNeedMatch,
                                             remainingTransactionCount: x.remainingTransactionCount,
                                          };
                                          return rv;
                                       }),
                                    ];
                                 }

                                 if (rucValuesNeedMatch.length > 0) {
                                    if (draft.contracts[i].rucValue.needMatchColor != range.color)
                                       draft.contracts[i].rucValue.needMatchColor = range.color;
                                    draft.contracts[i].rucValue.needMatchCompanies = [
                                       ...rucValuesNeedMatch.map((x) => {
                                          const rv: IContractRucValueCompany = {
                                             companyId: x.companyId,
                                             companyName: x.companyName,
                                             ruc: x.ruc,
                                             totalNeedMatch: x.totalNeedMatch,
                                             remainingTransactionCount: x.remainingTransactionCount,
                                          };
                                          return rv;
                                       }),
                                    ];
                                    draft.contracts[i].updatedStamp = moment().unix();
                                 }
                              }
                           }
                           if (!draft.calculatedWallValues[selectedWallKey]?.radValue) {
                              draft.calculatedWallValues[selectedWallKey].radValue = radValues;
                           } else {
                              if (radValues) {
                                 if (
                                    !_.isEqual(
                                       radValues.day1,
                                       draft.calculatedWallValues[selectedWallKey].radValue.day1
                                    )
                                 ) {
                                    draft.calculatedWallValues[selectedWallKey].radValue.day1 = {
                                       ...draft.calculatedWallValues[selectedWallKey].radValue.day1,
                                       ...radValues.day1,
                                    };
                                 }
                                 if (
                                    !_.isEqual(
                                       radValues.day2,
                                       draft.calculatedWallValues[selectedWallKey].radValue.day2
                                    )
                                 ) {
                                    draft.calculatedWallValues[selectedWallKey].radValue.day2 = {
                                       ...draft.calculatedWallValues[selectedWallKey].radValue.day2,
                                       ...radValues.day2,
                                    };
                                 }
                                 const instrumentDateList = radValues.hourlyRucValues.map((x) => x.instrumentDate);
                                 const currentInstrumentDateList = draft.calculatedWallValues[
                                    selectedWallKey
                                 ].radValue.hourlyRucValues.map((x) => x.instrumentDate);
                                 draft.calculatedWallValues[selectedWallKey].radValue.hourlyRucValues =
                                    draft.calculatedWallValues[selectedWallKey].radValue.hourlyRucValues
                                       .filter((x) => instrumentDateList.includes(x.instrumentDate))
                                       .map((x) => {
                                          const r = radValues.hourlyRucValues.find(
                                             (y) => y.instrumentDate == x.instrumentDate
                                          );
                                          if (!r) return x;
                                          if (!_.isEqual(r, x)) return r;
                                          return x;
                                       })
                                       .concat(
                                          radValues.hourlyRucValues.filter(
                                             (x) => !currentInstrumentDateList.includes(x.instrumentDate)
                                          )
                                       );
                                 if (
                                    draft.calculatedWallValues[selectedWallKey].radValue.rucRange != radValues.rucRange
                                 )
                                    draft.calculatedWallValues[selectedWallKey].radValue.rucRange = radValues.rucRange;
                              } else {
                                 draft.calculatedWallValues[selectedWallKey].radValue = defaultRadValue;
                              }
                           }
                        }
                     });
                  } catch (error) {
                     /* empty */
                  }
               };
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketMessages = async (incomingMessage: any) => {
                  updateCachedData((draft) => {
                     draft.userMessage = {
                        parameters: JSON.parse(incomingMessage.localization.parameters),
                        localization: incomingMessage.localization,
                        messageType:
                           incomingMessage.subject == "smartpulse.idm.message?warning"
                              ? "SMARTPULSE_IDM_MESSAGE_WARNING"
                              : incomingMessage.subject == "intradayplanning.warning.submitoffererror"
                                ? "SMARTPULSE_IDM_MESSAGE_WARNING_SUBMITOFFERERROR"
                                : "SMARTPULSE_IDM_MESSAGE_INFO",
                     };

                     setGipPlanningTicker({
                        contractDataKey: draft.userMessage.parameters.ContractDataKey,
                        localization: incomingMessage.localization,
                        parameters: draft.userMessage.parameters,
                        tickerType: ETickerType.IdmMessage,
                        eventTime: moment().toDate(),
                        uuid: uuidv4(),
                     });
                  });
               };
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketVersionListener = async (incomingMessage: any) => {
                  const alertRules: IAlertRule[] = incomingMessage.body?.map((x) => mapAlertRule(x)) ?? [];

                  updateCachedData((draft) => {
                     const activeListeners = _.uniq(
                        alertRules.flatMap((x) => x.listeners.flatMap((y) => y.selectedContracts))
                     );
                     const oldActiveListeners = _.uniq(
                        draft.alertRules?.flatMap((x) => x.listeners.flatMap((y) => y.selectedContracts))
                     );

                     const closedBotsContracts = _.differenceBy(
                        oldActiveListeners,
                        activeListeners,
                        (x) => x.contractId
                     );

                     draft.alertRules = [...alertRules];

                     const groupSelection = _(draft.selectedWall)
                        .groupBy((x) => x.selectedWallKey)
                        .map((v, k) => ({
                           selectedWallKey: k,
                           companyId: v[0].companyId,
                           portfolioId: v[0].portfolioId,
                        }))
                        .value();
                     for (let i = 0; i < groupSelection.length; i++) {
                        const selectedWallKey = groupSelection[i].selectedWallKey;
                        const companyId = groupSelection[i].companyId;
                        const portfolioId = groupSelection[i].portfolioId;

                        for (let i = 0; i < activeListeners.length; i++) {
                           const listenerContractId = activeListeners[i].contractId;
                           const _draftContract = draft.contracts.find((x) => x.contractId == listenerContractId);
                           if (!_draftContract) continue;

                           const listenerInfo = alertRulesContractDetails(
                              companyId,
                              portfolioId,
                              _draftContract.contractId,
                              alertRules
                           );
                           if (_draftContract.smartBotListenerInfo[selectedWallKey]) {
                              if (
                                 _draftContract.smartBotListenerInfo[selectedWallKey].myListenerCount !=
                                    listenerInfo.myListenerCount ||
                                 _draftContract.smartBotListenerInfo[selectedWallKey].othersListenerCount !=
                                    listenerInfo.othersListenerCount
                              ) {
                                 _draftContract.smartBotListenerInfo[selectedWallKey] = { ...listenerInfo };
                                 _draftContract.updatedStamp = moment().unix();
                              }
                           } else {
                              _draftContract.smartBotListenerInfo[selectedWallKey] = listenerInfo;
                              _draftContract.updatedStamp = moment().unix();
                           }
                        }
                        for (let index = 0; index < closedBotsContracts.length; index++) {
                           const listenerContractId = closedBotsContracts[index].contractId;
                           const _draftContract = draft.contracts.find((x) => x.contractId === listenerContractId);
                           if (!_draftContract) continue;
                           _draftContract.smartBotListenerInfo[selectedWallKey] = alertRulesContractDetails(
                              companyId,
                              portfolioId,
                              _draftContract.contractId,
                              alertRules
                           );
                           _draftContract.updatedStamp = moment().unix();
                        }
                     }
                  });
               };
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketContractDetails = async (incomingMessage: any) => {
                  if (incomingMessage.body.MyProposalList) {
                     const myProposals: IMyProposal[] = incomingMessage.body.MyProposalList.map((x) => {
                        const myProposal: IMyProposal = {
                           proposals: x.Proposals?.map((y) => mapProposal(y)) ?? [] ?? [],
                           productType: x.ProductType,
                           duration: x.Duration,
                           bestBuyPrice: x.BestBuyPrice,
                           bestSellPrice: x.BestSellPrice,
                           weightedAverageActiveBuyPrice: x.WeightedAverageActiveBuyPrice,
                           weightedAverageActiveSellPrice: x.WeightedAverageActiveSellPrice,
                           totalActiveBuyQuantity: x.TotalActiveBuyQuantity,
                           totalActiveSellQuantity: x.TotalActiveSellQuantity,
                           weightedAverageTradeBuyPrice: x.WeightedAverageTradeBuyPrice,
                           weightedAverageTradeSellPrice: x.WeightedAverageTradeSellPrice,
                           totalTradeBuyQuantity: x.TotalTradeBuyQuantity,
                           totalTradeSellQuantity: x.TotalTradeSellQuantity,
                        };
                        return myProposal;
                     });
                     updateGipPlanningMyProposals(incomingMessage.body.ContractDataKey, myProposals);
                  }
                  if (incomingMessage.body.DepthInfo && incomingMessage.body.DepthInfo.Proposals) {
                     const depthInfo: IDepthInfo[] = [
                        ...incomingMessage.body.DepthInfo.Proposals.map((x) => {
                           const depthInfo: IDepthInfo = {
                              id: x.Id,
                              isMineItem: x.IsMineItem,
                              price: x.Price,
                              quantity: x.Quantity,
                              proposalType: x.ProposalType,
                              xBidId: x.XBidId ?? undefined,
                           };
                           return depthInfo;
                        }),
                     ];
                     updateGipPlanningDepthInfo(incomingMessage.body.ContractDataKey, depthInfo);
                  }

                  if (incomingMessage.body.TradeInfo && incomingMessage.body.TradeInfo.Trades) {
                     const trades = incomingMessage.body.TradeInfo?.Trades?.map((x) => {
                        const trade: IContractTradeDetail = {
                           buyProposalId: x.BuyProposalId ?? undefined,
                           date: x.Date,
                           direction: x.Direction,
                           id: x.Id,
                           price: x.Price,
                           quantity: x.Quantity,
                           sellProposalId: x.SellProposalId ?? undefined,
                           spread: x.Spread,
                        };
                        return trade;
                     });
                     updateGipPlanningTrades(incomingMessage.body.ContractDataKey, trades);
                  }
                  updateCachedData((draft) => {
                     const _draftContract = draft.contracts.find(
                        (x) => x.contractDataKey === incomingMessage.body.ContractDataKey
                     );
                     if (_draftContract) {
                        _draftContract.updatedStamp = moment().unix();
                        _draftContract.detailsLoadedStatus = ContractDetailsLoadedStatus.LOADED;
                     }
                     Object.entries(draft.selectedContractDetails).map(([key, value]) => {
                        if (value.contractDataKey === incomingMessage.body.ContractDataKey) {
                           draft.selectedContractDetails[key].loadingState = LoadingStateEnum.LOADED
                        }
                     });
                  });
               };

               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketWallUpdate = async (incomingMessage: any) => {
                  if((incomingMessage.body.Instruments??[]).length > 0) {
                     wallUpdateContainer = wallUpdateContainer.concat(incomingMessage.body.Instruments);
                  }
               }
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const socketWallUpdateProcess = async () => {
                  const { data: currentState } = getCacheEntry();
                  const contractTitleDisplayType = currentState!.liveSettings!.contractTitleDisplayType;
                  const updatedContracts = _.chain(wallUpdateContainer)
                     .groupBy("ContractDataKey")
                     .map((value, key) => {
                        const contract = value.reduce((a, b) => ({
                           ContractDataKey: key,
                           ContractInfo: {
                              ...a.ContractInfo,
                              ...b.ContractInfo,
                           },
                           MarketInfo: {
                              ...a.MarketInfo,
                              ...b.MarketInfo,
                           },
                           DepthSummary: {
                              ...a.DepthSummary,
                              ...b.DepthSummary,
                           },
                           PositionInfo: {
                              ...a.PositionInfo,
                              ...b.PositionInfo,
                           },
                           ProductionInfo: {
                              ...a.ProductionInfo,
                              ...b.ProductionInfo,
                           },
                           ProposalInfo: {
                              ...a.ProposalInfo,
                              ...b.ProposalInfo,
                           },
                           TradeInfo: {
                              ...a.TradeInfo,
                              ...b.TradeInfo,
                           },
                        }));

                        contract.ContractInfo = _.isEmpty(contract.ContractInfo) ? undefined : contract.ContractInfo;
                        contract.MarketInfo = _.isEmpty(contract.MarketInfo) ? undefined : contract.MarketInfo;
                        contract.DepthSummary = _.isEmpty(contract.DepthSummary) ? undefined : contract.DepthSummary;
                        contract.PositionInfo = _.isEmpty(contract.PositionInfo) ? undefined : contract.PositionInfo;
                        contract.ProductionInfo = _.isEmpty(contract.ProductionInfo)
                           ? undefined
                           : contract.ProductionInfo;
                        contract.ProposalInfo = _.isEmpty(contract.ProposalInfo) ? undefined : contract.ProposalInfo;
                        contract.TradeInfo = _.isEmpty(contract.TradeInfo) ? undefined : contract.TradeInfo;
                        contract.trades = [];
                        return contract;
                     })
                     .map((contract) => {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        const updateContract = mapInstrumentToBoardContract(contract, contractTitleDisplayType);
                        return updateContract;
                     })
                     .value();
                  wallUpdateContainer = [];
                  const newTickers: IGipPlanningTickerInfo[] = [];
                  const newTickersPriority: IGipPlanningTickerInfo[] = [];

                  updateCachedData((draft) => {
                     for (let i = 0; i < updatedContracts.length; i++) {
                        const updatedContract = updatedContracts[i];

                        if (
                           updatedContract.tradeInfo &&
                           !_.isEqual(
                              updatedContract.tradeInfo,
                              draft.lastTradeInfo ?? {}
                           )
                        ) {
                           const _draftContract = draft.contracts.find(
                              (x) => x.contractDataKey === updatedContract.contractDataKey
                           );
                           if (_draftContract) {
                              _draftContract.updatedStamp = moment().unix();
                           }
                           newTickersPriority.push({
                              contractDataKey: updatedContract.contractDataKey,
                              newValue: {
                                 lastTradePrice: updatedContract.tradeInfo.lastTradePrice,
                                 lastTradeQuantity: updatedContract.tradeInfo.lastTradeQuantity,
                              },
                              message: "",
                              tickerType: ETickerType.TradeLogs,
                              eventTime: moment().toDate(),
                              uuid: uuidv4(),
                           });

                           draft.lastTradeInfo = {
                              ...draft.lastTradeInfo,
                              ...updatedContract.tradeInfo,
                           };
                        }
                     }

                     for (let i = 0; i < updatedContracts.length; i++) {
                        const updatedContract = updatedContracts[i];
                        const _draftContract = draft.contracts.find(
                           (x) => x.contractDataKey === updatedContract.contractDataKey
                        );
                        let changedValues = false;
                        if (_draftContract) {
                           _draftContract.detailsLoadedStatus = ContractDetailsLoadedStatus.LOADED;
                           if (
                              updatedContract.contractDynamic &&
                              (_draftContract.contractDynamic.isOpenForTrade != updatedContract.contractDynamic.isOpenForTrade ||
                                 _draftContract.contractDynamic.state != updatedContract.contractDynamic.state ||
                                 (updatedContract.contractDynamic.isOpenForTrade && updatedContract.contractDynamic.remainingTimeSeconds<=60) ||
                                 (updatedContract.contractDynamic.isOpenForTrade && updatedContract.contractDynamic.remainingTime!=_draftContract.contractDynamic.remainingTime))
                           ) {
                              
                              _draftContract.contractDynamic = {
                                 ..._draftContract.contractDynamic,
                                 ...updatedContract.contractDynamic,
                              };
                              changedValues = true;
                              
                           }
                           if (
                              updatedContract.contractBests &&
                              !_.isEqual(
                                 updatedContract.contractBests,
                                 _draftContract.contractBests ? current(_draftContract.contractBests) : {}
                              )
                           ) {
                              if (
                                 _draftContract.contractBests.buyContractBestQuantity !=
                                 updatedContract.contractBests.buyContractBestQuantity
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.contractBests.buyContractBestQuantity,
                                    newValue: updatedContract.contractBests.buyContractBestQuantity,
                                    message: "",
                                    tickerType: ETickerType.BuyContractBestQuantity,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }

                              if (
                                 _draftContract.contractBests.buyContractBestPrice !=
                                 updatedContract.contractBests.buyContractBestPrice
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.contractBests.buyContractBestPrice,
                                    newValue: updatedContract.contractBests.buyContractBestPrice,
                                    message: "",
                                    tickerType: ETickerType.BuyContractBestPrice,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              if (
                                 _draftContract.contractBests.sellContractBestQuantity !=
                                 updatedContract.contractBests.sellContractBestQuantity
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.contractBests.sellContractBestQuantity,
                                    newValue: updatedContract.contractBests.sellContractBestQuantity,
                                    message: "",
                                    tickerType: ETickerType.SellContractBestQuantity,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              if (
                                 _draftContract.contractBests.sellContractBestPrice !=
                                 updatedContract.contractBests.sellContractBestPrice
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.contractBests.sellContractBestPrice,
                                    newValue: updatedContract.contractBests.sellContractBestPrice,
                                    message: "",
                                    tickerType: ETickerType.SellContractBestPrice,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              _draftContract.contractBests = {
                                 ..._draftContract.contractBests,
                                 ...updatedContract.contractBests,
                              };
                              changedValues = true;
                           }
                           if (updatedContract.depthSummary) {
                              if (!_draftContract.depthSummary) {
                                 _draftContract.depthSummary = updatedContract.depthSummary;
                              } else {
                                 const buyDepth = _draftContract.depthSummary?.buyDepth.map((x) => x.rating) ?? [];
                                 const sellDepth = _draftContract.depthSummary?.sellDepth?.map((x) => x.rating) ?? [];
                                 if (
                                    !_.isEqual(
                                       buyDepth,
                                       updatedContract.depthSummary.buyDepth?.map((x) => x.rating)
                                    )
                                 ) {
                                    _draftContract.depthSummary.buyDepth = updatedContract.depthSummary.buyDepth;
                                    changedValues = true;
                                 }
                                 if (
                                    !_.isEqual(
                                       sellDepth,
                                       updatedContract.depthSummary.sellDepth.map((x) => x.rating)
                                    )
                                 ) {
                                    _draftContract.depthSummary.sellDepth = updatedContract.depthSummary.sellDepth;
                                    changedValues = true;
                                 }
                              }
                           }
                           if (
                              updatedContract.marketInfo &&
                              !_.isEqual(
                                 updatedContract.marketInfo,
                                 _draftContract.marketInfo ? current(_draftContract.marketInfo) : {}
                              )
                           ) {
                              if (_draftContract.marketInfo.mcp != updatedContract.marketInfo.mcp) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.marketInfo.mcp,
                                    newValue: updatedContract.marketInfo.mcp,
                                    message: "",
                                    tickerType: ETickerType.MCP,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              if (
                                 _draftContract.marketInfo.systemDirection != updatedContract.marketInfo.systemDirection
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.marketInfo.systemDirection,
                                    newValue: updatedContract.marketInfo.systemDirection,
                                    message: "",
                                    tickerType: ETickerType.SystemDirection,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }

                              _draftContract.marketInfo = updatedContract.marketInfo;
                              changedValues = true;
                           }
                           if (
                              updatedContract.positionInfo &&
                              !_.isEqual(
                                 updatedContract.positionInfo[updatedContract.positionInfo.wallSelectionKey],
                                 _draftContract.positionInfo
                              )
                           ) {
                              const currentPositionInfo =
                                 _draftContract.positionInfo[updatedContract.positionInfo.wallSelectionKey];
                              const updatePositionInfo = updatedContract.positionInfo;
                              if (
                                 currentPositionInfo != undefined &&
                                 currentPositionInfo?.netPosition != updatePositionInfo.netPosition
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: currentPositionInfo?.netPosition,
                                    newValue: updatePositionInfo.netPosition,
                                    message: "",
                                    tickerType: ETickerType.NetPosition,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              if (
                                 currentPositionInfo != undefined &&
                                 currentPositionInfo?.biliteralAggreementQuantity !=
                                    updatePositionInfo.biliteralAggreementQuantity
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: currentPositionInfo?.biliteralAggreementQuantity,
                                    newValue: updatePositionInfo.biliteralAggreementQuantity,
                                    message: "",
                                    tickerType: ETickerType.BiliteralAggreementQuantity,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              if (
                                 currentPositionInfo != undefined &&
                                 currentPositionInfo?.dayAheadDefaultQuantity !=
                                    updatePositionInfo.dayAheadDefaultQuantity
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: currentPositionInfo?.dayAheadDefaultQuantity,
                                    newValue: updatePositionInfo.dayAheadDefaultQuantity,
                                    message: "",
                                    tickerType: ETickerType.DayAheadDefaultQuantity,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              if (
                                 currentPositionInfo != undefined &&
                                 currentPositionInfo?.dayAheadBlockQuantity != updatePositionInfo.dayAheadBlockQuantity
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: currentPositionInfo?.dayAheadBlockQuantity,
                                    newValue: updatePositionInfo.dayAheadBlockQuantity,
                                    message: "",
                                    tickerType: ETickerType.DayAheadBlockQuantity,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }

                              if (
                                 currentPositionInfo != undefined &&
                                 currentPositionInfo?.intradayDefaultQuantity !=
                                    updatePositionInfo.intradayDefaultQuantity
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: currentPositionInfo?.intradayDefaultQuantity,
                                    newValue: updatePositionInfo.intradayDefaultQuantity,
                                    message: "",
                                    tickerType: ETickerType.IntradayDefaultQuantity,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              if (
                                 currentPositionInfo != undefined &&
                                 currentPositionInfo?.intradayBlockQuantity != updatePositionInfo.intradayBlockQuantity
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: currentPositionInfo?.intradayBlockQuantity,
                                    newValue: updatePositionInfo.intradayBlockQuantity,
                                    message: "",
                                    tickerType: ETickerType.IntradayBlockQuantity,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              if (
                                 currentPositionInfo != undefined &&
                                 currentPositionInfo?.productionPrediction != updatePositionInfo.productionPrediction
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: currentPositionInfo?.productionPrediction,
                                    newValue: updatePositionInfo.productionPrediction,
                                    message: "",
                                    tickerType: ETickerType.ProductionPrediction,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              if (
                                 currentPositionInfo != undefined &&
                                 currentPositionInfo?.retailQuantity != updatePositionInfo.retailQuantity
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: currentPositionInfo?.retailQuantity,
                                    newValue: updatePositionInfo.retailQuantity,
                                    message: "",
                                    tickerType: ETickerType.RetailQuantity,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              _draftContract.positionInfo[updatePositionInfo.wallSelectionKey] = {
                                 ..._draftContract.positionInfo[updatePositionInfo.wallSelectionKey],
                                 ...updatePositionInfo,
                              };
                              changedValues = true;
                           }
                           if (
                              updatedContract.productionInfo &&
                              !_.isEqual(
                                 updatedContract.productionInfo,
                                 _draftContract.productionInfo ? current(_draftContract.productionInfo) : {}
                              )
                           ) {
                              if (
                                 Math.abs(
                                    (_draftContract.productionInfo?.actualProduction ?? 0) -
                                       (updatedContract.productionInfo?.actualProduction ?? 0)
                                 ) > twoDigitThreshold
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.productionInfo.actualProduction,
                                    newValue: updatedContract.productionInfo.actualProduction,
                                    message: "",
                                    tickerType: ETickerType.ActualProduction,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }

                              _draftContract.productionInfo = updatedContract.productionInfo;
                              changedValues = true;
                           }
                           if (
                              updatedContract.proposalInfo &&
                              !_.isEqual(
                                 updatedContract.proposalInfo,
                                 _draftContract.proposalInfo ? current(_draftContract.proposalInfo) : {}
                              )
                           ) {
                              if (
                                 _draftContract.proposalInfo.buyContractMyPrice !=
                                 updatedContract.proposalInfo.buyContractMyPrice
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.proposalInfo.buyContractMyPrice,
                                    newValue: updatedContract.proposalInfo.buyContractMyPrice,
                                    message: "",
                                    tickerType: ETickerType.BuyContractMyPrice,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              if (
                                 _draftContract.proposalInfo.sellContractMyPrice !=
                                 updatedContract.proposalInfo.sellContractMyPrice
                              ) {
                                 newTickers.push({
                                    contractDataKey: _draftContract.contractDataKey,
                                    oldValue: _draftContract.proposalInfo.sellContractMyPrice,
                                    newValue: updatedContract.proposalInfo.sellContractMyPrice,
                                    message: "",
                                    tickerType: ETickerType.SellContractMyPrice,
                                    eventTime: moment().toDate(),
                                    uuid: uuidv4(),
                                 });
                              }
                              _draftContract.proposalInfo = {
                                 ..._draftContract.proposalInfo,
                                 ...updatedContract.proposalInfo,
                              };
                              changedValues = true;
                           }
                           if (
                              updatedContract.tradeInfo &&
                              !_.isEqual(
                                 updatedContract.tradeInfo,
                                 _draftContract.tradeInfo ? current(_draftContract.tradeInfo) : {}
                              )
                           ) {
                              _draftContract.tradeInfo = updatedContract.tradeInfo;
                              changedValues = true;
                           }
                           if (changedValues) {
                              draft.calculatedValues = {
                                 ...getCalculatedValues([...draft.contracts]),
                              };
                              const groupSelection = _(draft.selectedWall)
                                 .groupBy((x) => x.selectedWallKey)
                                 .map((v, k) => k)
                                 .value();

                              for (let i = 0; i < groupSelection.length; i++) {
                                 const key = groupSelection[i];
                                 draft.calculatedWallValues = {
                                    ...draft.calculatedWallValues,
                                    [key]: { ...getCalculatedWallValues([...draft.contracts], key) },
                                 };
                              }
                              _draftContract.updatedStamp = moment().unix();
                           }
                        }
                        const state =
                           updatedContract.contractDynamic?.state ?? _draftContract?.contractDynamic?.state ?? -1;
                        const isOpenForTrade =
                           updatedContract.contractDynamic?.isOpenForTrade ??
                           _draftContract?.contractDynamic?.isOpenForTrade ??
                           false;

                        const draftListedContractInfo = draft.listedContractsInfo.find(
                           (x) => x.contractDataKey == updatedContract.contractDataKey
                        );
                        if (draftListedContractInfo) {
                           if (draftListedContractInfo.isOpenForTrade !== isOpenForTrade) {
                              draftListedContractInfo.isOpenForTrade = isOpenForTrade;
                           }
                           if (draftListedContractInfo.state !== state) {
                              draftListedContractInfo.state = state;
                           }
                        } else {
                           if (updatedContract && updatedContract.contractConstant && updatedContract.contractDynamic) {
                              draft.listedContractsInfo = _.orderBy(
                                 [
                                    ...draft.listedContractsInfo.filter((x) =>
                                       draft.contracts.map((y) => y.contractDataKey).includes(x.contractDataKey)
                                    ),
                                    {
                                       displayName: updatedContract.contractConstant.displayName,
                                       displayShortName: updatedContract.contractConstant.displayShortName,
                                       contractDataKey: updatedContract.contractDataKey,
                                       contractId: updatedContract.contractConstant.contractId,
                                       contractName: updatedContract.contractConstant.contractName,
                                       periodId: updatedContract.contractConstant.periodId,
                                       periodName: updatedContract.contractConstant.periodName,
                                       tradingPhaseEnd: updatedContract.contractConstant?.tradingPhaseEnd,
                                       deliveryStart: updatedContract.contractConstant.deliveryStart,
                                       deliveryEnd: updatedContract.contractConstant.deliveryEnd,
                                       isOpenForTrade: updatedContract.contractDynamic.isOpenForTrade,
                                       state: updatedContract.contractDynamic.state,
                                       contractType: updatedContract.contractConstant.contractType,
                                    },
                                 ],
                                 (x) => x.deliveryStart,
                                 "asc"
                              );
                           } else {
                              draft.listedContractsInfo = draft.listedContractsInfo.filter((x) =>
                                 draft.contracts.map((y) => y.contractDataKey).includes(x.contractDataKey)
                              );
                           }
                        }
                     }
                  });

                  if (newTickers.length > 0) {
                     setTickerMessageThrottle(newTickers);
                  }
                  if (newTickersPriority.length > 0) {
                     setTickerMessage(newTickersPriority);
                  }
               };

               // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
               const webSocketOnClose = (event: any) => {
                  updateCachedData((draft) => {
                     draft.connectionStatus = GipPlanningWebSocketConnectionStatus.CLOSED;
                  });

                  setTimeout(() => {
                     if (event.code !== 1006) {
                        if (tryingConnectCount < 10) {
                           console.log("webSocketOnClose- try reconnect");
                           connectSocket(true);
                           tryingConnectCount++;
                        } else {
                           location.reload();
                        }
                     } else {
                        if (tryingConnectCount < 5) {
                           console.log("webSocketOnClose- try reconnect");
                           connectSocket(true);
                           tryingConnectCount++;
                        } else {
                           location.reload();
                        }
                     }
                  }, 2000);
               };

               // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
               const wbSocketOnError = (event: any) => {
                  updateCachedData((draft) => {
                     console.log("wbSocketOnError", event);
                     draft.connectionStatus = GipPlanningWebSocketConnectionStatus.CLOSED;
                  });
               };
               const alertRulesContractDetails = (
                  companyId: number,
                  portfolioId: number,
                  contractDataKey: string,
                  alertRules: IAlertRule[]
               ): IBoardContractSmartBotListenerInfo => {
                  const portfolioCompanyIds: number[] =
                     CommonActions.getPermissions().portfolios?.find((x) => x.id == portfolioId)?.companies ?? [];

                  const result: IBoardContractSmartBotListenerInfo = {
                     myListenerCount: 0,
                     othersListenerCount: 0,
                     myListenerAlertRuleIds: [],
                     othersListenerAlertRuleIds: [],
                  };

                  const myListeners = alertRules
                     .filter(
                        (x) =>
                           ((companyId ?? 0) > 0
                              ? x.actions.findIndex((y) => y.companyId == companyId) > -1
                              : (portfolioId ?? 0) > 0
                                ? x.actions.findIndex((y) => portfolioCompanyIds.includes(y.companyId)) > -1 //portfolioCompanyIds.includes(x.actions.find((y) => y.companyId == companyId)?.companyId ?? 0)
                                : true) &&
                           x.createUser == activeUserId &&
                           x.listeners.filter((x) => x.selectedContracts?.find((s) => s.contractId == contractDataKey))
                              .length > 0
                     )
                     .map((x) => x.id);

                  const otherListeners = alertRules
                     .filter(
                        (x) =>
                           ((companyId ?? 0) > 0
                              ? x.actions.findIndex((y) => y.companyId == companyId) > -1
                              : (portfolioId ?? 0) > 0
                                ? x.actions.findIndex((y) => portfolioCompanyIds.includes(y.companyId)) > -1
                                : true) &&
                           x.createUser != activeUserId &&
                           x.listeners.filter((x) => x.selectedContracts?.find((s) => s.contractId == contractDataKey))
                              .length > 0
                     )
                     .map((x) => x.id);
                  result.myListenerCount = myListeners.length;
                  result.myListenerAlertRuleIds = myListeners;
                  result.othersListenerCount = otherListeners.length;
                  result.othersListenerAlertRuleIds = otherListeners;
                  return result;
               };

               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const webSocketOnMessageProcess = async (incomingMessage: any) => {
                  if (incomingMessage.to === "group") {
                     if (incomingMessage.subject === GIP_WS_SUBJECTS.GROUP_ENTER_USER) {
                        updateCachedData((draft) => {
                           draft.userInfo = {
                              companyId: incomingMessage.body.companyId,
                              groupId: incomingMessage.body.groupId,
                              userId: incomingMessage.body.userId,
                              userFullName: incomingMessage.body.userFullName,
                              groupCompanies:
                                 incomingMessage.body.userInfo.groupCompanies?.map((x) => {
                                    return { companyId: x.companyId, companyName: x.companyName };
                                 }) ?? [],
                              groupPowerPlants:
                                 incomingMessage.body.userInfo.groupPowerPlants?.map((x) => {
                                    return {
                                       companyId: x.companyId,
                                       powerPlantId: x.powerplantId,
                                       powerPlantName: x.powerplantName,
                                    };
                                 }) ?? [],
                              userCompanies: incomingMessage.body.userInfo.userCompanies?.map((x) => x.companyId) ?? [],
                           };
                        });
                     }
                  } else if (incomingMessage.to === "user") {
                     switch (incomingMessage.subject) {
                        case GIP_WS_SUBJECTS.USER_GLOBAL_ERROR: {
                           updateCachedData((draft) => {
                              draft.userMessage = {
                                 parameters: JSON.parse(incomingMessage.localization.parameters),
                                 localization: incomingMessage.localization,
                                 messageType: "GLOBAL_ERROR",
                              };
                              setGipPlanningTicker({
                                 contractDataKey: draft.userMessage.parameters.ContractDataKey,
                                 localization: incomingMessage.localization,
                                 parameters: draft.userMessage.parameters,
                                 tickerType: ETickerType.GlobalError,
                                 eventTime: moment().toDate(),
                                 uuid: uuidv4(),
                              });
                           });

                           break;
                        }
                        case GIP_WS_SUBJECTS.USER_GLOBAL_SUCCESS: {
                           updateCachedData((draft) => {
                              draft.userMessage = {
                                 parameters: JSON.parse(incomingMessage.localization.parameters),
                                 localization: incomingMessage.localization,
                                 messageType: "GLOBAL_SUCCESS",
                              };
                           });
                           break;
                        }
                        case GIP_WS_SUBJECTS.USER_GLOBAL_SESSIONID: {
                           updateCachedData((draft) => {
                              draft.socketSessionId = incomingMessage.body;
                           });
                           break;
                        }
                        case GIP_WS_SUBJECTS.USER_SYSTEM_MESSAGE: {
                           updateCachedData((draft) => {
                              draft.userMessage = {
                                 parameters: JSON.parse(incomingMessage.localization.parameters),
                                 localization: incomingMessage.localization,
                                 messageType: "SYSTEM_MESSAGE",
                              };
                           });
                           break;
                        }
                        case GIP_WS_SUBJECTS.USER_WALL_DATA: {
                           const widgetId = incomingMessage.body.WidgetId;
                           if (socketMap.has(socketConnectionType)) {
                              const socket = socketMap.get(socketConnectionType)!;
                              if (!socket.wallUpdater) {
                                 socket.wallUpdater = setInterval(() => {
                                    socketWallUpdateProcess();
                                 }, 500);
                              }
                           }


                           const liveServicesConfiguration: IGipPlanningLiveSettings = {
                              allowedStatesForProposalUpdate:
                                 incomingMessage.body.LiveIntradaySettings.AllowedStatesForProposalUpdate,
                              blockOfferVersion: incomingMessage.body.LiveIntradaySettings.BlockOfferVersion,
                              canCreatePassiveOffer: incomingMessage.body.LiveIntradaySettings.CanCreatePassiveOffer,
                              canEditClipSize: incomingMessage.body.LiveIntradaySettings.CanEditClipSize,
                              canEditIcebergOrder: incomingMessage.body.LiveIntradaySettings.CanEditIcebergOrder,
                              canEditIcebergPrice: incomingMessage.body.LiveIntradaySettings.CanEditIcebergPrice,
                              canEditPeakPrice: incomingMessage.body.LiveIntradaySettings.CanEditPeakPrice,
                              canEditTotalQuantity: incomingMessage.body.LiveIntradaySettings.CanEditTotalQuantity,
                              canSeeTradeHitSide: incomingMessage.body.LiveIntradaySettings.CanSeeTradeHitSide,
                              contractTitleDisplayType:
                                 incomingMessage.body.LiveIntradaySettings.ContractTitleDisplayType,
                              hideProductionPlanning: incomingMessage.body.LiveIntradaySettings.HideProductionPlanning,
                              currency: incomingMessage.body.LiveIntradaySettings.Currency,
                              currencySymbol: incomingMessage.body.LiveIntradaySettings.CurrencySymbol,
                              disableBlockProposals: incomingMessage.body.LiveIntradaySettings.DisableBlockProposals,
                              disableNetPositionTooltip:
                                 incomingMessage.body.LiveIntradaySettings.DisableNetPositionTooltip,
                              enableHibernatedProposals:
                                 incomingMessage.body.LiveIntradaySettings.EnableHibernatedProposals,
                              enableIcebergProposals: incomingMessage.body.LiveIntradaySettings.EnableIcebergProposals,
                              enableProposalTreeView: incomingMessage.body.LiveIntradaySettings.EnableProposalTreeView,
                              enableValidityDate: incomingMessage.body.LiveIntradaySettings.EnableValidityDate,
                              hideMcpMultiplierButtons:
                                 incomingMessage.body.LiveIntradaySettings.HideMcpMultiplierButtons,
                              hideNewProposalOptions: incomingMessage.body.LiveIntradaySettings.HideNewProposalOptions,
                              icebergExplanationId: incomingMessage.body.LiveIntradaySettings.IcebergExplanationId,
                              intradayPlanningColumns:
                                 incomingMessage.body.LiveIntradaySettings.IntradayPlanningColumns,
                              isUsingUtcForProposals: incomingMessage.body.LiveIntradaySettings.IsUsingUtcForProposals,
                              marketOperatorId: incomingMessage.body.LiveIntradaySettings.MarketOperatorId,
                              maxPrice: incomingMessage.body.LiveIntradaySettings.MaxPrice,
                              minimumOrderPriceSensitivity:
                                 incomingMessage.body.LiveIntradaySettings.MinimumOrderPriceSensitivity,
                              minPrice: incomingMessage.body.LiveIntradaySettings.MinPrice,
                              proposalAmountType: incomingMessage.body.LiveIntradaySettings.ProposalAmountType,
                              quantityDecimalPlaces: incomingMessage.body.LiveIntradaySettings.QuantityDecimalPlaces,
                              quantityDecimal: incomingMessage.body.LiveIntradaySettings.QuantityDecimal,
                              quantityDivider: incomingMessage.body.LiveIntradaySettings.QuantityDivider,
                              quantityIncrement: incomingMessage.body.LiveIntradaySettings.QuantityIncrement,
                              quantityUnit: incomingMessage.body.LiveIntradaySettings.QuantityUnit,
                              regionId: incomingMessage.body.LiveIntradaySettings.RegionId,
                              serviceName: incomingMessage.body.LiveIntradaySettings.ServiceName,
                              showRucRad: incomingMessage.body.LiveIntradaySettings.ShowRucRad,
                              useQuarterMinuteProposals:
                                 incomingMessage.body.LiveIntradaySettings.UseQuarterMinuteProposals,
                              validityDateMinuteSteps:
                                 incomingMessage.body.LiveIntradaySettings.ValidityDateMinuteSteps,
                              regionTimeZone: CommonActions.getUserRegionTimezone(),
                              netPositionDisplay: incomingMessage.body.NetPositionDisplay,
                              showInstrumentAlerts: isEligible(2501, 1),
                              defaultActionCompany: incomingMessage.body.DefaultActionCompany,
                           };

                           const contracts: IBoardContract[] =
                              incomingMessage.body.Instruments?.map((x) => {
                                 const mappedContract = mapInstrumentToBoardContract(x, liveServicesConfiguration.contractTitleDisplayType);
                                 const contract: IBoardContract = {
                                    contractConstant: mappedContract.contractConstant!,
                                    contractDynamic: mappedContract.contractDynamic!,
                                    contractBests: mappedContract.contractBests!,
                                    depthSummary: mappedContract.depthSummary!,
                                    marketInfo: mappedContract.marketInfo!,
                                    positionInfo: {
                                       [mappedContract.positionInfo!.wallSelectionKey]: mappedContract.positionInfo!,
                                    },
                                    productionInfo: mappedContract.productionInfo!,
                                    proposalInfo: mappedContract.proposalInfo!,
                                    contractDataKey: mappedContract.contractDataKey,
                                    updatedStamp: moment().unix(),
                                    contractId:
                                       mappedContract.contractConstant?.contractId ?? mappedContract.contractDataKey,
                                    tradeInfo: mappedContract.tradeInfo!,
                                    dynamicColumn: {},
                                    detailsLoadedStatus: ContractDetailsLoadedStatus.NOT_LOADED,
                                    smartBotListenerInfo: {},
                                    rucValue: {
                                       color: "gray",
                                       needMatchColor: "gray",
                                       min: 0,
                                       max: 0,
                                       companies: [],
                                       needMatchCompanies: [],
                                    },
                                 };

                                 return contract;
                              }) ?? [];
                           const alertRules: IAlertRule[] =
                              incomingMessage.body.AlertRules?.map((x) => mapAlertRule(x)) ?? [];

                           const libraryScripts: ILibraryScript[] =
                              incomingMessage.body.AlertRuleLibraryScriptList?.LibraryScripts?.map((x) =>
                                 mapLibraryScript(x)
                              ) ?? [];

                           const services: ISystemStatusServices[] =
                              incomingMessage.body.SystemStatus?.Services?.map((x) => {
                                 return {
                                    additionalData: x.AdditionalData != null ? JSON.parse(x.AdditionalData) : undefined,
                                    isConnected: x.IsConnected,
                                    isHealthy: x.IsHealthy,
                                    regionId: liveServicesConfiguration.regionId,
                                    lastUpdateDate: x.LastUpdateDate,
                                    message: x.Message ?? undefined,
                                    title: x.Title,
                                 };
                              }) ?? [];

                           const proposalLimits: IProposalLimit[] =
                              incomingMessage.body.ProposalLimits?.map((x) => {
                                 return {
                                    id: x.Id,
                                    region: x.Region,
                                    complexType: x.ComplexType,
                                    orderType: x.OrderType,
                                    messageId: x.MessageId,
                                    defaultMessage: x.DefaultMessage,
                                    limitationRule: x.LimitationRule,
                                    rule: {
                                       minPrice: x.Rule?.MinPrice,
                                       maxPrice: x.Rule?.MaxPrice,
                                       maxPeakPrice: x.Rule?.MaxPeakPrice,
                                       minPeakPrice: x.Rule?.MinPeakPrice,
                                       maxTotalQuantity: x.Rule?.MaxTotalQuantity,
                                       minTotalQuantity: x.Rule?.MinTotalQuantity,
                                       maxQuantity: x.Rule?.MaxQuantity,
                                       minQuantity: x.Rule?.MinQuantity,
                                    },
                                 };
                              }) ?? [];
                           const companyStatus = getSystemStatusOfCompanies(
                              services,
                              liveServicesConfiguration.serviceName
                           );
                           const systemStatus: ISystemStatus = {
                              isSystemHealthy: incomingMessage.body.SystemStatus?.IsSystemHealthy,
                              services: services,
                              connectedCompanies: companyStatus.ConnectedCompanies ?? [],
                              rejectedCompanies: companyStatus.RejectedCompanies ?? [],
                           };
                           const activeListeners = _.uniq(
                              alertRules.flatMap((x) => x.listeners.flatMap((y) => y.selectedContracts))
                           );
                           await updateCachedData((draft) => {
                              const socketManager = socketMap.get(socketConnectionType)!;
                              if (socketManager.heartbeatChecker) clearInterval(socketManager.heartbeatChecker);
                              console.log("heartbeatCheck started");
                              const heartbeatCheckerInterval = setInterval(() => {
                                 heartbeatCheck();
                              }, 5000);

                              socketManager.heartbeatChecker = heartbeatCheckerInterval;
                              socketMap.set(socketConnectionType, socketManager);
                              draft.connectionStatus = GipPlanningWebSocketConnectionStatus.OPEN;
                              draft.lastPongTime = moment().toDate();
                              draft.heartbeat = true;

                              if (draft.selectedWall[widgetId]) {
                                 draft.selectedWall[widgetId].lastFetchWallData = moment().unix();
                                 draft.selectedWall[widgetId].isLoaded = true;
                                 draft.selectedWall[widgetId].contractKeys = [
                                    ...(incomingMessage.body.Instruments?.map((x) => x.ContractDataKey) ?? []),
                                 ];
                                 draft.selectedWall[widgetId].loadingState = LoadingStateEnum.LOADED;
                              }
                              for (let i = 0; i < contracts.length; i++) {
                                 const contract = contracts[i];
                                 const _draftContract = draft.contracts.find(
                                    (x) => x.contractDataKey == contract.contractDataKey
                                 );
                                 if (_draftContract) {
                                    _draftContract.positionInfo = {
                                       ..._draftContract.positionInfo,
                                       ...contract.positionInfo,
                                    };
                                    _draftContract.proposalInfo = {
                                       ..._draftContract.proposalInfo,
                                       ...contract.proposalInfo,
                                    };
                                    _draftContract.productionInfo = contract.productionInfo;
                                 } else {
                                    draft.contracts.push(contract);
                                 }
                              }

                              if (!Object.keys(draft.selectedWall).find((x) => x != widgetId)) {
                                 const contractDataKeys = contracts.map((x) => x.contractDataKey);
                                 draft.contracts = [
                                    ...draft.contracts.filter((x) => contractDataKeys.includes(x.contractDataKey)),
                                 ];
                              }

                              const isMSeven = liveServicesConfiguration.serviceName === SERVICE_CONSTANTS.MSeven;

                              draft.contracts = [
                                 ..._.orderBy(
                                    draft.contracts,
                                    [
                                       isMSeven
                                          ? (c) => {
                                               if (
                                                  c.contractDynamic.state === EContractState.Closed ||
                                                  c.contractDynamic.state === EContractState.Inactive
                                               ) {
                                                  return -1;
                                               }
                                               return 1;
                                            }
                                          : () => 0,
                                       (c) => moment(c.contractConstant.deliveryStart, deliveryStartFormat),
                                       (c) =>
                                          moment(c.contractConstant.deliveryEnd, deliveryStartFormat).diff(
                                             moment(c.contractConstant.deliveryStart, deliveryStartFormat)
                                          ),

                                       (c) => contractTypeSortOrder[c.contractConstant.contractType],
                                       (c) =>
                                          isMSeven
                                             ? moment(
                                                  c.contractConstant.tradingPhaseEnd,
                                                  Constants.intraday.deliveryStartFormat
                                               )
                                             : 0,
                                    ],
                                    ["asc", "asc", "desc", "asc", "asc"]
                                 ),
                              ];
                              draft.listedContractsInfo = draft.contracts.map((x) => {
                                 const contractShortInfo: IContractShortInfo = {
                                    contractDataKey: x.contractDataKey,
                                    contractId: x.contractConstant.contractId,
                                    contractName: x.contractConstant.contractName,
                                    periodId: x.contractConstant.periodId,
                                    periodName: x.contractConstant.periodName,
                                    tradingPhaseEnd: x.contractConstant?.tradingPhaseEnd,
                                    deliveryStart: x.contractConstant.deliveryStart,
                                    deliveryEnd: x.contractConstant.deliveryEnd,
                                    isOpenForTrade: x.contractDynamic.isOpenForTrade,
                                    state: x.contractDynamic.state,
                                    contractType: x.contractConstant.contractType,
                                    displayName: x.contractConstant.displayName,
                                    displayShortName: x.contractConstant.displayShortName,
                                 };
                                 return contractShortInfo;
                              });
                              const groupSelection = _(draft.selectedWall)
                                 .groupBy((x) => x.selectedWallKey)
                                 .map((v, k) => ({
                                    selectedWallKey: k,
                                    companyId: v[0].companyId,
                                    portfolioId: v[0].portfolioId,
                                 }))
                                 .value();
                              for (let i = 0; i < groupSelection.length; i++) {
                                 const selectedWallKey = groupSelection[i].selectedWallKey;
                                 const companyId = groupSelection[i].companyId;
                                 const portfolioId = groupSelection[i].portfolioId;

                                 for (let i = 0; i < activeListeners.length; i++) {
                                    const listenerContractId = activeListeners[i].contractId;
                                    const contract = draft.contracts.find((x) => x.contractId == listenerContractId);
                                    if (!contract) continue;
                                    contract.smartBotListenerInfo = {
                                       ...contract.smartBotListenerInfo,
                                       [selectedWallKey]: alertRulesContractDetails(
                                          companyId,
                                          portfolioId,
                                          contract.contractId,
                                          alertRules
                                       ),
                                    };
                                 }
                              }

                              draft.calculatedValues = getCalculatedValues(contracts);
                              for (let i = 0; i < groupSelection.length; i++) {
                                 const key = groupSelection[i].selectedWallKey;
                                 draft.calculatedWallValues = {
                                    ...draft.calculatedWallValues,
                                    [key]: { ...getCalculatedWallValues([...draft.contracts], key) },
                                 };
                              }
                              draft.dynamicGroups =
                                 incomingMessage.body.DynamicGroups?.map((x) => {
                                    return {
                                       id: x.Id,
                                       name: x.Name,
                                       type: x.Type,
                                       enabled: x.Enabled,
                                       displayOrder: x.DisplayOrder,
                                       details: x.Details?.map((y) => {
                                          return {
                                             id: y.Id,
                                             name: y.Name,
                                             type: y.Type,
                                             enabled: y.Enabled,
                                             displayOrder: y.DisplayOrder,
                                             expression: y.Expression,
                                             expressionParsed: y.ExpressionParsed,
                                             groupId: y.GroupId,
                                             triggerType: y.TriggerType,
                                             calculationCategory: y.CalculationCategory,
                                             errorCount: y.ErrorCount,
                                          };
                                       }),
                                    };
                                 }) ?? [];
                              draft.liveSettings = liveServicesConfiguration;
                              draft.systemStatus = systemStatus;
                              draft.alertRules = alertRules;
                              draft.libraryScripts = libraryScripts;
                              draft.proposalLimits = proposalLimits;
                           });
                           if (socketMap.has(socketConnectionType)) {
                              const socket = socketMap.get(socketConnectionType)!;
                              if (!socket.dynamicColumnUpdater) {
                                 socket.dynamicColumnUpdater = setInterval(() => {
                                    dynamicColumnStateUpdate();
                                 }, 1000);
                              }
                           }
                           break;
                        }
                        case GIP_WS_SUBJECTS.USER_WALL_UPDATE: {
                           socketWallUpdate(incomingMessage);
                           break;
                        }
                        case GIP_WS_SUBJECTS.USER_POWER_PLANT_DATA: {
                           const widgetId = incomingMessage.body.WidgetId;
                           const liveServicesConfiguration: IGipPlanningLiveSettings = {
                              allowedStatesForProposalUpdate:
                                 incomingMessage.body.LiveIntradaySettings.AllowedStatesForProposalUpdate,
                              blockOfferVersion: incomingMessage.body.LiveIntradaySettings.BlockOfferVersion,
                              canCreatePassiveOffer: incomingMessage.body.LiveIntradaySettings.CanCreatePassiveOffer,
                              canEditClipSize: incomingMessage.body.LiveIntradaySettings.CanEditClipSize,
                              canEditIcebergOrder: incomingMessage.body.LiveIntradaySettings.CanEditIcebergOrder,
                              canEditIcebergPrice: incomingMessage.body.LiveIntradaySettings.CanEditIcebergPrice,
                              canEditPeakPrice: incomingMessage.body.LiveIntradaySettings.CanEditPeakPrice,
                              canEditTotalQuantity: incomingMessage.body.LiveIntradaySettings.CanEditTotalQuantity,
                              canSeeTradeHitSide: incomingMessage.body.LiveIntradaySettings.CanSeeTradeHitSide,
                              contractTitleDisplayType:
                                 incomingMessage.body.LiveIntradaySettings.ContractTitleDisplayType,
                              hideProductionPlanning: incomingMessage.body.LiveIntradaySettings.HideProductionPlanning,
                              currency: incomingMessage.body.LiveIntradaySettings.Currency,
                              currencySymbol: incomingMessage.body.LiveIntradaySettings.CurrencySymbol,
                              disableBlockProposals: incomingMessage.body.LiveIntradaySettings.DisableBlockProposals,
                              disableNetPositionTooltip:
                                 incomingMessage.body.LiveIntradaySettings.DisableNetPositionTooltip,
                              enableHibernatedProposals:
                                 incomingMessage.body.LiveIntradaySettings.EnableHibernatedProposals,
                              enableIcebergProposals: incomingMessage.body.LiveIntradaySettings.EnableIcebergProposals,
                              enableProposalTreeView: incomingMessage.body.LiveIntradaySettings.EnableProposalTreeView,
                              enableValidityDate: incomingMessage.body.LiveIntradaySettings.EnableValidityDate,
                              hideMcpMultiplierButtons:
                                 incomingMessage.body.LiveIntradaySettings.HideMcpMultiplierButtons,
                              hideNewProposalOptions: incomingMessage.body.LiveIntradaySettings.HideNewProposalOptions,
                              icebergExplanationId: incomingMessage.body.LiveIntradaySettings.IcebergExplanationId,
                              intradayPlanningColumns:
                                 incomingMessage.body.LiveIntradaySettings.IntradayPlanningColumns,
                              isUsingUtcForProposals: incomingMessage.body.LiveIntradaySettings.IsUsingUtcForProposals,
                              marketOperatorId: incomingMessage.body.LiveIntradaySettings.MarketOperatorId,
                              maxPrice: incomingMessage.body.LiveIntradaySettings.MaxPrice,
                              minimumOrderPriceSensitivity:
                                 incomingMessage.body.LiveIntradaySettings.MinimumOrderPriceSensitivity,
                              minPrice: incomingMessage.body.LiveIntradaySettings.MinPrice,
                              proposalAmountType: incomingMessage.body.LiveIntradaySettings.ProposalAmountType,
                              quantityDecimalPlaces: incomingMessage.body.LiveIntradaySettings.QuantityDecimalPlaces,
                              quantityDecimal: incomingMessage.body.LiveIntradaySettings.QuantityDecimal,
                              quantityDivider: incomingMessage.body.LiveIntradaySettings.QuantityDivider,
                              quantityIncrement: incomingMessage.body.LiveIntradaySettings.QuantityIncrement,
                              quantityUnit: incomingMessage.body.LiveIntradaySettings.QuantityUnit,
                              regionId: incomingMessage.body.LiveIntradaySettings.RegionId,
                              serviceName: incomingMessage.body.LiveIntradaySettings.ServiceName,
                              showRucRad: incomingMessage.body.LiveIntradaySettings.ShowRucRad,
                              useQuarterMinuteProposals:
                                 incomingMessage.body.LiveIntradaySettings.UseQuarterMinuteProposals,
                              validityDateMinuteSteps:
                                 incomingMessage.body.LiveIntradaySettings.ValidityDateMinuteSteps,
                              regionTimeZone: CommonActions.getUserRegionTimezone(),
                              netPositionDisplay: incomingMessage.body.NetPositionDisplay,
                              showInstrumentAlerts: isEligible(2501, 1),
                              defaultActionCompany: incomingMessage.body.DefaultActionCompany,
                           };

                           const contracts: IBoardContract[] =
                              incomingMessage.body.Instruments?.map((x) => {
                                 // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                 const c = mapInstrumentToBoardContract(x, liveServicesConfiguration!.contractTitleDisplayType);
                                 c.detailsLoadedStatus = ContractDetailsLoadedStatus.NOT_LOADED;
                                 c.rucValue = {
                                    color: "gray",
                                    needMatchColor: "gray",
                                    min: 0,
                                    max: 0,
                                    companies: [],
                                    needMatchCompanies: [],
                                 };
                                 return c;
                              }) ?? [];

                           const powerPlants: IPowerPlant[] =
                              incomingMessage.body.PowerPlants?.map((x) => {
                                 const powerPlant: IPowerPlant = {
                                    powerPlantId: x.PowerPlantId,
                                    powerPlantName: x.PowerPlantName,
                                    instruments: x.Instruments?.map((y) => {
                                       return {
                                          actualProduction: y.ActualProduction,
                                          delta: y.Delta,
                                          fdppDelta: y.FdppDelta,
                                          finalDailyProductionProgram: y.FinalDailyProductionProgram,
                                          firstFdpp: y.FirstFdpp,
                                          instrument: y.Instrument,
                                          productionPrediction: y.ProductionPrediction,
                                       };
                                    }),
                                 };
                                 return powerPlant;
                              }) ?? [];
                           updateCachedData((draft) => {
                              if (draft.selectedWall[widgetId]) {
                                 draft.selectedWall[widgetId].lastFetchWallData = moment().unix();
                                 draft.selectedWall[widgetId].isLoaded = true;
                                 draft.selectedWall[widgetId].contractKeys = [
                                    ...(incomingMessage.body.Instruments?.map((x) => x.ContractDataKey) ?? []),
                                 ];
                                 draft.selectedWall[widgetId].loadingState = LoadingStateEnum.LOADED;
                              }
                              draft.liveSettings = liveServicesConfiguration;
                              draft.contracts = draft.contracts.length > 0 ? draft.contracts : contracts;
                              draft.powerPlants = powerPlants;
                           });

                           break;
                        }
                        case GIP_WS_SUBJECTS.USER_CONTRACT_DETAILS: {
                           socketContractDetailsThrottle(incomingMessage);

                           break;
                        }
                        case GIP_WS_SUBJECTS.USER_SYSTEM_STATUS_UPDATE: {
                           socketSystemStatusUpdate(incomingMessage);

                           break;
                        }
                        case GIP_WS_SUBJECTS.USER_TICKER: {
                           setTickerMessageSmartBotThrottle({
                              contractDataKey: incomingMessage.body.ContractDataKey,
                              message: incomingMessage.body.Log,
                              tickerType: ETickerType.SmartBot,
                              eventTime: moment().toDate(),
                              uuid: uuidv4(),
                              botId: parseInt(incomingMessage.body.Id?.split(":")[2] ?? 0),
                           });

                           break;
                        }
                        case GIP_WS_SUBJECTS.USER_DYNAMIC_COLUMN_EVAL: {
                           socketDynamicColumnEval(incomingMessage);

                           break;
                        }
                        case GIP_WS_SUBJECTS.USER_VERSION_LISTENERS: {
                           socketVersionListener(incomingMessage);

                           break;
                        }

                        case GIP_WS_SUBJECTS.USER_MESSAGE_WARNING:
                        case GIP_WS_SUBJECTS.USER_SUBMIT_OFFER_ERROR:
                        case GIP_WS_SUBJECTS.USER_MESSAGE_INFO: {
                           socketMessages(incomingMessage);
                           break;
                        }
                        case GIP_WS_SUBJECTS.USER_SERVER_TIME:
                           updateCachedData((draft) => {
                              draft.serverTime = {
                                 offset: incomingMessage.body.offset,
                                 serverTime: incomingMessage.body.serverTime,
                                 timeZone: incomingMessage.body.timeZone,
                              };
                           });

                           break;
                        case GIP_WS_SUBJECTS.USER_EPIAS_KOPI_INFO: {
                           // TODO: remove jquery
                           $("#kopiCheck").show("slow");
                           $("#proposal-additional-warning").text(incomingMessage.body);
                           break;
                        }

                        case GIP_WS_SUBJECTS.USER_RUC_RISK_DATA: {
                           socketRucRiskData(incomingMessage);
                           break;
                        }
                        case GIP_WS_SUBJECTS.USER_RAD_RISK_DATA: {
                           socketRadRiskData(incomingMessage);
                           break;
                        }

                        case GIP_WS_SUBJECTS.USER_PONG: {
                           updateCachedData((draft) => {
                              if (draft.connectionStatus != GipPlanningWebSocketConnectionStatus.OPEN) {
                                 draft.connectionStatus = GipPlanningWebSocketConnectionStatus.OPEN;
                              }
                              draft.lastPongTime = moment().toDate();
                              draft.heartbeat = true;
                           });
                           setTimeout(() => {
                              socketSendMessage(
                                 Constants.intraday.defaultWidgetId,
                                 socketConnectionType,
                                 "smartpulse/pinglistener",
                                 "ping",
                                 {}
                              );
                           }, 3000);
                           break;
                        }
                        default: {
                           console.log("invalid subject");
                           break;
                        }
                     }
                  }
               };
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               const webSocketOnMessage = async (event: MessageEvent<any>) => {
                  // console.log(event);
                  (async () => {
                     await queue.add(() => {
                        const incomingMessage = JSON.parse(event.data);
                        webSocketOnMessageProcess(incomingMessage);
                     });
                  })();

               };

               const connectSocket = async (_autoFetch = false) => {
                  try {
                     if (tryingConnect) {
                        console.log("already connection repair");
                        return;
                     }
                     tryingConnect = true;
                     autoFetch = _autoFetch;
                     const socketUrl =
                        socketConnectionType == SocketConnectionType.ALL_CONTRACTS
                           ? Constants.intraday.intradayQuarterlyWebSocketUrl
                           : Constants.intraday.intradayWebSocketUrl;
                     await clearSocketConnection();
                     const socketManager = socketMap.get(socketConnectionType)!;
                     socketManager.socket = new WebSocket(window.location.origin.replace("http", "ws") + socketUrl);

                     socketManager.socket.onopen = webSocketOnOpen;
                     socketManager.socket.onclose = webSocketOnClose;
                     socketManager.socket.onerror = wbSocketOnError;
                     socketManager.socket.onmessage = webSocketOnMessage;

                     setTimeout(() => {
                        tryingConnect = false;
                     }, 2000);
                  } catch (error) {
                     console.log("connectSocket Error:", error);
                     setTimeout(() => {
                        connectSocket(true);
                     }, 2000);
                  }
               };

               await connectSocket();
               await cacheEntryRemoved;

               await clearSocketConnection();
               if (![...socketMap.keys()].find((x) => x != socketConnectionType)) await clearReduxStates();
               await socketMap.delete(socketConnectionType);
            } catch {
               /* empty */
            }
         },
      }),
   }),
});

export const { useGetMessagesQuery } = socketApi;
