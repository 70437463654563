import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as consumptionForcastService from 'src/services/consumptionForcast/consumptionForecast.service';

//#region Grid

import { GridApi, GridReadyEvent } from '@ag-grid-community/core';
import WrappedGrid, { WrappedGridRef } from 'src/components/grid';
import moment from 'moment';
import { ConsumptionGopLockType } from 'src/enums/consumption-gop-lock-type.enum';
import * as Utils from 'src/utils';
//#region Grid

type AgGridApi = {
  grid?: GridApi;
};

const ConsumptionForecastLockGop = ({ selectedConsumers, appuserid, saveButonClickCount }) => {
  //#region Grid

  const [lockClose, setLockClose] = useState<any>([
    {
      id: 1,
      dataValueHour: 12,
      dataValueMinute: 30,
      dataValueSecond: 0,
      description: 'EPİAS_GÖPKapanış',
      value: ConsumptionGopLockType.EPIAS_GOP_KAPANIS,
    },
  ]);

  const [rowData, setRowData] = useState<any[]>([
    {
      field_1: true,
      field_2: '',
      field_3: 'Tümü',
      field_4: '',
      field_13: '',
      field_5: '',
      field_6: '',
      field_7: '',
      field_8: '',
      field_9: '',
      field_10: '',
      field_11: '',
      field_12: '',
    },
  ]);

  const [rowDataList, setRowDataList] = useState<any[]>([]);

  const columnPrimary = {
    colId: 'field_1',
    field: 'field_1',
    headerName: 'Aktif',
    pinned: 'left',
    width: 75,

    cellRendererSelector: (p) => {
      return {
        component: RenderPrimaryColumn,
        params: { selected: p?.value },
      };
    },
  };

  const columnPrimaryList = {
    colId: 'field_1',
    field: 'field_1',
    headerName: 'Aktif',
    pinned: 'left',
    width: 75,

    cellRendererSelector: (p) => {
      return {
        component: RenderPrimaryColumnList,
        params: { row: p },
      };
    },
  };

  // const columnSecondary = {
  //   colId: 'field_2',
  //   field: 'field_2',
  //   headerName: 'Uygulanacak Tesisler',
  //   editable: false,
  //   width: 150,

  //   cellStyle: {
  //     display: 'flex',
  //     padding: '0px',
  //     justifyContent: 'flex-end',
  //     alignItems: 'center',
  //   },

  //   cellRendererSelector: (p) => {
  //     return {
  //       component: RenderColumnSecondary,
  //       params: { total: p?.value, parentSelectedConsumers: selectedConsumers },
  //     };
  //   },
  // };

  const columnSecondaryFacility = {
    colId: 'field_2',
    field: 'field_2',
    headerName: 'Tesis',
    editable: false,
    width: 150,
  };

  const columnTertiary = {
    colId: 'field_3',
    field: 'field_3',
    headerName: 'Kontrat Seçimi',
    editable: false,
    width: 130,
  };

  const columnQuaternary = {
    colId: 'field_4',
    field: 'field_4',
    headerName: 'Kilit Kapanış',
    editable: false,
    width: 120,

    cellStyle: {
      display: 'flex',
      padding: '0px',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    cellRendererSelector: (p) => {
      return {
        component: RenderColumnQuaternary,
      };
    },
  };

  const columnQuaternaryList = {
    colId: 'field_4',
    field: 'field_4',
    headerName: 'Kilit Kapanış',
    editable: false,
    width: 120,

    cellStyle: {
      display: 'flex',
      padding: '0px',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    cellRendererSelector: (p) => {
      return {
        component: RenderColumnQuaternaryList,
        params: { row: p },
      };
    },
  };

  const columnCloseSettingsMinute = {
    colId: 'field_13',
    field: 'field_13',
    headerName: 'Kapanış Ayar (dk önce)',
    editable: false,
    width: 130,

    cellRendererSelector: (p) => {
      return {
        component: RenderolumnCloseSettingsMinute,
      };
    },
  };

  const columnCloseSettingsMinuteList = {
    colId: 'field_13',
    field: 'field_13',
    headerName: 'Kapanış Ayar (dk önce)',
    editable: false,
    width: 130,

    cellRendererSelector: (p) => {
      return {
        component: RenderolumnCloseSettingsMinuteList,
        params: { row: p },
      };
    },
  };

  const columnQuinary = {
    colId: 'field_5',
    field: 'field_5',
    headerName: 'Kapanış Ön Gösterim',
    editable: false,
    width: 150,
  };

  const columnSenary = {
    colId: 'field_6',
    field: 'field_6',
    headerName: 'Kilit Açılış',
    editable: false,
    width: 120,

    cellStyle: {
      display: 'flex',
      padding: '0px',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    cellRendererSelector: (p) => {
      return {
        component: RenderColumnSenary,
      };
    },
  };

  const columnSenaryList = {
    colId: 'field_6',
    field: 'field_6',
    headerName: 'Kilit Açılış',
    editable: false,
    width: 120,

    cellStyle: {
      display: 'flex',
      padding: '0px',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    cellRendererSelector: (p) => {
      return {
        component: RenderColumnSenaryList,
        params: { row: p },
      };
    },
  };

  const columnSeptenary = {
    colId: 'field_7',
    field: 'field_7',
    headerName: 'Açılış Ayar (dk sonra)',
    editable: false,
    width: 130,

    cellRendererSelector: (p) => {
      return {
        component: RenderColumnSeptenary,
      };
    },
  };

  const columnSeptenaryList = {
    colId: 'field_7',
    field: 'field_7',
    headerName: 'Açılış Ayar (dk sonra)',
    editable: false,
    width: 130,

    cellRendererSelector: (p) => {
      return {
        component: RenderColumnSeptenaryList,
        params: { row: p },
      };
    },
  };

  const columnOctonary = {
    colId: 'field_8',
    field: 'field_8',
    headerName: 'Açılış Ön Gösterim',
    editable: false,
    width: 150,
  };

  const columnNonary = {
    colId: 'field_9',
    field: 'field_9',
    headerName: 'Mail Ayar (dk önce)',
    editable: false,
    width: 120,

    cellRendererSelector: (p) => {
      return {
        component: RenderColumnNonary,
      };
    },
  };

  const columnNonaryList = {
    colId: 'field_9',
    field: 'field_9',
    headerName: 'Mail Ayar (dk)',
    editable: false,
    width: 120,

    cellRendererSelector: (p) => {
      return {
        component: RenderColumnNonaryList,
        params: { row: p },
      };
    },
  };

  const columnDenary = {
    colId: 'field_10',
    field: 'field_10',
    headerName: 'Mail Gönderim Başlangıç Saati',
    editable: false,
    width: 200,
  };

  const columnUndenary = {
    colId: 'field_11',
    field: 'field_11',
    headerName: 'Kaç Defa',
    editable: false,
    width: 100,

    cellRendererSelector: (p) => {
      return {
        component: RenderColumnUndenary,
      };
    },
  };

  const columnUndenaryList = {
    colId: 'field_11',
    field: 'field_11',
    headerName: 'Kaç Defa',
    editable: false,
    width: 100,

    cellRendererSelector: (p) => {
      return {
        component: RenderColumnUndenaryList,
        params: { row: p },
      };
    },
  };

  const columnDuodenary = {
    colId: 'field_12',
    field: 'field_12',
    headerName: 'Kaç Dkda Bir',
    editable: false,
    width: 120,

    cellRendererSelector: (p) => {
      return {
        component: RenderColumnDuodenary,
      };
    },
  };

  const columnDuodenaryList = {
    colId: 'field_12',
    field: 'field_12',
    headerName: 'Kaç Dkda Bir',
    editable: false,
    width: 120,

    cellRendererSelector: (p) => {
      return {
        component: RenderColumnDuodenaryList,
        params: { row: p },
      };
    },
  };

  const columnDefinitions = [
    columnPrimary,
    // columnSecondary,
    columnTertiary,
    columnQuaternary,
    columnCloseSettingsMinute,
    columnQuinary,
    columnSenary,
    columnSeptenary,
    columnOctonary,
    columnNonary,
    columnDenary,
    columnUndenary,
    columnDuodenary,
  ];

  const gridOptions = {
    columnDefs: [
      columnPrimary,
      // columnSecondary,
      columnTertiary,
      columnQuaternary,
      columnQuinary,
      columnSenary,
      columnSeptenary,
      columnOctonary,
      columnNonary,
      columnDenary,
      columnUndenary,
      columnDuodenary,
      columnCloseSettingsMinute,
    ],
    rowData: rowData,
    autoHeight: true,
    alwaysShowHorizontalScroll: true,
  };

  const columnDefinitionsList = [
    columnPrimaryList,
    columnSecondaryFacility,
    columnTertiary,
    columnQuaternaryList,
    columnCloseSettingsMinuteList,
    columnQuinary,
    columnSenaryList,
    columnSeptenaryList,
    columnOctonary,
    columnNonaryList,
    columnDenary,
    columnUndenaryList,
    columnDuodenaryList,
  ];

  const gridOptionsList = {
    columnDefs: [
      columnPrimaryList,
      columnSecondaryFacility,
      columnTertiary,
      columnQuaternaryList,
      columnQuinary,
      columnSenaryList,
      columnSeptenaryList,
      columnOctonary,
      columnNonaryList,
      columnDenary,
      columnUndenaryList,
      columnDuodenaryList,
      columnCloseSettingsMinuteList,
    ],
    rowData: rowDataList,
    autoHeight: true,
    alwaysShowHorizontalScroll: true,
  };

  const gridRef = useRef<WrappedGridRef>(null);
  const gridRefList = useRef<WrappedGridRef>(null);
  const gridHeight = 80 + 'px';
  //   const [rowData, setRowData] = useState<any[]>();
  const [columnDefs, setColumnDefs] = useState(columnDefinitions);
  const [columnDefsList, setColumnDefsList] = useState(columnDefinitionsList);

  const [selectedMakes, setSelectedMakes] = useState([]);
  const defaultColDef = useMemo(() => {
    return {
      sortable: false,
      resizable: true,
      filter: false,
    };
  }, []);

  const apiRef = useRef<GridApi>({
    grid: undefined,
  });

  const apiRefList = useRef<GridApi>({
    grid: undefined,
  });

  const onGridReady = (params: GridReadyEvent) => {
    const { api } = params;

    apiRef.current.grid = api;
  };

  const onGridReadyList = (params: GridReadyEvent) => {
    const { api } = params;

    apiRefList.current.grid = api;
  };

  const RenderPrimaryColumn = (row: any, selected) => {
    const [selectedValue, setSelectedValue] = useState<any>(selected ?? false);

    const onChange = (event: any) => {
      setSelectedValue(event.target.checked);

      // update rowData state set field_1 new value
      let updatedRowData = [...rowData];
      updatedRowData[row.rowIndex].field_1 = event.target.checked;
      setRowData(updatedRowData);

      //refresh grid
      gridRef.current?.api?.refreshCells({ force: true });
    };

    return (
      <Checkbox sx={{ padding: '0px' }} checked={selectedValue ?? false} onChange={onChange} />
    );
  };

  const RenderPrimaryColumnList = (row: any) => {
    const [selectedValue, setSelectedValue] = useState<any>(row?.data?.field_1 ?? false);

    const onChange = (event: any) => {
      setSelectedValue(event.target.checked);

      gridRefList?.current?.api?.forEachNode((node) => {
        if (node.data.id === row.node.data.id) {
          node.setDataValue('field_1', event.target.checked);

          gridRefList.current?.api?.refreshCells({ force: true });
        }
      });
    };

    return (
      <Checkbox sx={{ padding: '0px' }} checked={selectedValue ?? false} onChange={onChange} />
    );
  };

  const RenderColumnQuaternary = (row: any) => {
    const [selectedValue, setSelectedValue] = useState<any>();

    const onChange = (event: any) => {
      setSelectedValue(event.target.value);

      let processDate = new Date();
      processDate?.setHours(lockClose[0]?.dataValueHour);
      processDate?.setMinutes(lockClose[0]?.dataValueMinute);
      processDate?.setSeconds(lockClose[0]?.dataValueSecond);
      processDate?.setMilliseconds(0);

      let updatedRowData = [...rowData];
      updatedRowData[row.rowIndex].field_4 = event.target.value ?? '';
      updatedRowData[row.rowIndex].field_5 = processDate?.toLocaleTimeString('tr-TR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });

      setRowData(updatedRowData);

      gridRef.current?.api?.refreshCells({ force: true });
    };

    return (
      <FormControl variant="standard" fullWidth={true} sx={{ padding: '0px' }}>
        <Select
          value={selectedValue ?? ''}
          onChange={onChange}
          disableUnderline={true}
          input={<OutlinedInput label="Tag" />}
          sx={{
            padding: '0px',
            '& .MuiSelect-select': {
              padding: '4px',
              border: '0px solid transparent',
              width: '100%',
            },
            '& .uiInputBase-colorPrimary': {
              ':hover': {
                border: '0px solid transparent',
              },
            },
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '& .MuiOutlinedInput-input': {
              fontSize: '12px',
              padding: '0 24px 0 0',
            },
            '& .MuiTypography-root': {
              fontSize: '12px',
            },
          }}
        >
          {lockClose.map((item, index) => (
            <MenuItem key={index} value={item} style={{ padding: '4px' }} sx={{ fontSize: '12px' }}>
              <ListItemText primary={item.description} style={{ fontSize: '11px' }} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const RenderColumnQuaternaryList = ({ row }) => {
    const [selectedValue, setSelectedValue] = useState<any>(row?.data?.field_4 ?? '');

    const onChange = (event: any) => {
      setSelectedValue(event.target.value ?? '');
      gridRefList?.current?.api?.forEachNode((node) => {
        if (node.data.id === row.node.data.id) {
          let processDate = new Date();
          processDate?.setHours(lockClose[0]?.dataValueHour);
          processDate?.setMinutes(lockClose[0]?.dataValueMinute);
          processDate?.setSeconds(lockClose[0]?.dataValueSecond);
          processDate?.setMilliseconds(0);

          node.setDataValue('field_4', event.target.value ?? '');
          node.setDataValue('field_5', moment(processDate).format('DD.MM.YYYY HH:mm:ss'));

          gridRefList.current?.api?.refreshCells({ force: true });
        }
      });
    };

    return (
      <FormControl variant="standard" fullWidth={true} sx={{ padding: '0px' }}>
        <Select
          value={selectedValue ?? ''}
          onChange={(e) => onChange(e)}
          disableUnderline={true}
          input={<OutlinedInput label="Tag" />}
          sx={{
            padding: '0px',
            '& .MuiSelect-select': {
              padding: '4px',
              border: '0px solid transparent',
              width: '100%',
            },
            '& .uiInputBase-colorPrimary': {
              ':hover': {
                border: '0px solid transparent',
              },
            },
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '& .MuiOutlinedInput-input': {
              fontSize: '12px',
              padding: '0 24px 0 0',
            },
            '& .MuiTypography-root': {
              fontSize: '12px',
            },
          }}
        >
          {lockClose.map((item, index) => (
            <MenuItem key={index} value={item} style={{ padding: '4px' }} sx={{ fontSize: '12px' }}>
              <ListItemText primary={item.description} style={{ fontSize: '11px' }} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const RenderolumnCloseSettingsMinute = (row: any) => {
    const onChange = (event: any) => {
      let processMin = 0;
      if (
        event?.target?.value === null ||
        event?.target?.value === undefined ||
        event?.target?.value === '' ||
        Number.isNaN(Number(event?.target?.value))
      ) {
        processMin = 0;
      } else {
        processMin = Number(event?.target?.value);
      }

      let processDate = new Date();
      processDate?.setHours(lockClose[0]?.dataValueHour);
      processDate?.setMinutes(lockClose[0]?.dataValueMinute);
      processDate?.setSeconds(lockClose[0]?.dataValueSecond);
      processDate?.setMilliseconds(0);

      let updatedRowData = [...rowData];
      updatedRowData[row.rowIndex].field_13 = +(event?.target?.value ?? 0);
      updatedRowData[row.rowIndex].field_5 = moment(processDate)
        .subtract(processMin, 'm')
        .format('DD.MM.YYYY HH:mm:ss');
      setRowData(updatedRowData);

      gridRef.current?.api?.refreshCells({ force: true });
    };
    return (
      <FormControl variant="standard" fullWidth={true} sx={{ padding: '0px' }}>
        <TextField
          onChange={onChange}
          value={row?.data?.field_13 ?? ''}
          sx={{
            '& .MuiInputBase-input': {
              paddingTop: '0px',
              fontSize: '12px',
            },
          }}
          variant="standard"
        />
      </FormControl>
    );
  };

  const RenderolumnCloseSettingsMinuteList = (row: any) => {
    const onChange = (event: any) => {
      gridRefList?.current?.api?.forEachNode((node) => {
        if (node.data.id === row.node.data.id) {
          let processMin = 0;
          if (
            event?.target?.value === null ||
            event?.target?.value === undefined ||
            event?.target?.value === '' ||
            Number.isNaN(Number(event?.target?.value))
          ) {
            processMin = 0;
          } else {
            processMin = Number(event?.target?.value);
          }

          let processDate = new Date();
          processDate?.setHours(lockClose[0]?.dataValueHour);
          processDate?.setMinutes(lockClose[0]?.dataValueMinute);
          processDate?.setSeconds(lockClose[0]?.dataValueSecond);
          processDate?.setMilliseconds(0);

          node.setDataValue('field_13', +(event.target.value ?? 0));
          node.setDataValue(
            'field_5',
            moment(processDate).subtract(processMin, 'm').format('DD.MM.YYYY HH:mm:ss')
          );

          gridRef.current?.api?.refreshCells({ force: true });
        }
      });
    };
    return (
      <FormControl variant="standard" fullWidth={true} sx={{ padding: '0px' }}>
        <TextField
          onChange={onChange}
          value={row?.data?.field_13 ?? ''}
          sx={{
            '& .MuiInputBase-input': {
              paddingTop: '0px',
              fontSize: '12px',
            },
          }}
          variant="standard"
        />
      </FormControl>
    );
  };

  const RenderColumnSenary = (row: any) => {
    const [selectedValue, setSelectedValue] = useState<any>();

    const onChange = (event: any) => {
      setSelectedValue(event.target.value);

      let processDate = new Date();
      processDate?.setHours(event.target.value?.dataValueHour);
      processDate?.setMinutes(event.target.value?.dataValueMinute);
      processDate?.setSeconds(event.target.value?.dataValueSecond);
      processDate?.setMilliseconds(0);

      let updatedRowData = [...rowData];
      updatedRowData[row.rowIndex].field_6 = event.target.value ?? '';
      updatedRowData[row.rowIndex].field_8 = moment(processDate).format('DD.MM.YYYY HH:mm:ss');
      setRowData(updatedRowData);

      //refresh grid
      gridRef.current?.api?.refreshCells({ force: true });
    };

    return (
      <FormControl variant="standard" fullWidth={true} sx={{ padding: '0px' }}>
        <Select
          value={selectedValue ?? ''}
          onChange={onChange}
          disableUnderline={true}
          input={<OutlinedInput label="Tag" />}
          sx={{
            padding: '0px',
            '& .MuiSelect-select': {
              padding: '4px',
              border: '0px solid transparent',
              width: '100%',
            },
            '& .uiInputBase-colorPrimary': {
              ':hover': {
                border: '0px solid transparent',
              },
            },
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '& .MuiOutlinedInput-input': {
              fontSize: '12px',
              padding: '0 24px 0 0',
            },
            '& .MuiTypography-root': {
              fontSize: '12px',
            },
          }}
        >
          {lockClose.map((item, index) => (
            <MenuItem key={index} value={item} style={{ padding: '4px' }} sx={{ fontSize: '12px' }}>
              <ListItemText primary={item.description} style={{ fontSize: '11px' }} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const RenderColumnSenaryList = (row: any) => {
    const [selectedValue, setSelectedValue] = useState<any>(row?.data?.field_6 ?? '');

    const onChange = (event: any) => {
      setSelectedValue(event.target.value);

      gridRefList?.current?.api?.forEachNode((node) => {
        if (node.data.id === row.node.data.id) {
          let processDate = new Date();
          processDate?.setHours(lockClose[0]?.dataValueHour);
          processDate?.setMinutes(lockClose[0]?.dataValueMinute);
          processDate?.setSeconds(lockClose[0]?.dataValueSecond);
          processDate?.setMilliseconds(0);

          node.setDataValue('field_6', event.target.value ?? '');
          node.setDataValue('field_8', moment(processDate).format('DD.MM.YYYY HH:mm:ss'));

          gridRefList.current?.api?.refreshCells({ force: true });
        }
      });

      gridRef.current?.api?.refreshCells({ force: true });
    };

    return (
      <FormControl variant="standard" fullWidth={true} sx={{ padding: '0px' }}>
        <Select
          value={selectedValue ?? ''}
          onChange={onChange}
          disableUnderline={true}
          input={<OutlinedInput label="Tag" />}
          sx={{
            padding: '0px',
            '& .MuiSelect-select': {
              padding: '4px',
              border: '0px solid transparent',
              width: '100%',
            },
            '& .uiInputBase-colorPrimary': {
              ':hover': {
                border: '0px solid transparent',
              },
            },
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '& .MuiOutlinedInput-input': {
              fontSize: '12px',
              padding: '0 24px 0 0',
            },
            '& .MuiTypography-root': {
              fontSize: '12px',
            },
          }}
        >
          {lockClose.map((item, index) => (
            <MenuItem key={index} value={item} style={{ padding: '4px' }} sx={{ fontSize: '12px' }}>
              <ListItemText primary={item.description} style={{ fontSize: '11px' }} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const RenderColumnSeptenary = (row: any) => {
    const onChange = (event: any) => {
      let processMin = 0;
      if (
        event?.target?.value === null ||
        event?.target?.value === undefined ||
        event?.target?.value === '' ||
        Number.isNaN(Number(event?.target?.value))
      ) {
        processMin = 0;
      } else {
        processMin = Number(event?.target?.value);
      }

      let processDate = new Date();
      processDate?.setHours(lockClose[0]?.dataValueHour);
      processDate?.setMinutes(lockClose[0]?.dataValueMinute);
      processDate?.setSeconds(lockClose[0]?.dataValueSecond);
      processDate?.setMilliseconds(0);

      let updatedRowData = [...rowData];
      updatedRowData[row.rowIndex].field_7 = +(event?.target?.value ?? 0);
      updatedRowData[row.rowIndex].field_8 = moment(processDate)
        .add(processMin, 'm')
        .format('DD.MM.YYYY HH:mm:ss');
      setRowData(updatedRowData);

      gridRef.current?.api?.refreshCells({ force: true });
    };
    return (
      <FormControl variant="standard" fullWidth={true} sx={{ padding: '0px' }}>
        <TextField
          onChange={onChange}
          sx={{
            '& .MuiInputBase-input': {
              paddingTop: '0px',
              fontSize: '12px',
            },
          }}
          variant="standard"
        />
      </FormControl>
    );
  };

  const RenderColumnSeptenaryList = (row: any) => {
    const onChange = (event: any) => {
      gridRefList?.current?.api?.forEachNode((node) => {
        if (node.data.id === row.node.data.id) {
          let processMin = 0;
          if (
            event?.target?.value === null ||
            event?.target?.value === undefined ||
            event?.target?.value === '' ||
            Number.isNaN(Number(event?.target?.value))
          ) {
            processMin = 0;
          } else {
            processMin = Number(event?.target?.value);
          }

          let processDate = new Date();
          processDate?.setHours(lockClose[0]?.dataValueHour);
          processDate?.setMinutes(lockClose[0]?.dataValueMinute);
          processDate?.setSeconds(lockClose[0]?.dataValueSecond);
          processDate?.setMilliseconds(0);

          node.setDataValue('field_7', +(event?.target?.value ?? 0));
          node.setDataValue(
            'field_8',
            moment(processDate).add(processMin, 'm').format('DD.MM.YYYY HH:mm:ss')
          );

          gridRef.current?.api?.refreshCells({ force: true });
        }
      });
    };

    return (
      <FormControl variant="standard" fullWidth={true} sx={{ padding: '0px' }}>
        <TextField
          onChange={onChange}
          value={row?.data?.field_7 ?? ''}
          sx={{
            '& .MuiInputBase-input': {
              paddingTop: '0px',
              fontSize: '12px',
            },
          }}
          variant="standard"
        />
      </FormControl>
    );
  };

  const RenderColumnNonary = (row: any) => {
    const onChange = (event: any) => {
      let processMin = 0;
      if (
        event?.target?.value === null ||
        event?.target?.value === undefined ||
        event?.target?.value === '' ||
        Number.isNaN(Number(event?.target?.value))
      ) {
        processMin = 0;
      } else {
        processMin = Number(event?.target?.value);
      }

      let processDate = new Date();
      processDate?.setHours(lockClose[0]?.dataValueHour);
      processDate?.setMinutes(lockClose[0]?.dataValueMinute);
      processDate?.setSeconds(lockClose[0]?.dataValueSecond);
      processDate?.setMilliseconds(0);

      let updatedRowData = [...rowData];
      processDate = moment(processDate).subtract(processMin, 'm').toDate();
      processDate = moment(processDate)
        .subtract(updatedRowData[row?.rowIndex]?.field_13 ?? 0, 'm')
        .toDate();
      updatedRowData[row.rowIndex].field_9 = +(event?.target?.value ?? 0);
      updatedRowData[row.rowIndex].field_10 = moment(processDate).format('DD.MM.YYYY HH:mm:ss');
      setRowData(updatedRowData);

      gridRef.current?.api?.refreshCells({ force: true });
    };
    return (
      <FormControl variant="standard" fullWidth={true} sx={{ padding: '0px' }}>
        <TextField
          value={row?.data?.field_9 ?? ''}
          onChange={onChange}
          sx={{
            '& .MuiInputBase-input': {
              paddingTop: '0px',
              fontSize: '12px',
            },
          }}
          variant="standard"
        />
      </FormControl>
    );
  };

  const RenderColumnNonaryList = (row: any) => {
    const onChange = (event: any) => {
      gridRefList?.current?.api?.forEachNode((node) => {
        if (node.data.id === row.node.data.id) {
          let processMin = 0;
          if (
            event?.target?.value === null ||
            event?.target?.value === undefined ||
            event?.target?.value === '' ||
            Number.isNaN(Number(event?.target?.value))
          ) {
            processMin = 0;
          } else {
            processMin = Number(event?.target?.value);
          }

          let processDate = new Date();
          processDate?.setHours(lockClose[0]?.dataValueHour);
          processDate?.setMinutes(lockClose[0]?.dataValueMinute);
          processDate?.setSeconds(lockClose[0]?.dataValueSecond);
          processDate?.setMilliseconds(0);

          processDate = moment(processDate).subtract(processMin, 'm').toDate();
          processDate = moment(processDate)
            .subtract(+node?.data?.field_13 ?? 0, 'm')
            .toDate();
          node.setDataValue('field_9', +(event.target.value ?? 0));
          node.setDataValue('field_10', moment(processDate).format('DD.MM.YYYY HH:mm:ss'));

          gridRef.current?.api?.refreshCells({ force: true });
        }
      });
    };
    return (
      <FormControl variant="standard" fullWidth={true} sx={{ padding: '0px' }}>
        <TextField
          onChange={onChange}
          value={row?.data?.field_9 ?? ''}
          sx={{
            '& .MuiInputBase-input': {
              paddingTop: '0px',
              fontSize: '12px',
            },
          }}
          variant="standard"
        />
      </FormControl>
    );
  };

  const RenderColumnUndenary = (row: any) => {
    const onChange = (event: any) => {
      let processVal = 0;
      if (
        event?.target?.value === null ||
        event?.target?.value === undefined ||
        event?.target?.value === '' ||
        Number.isNaN(Number(event?.target?.value))
      ) {
        processVal = 0;
      } else {
        processVal = Number(event?.target?.value);
      }

      let updatedRowData = [...rowData];
      updatedRowData[row.rowIndex].field_11 = processVal;
      setRowData(updatedRowData);

      gridRef.current?.api?.refreshCells({ force: true });
    };
    return (
      <FormControl variant="standard" fullWidth={true} sx={{ padding: '0px' }}>
        <TextField
          onChange={onChange}
          sx={{
            '& .MuiInputBase-input': {
              paddingTop: '0px',
              fontSize: '12px',
            },
          }}
          variant="standard"
        />
      </FormControl>
    );
  };

  const RenderColumnUndenaryList = (row: any) => {
    const onChange = (event: any) => {
      gridRefList?.current?.api?.forEachNode((node) => {
        if (node.data.id === row.node.data.id) {
          let processVal = 0;
          if (
            event?.target?.value === null ||
            event?.target?.value === undefined ||
            event?.target?.value === '' ||
            Number.isNaN(Number(event?.target?.value))
          ) {
            processVal = 0;
          } else {
            processVal = Number(event?.target?.value);
          }

          node.setDataValue('field_11', processVal ?? '');

          gridRef.current?.api?.refreshCells({ force: true });
        }
      });
    };

    return (
      <FormControl variant="standard" fullWidth={true} sx={{ padding: '0px' }}>
        <TextField
          onChange={onChange}
          value={row?.data?.field_11 ?? ''}
          sx={{
            '& .MuiInputBase-input': {
              paddingTop: '0px',
              fontSize: '12px',
            },
          }}
          variant="standard"
        />
      </FormControl>
    );
  };

  const RenderColumnDuodenary = (row: any) => {
    const onChange = (event: any) => {
      let processVal = 0;
      if (
        event?.target?.value === null ||
        event?.target?.value === undefined ||
        event?.target?.value === '' ||
        Number.isNaN(Number(event?.target?.value))
      ) {
        processVal = 0;
      } else {
        processVal = Number(event?.target?.value);
      }

      let updatedRowData = [...rowData];
      updatedRowData[row.rowIndex].field_12 = processVal;
      setRowData(updatedRowData);

      gridRef.current?.api?.refreshCells({ force: true });
    };
    return (
      <FormControl variant="standard" fullWidth={true} sx={{ padding: '0px' }}>
        <TextField
          onChange={onChange}
          sx={{
            '& .MuiInputBase-input': {
              paddingTop: '0px',
              fontSize: '12px',
            },
          }}
          variant="standard"
        />
      </FormControl>
    );
  };

  const RenderColumnDuodenaryList = (row: any) => {
    const onChange = (event: any) => {
      gridRefList?.current?.api?.forEachNode((node) => {
        if (node.data.id === row.node.data.id) {
          let processVal = 0;
          if (
            event?.target?.value === null ||
            event?.target?.value === undefined ||
            event?.target?.value === '' ||
            Number.isNaN(Number(event?.target?.value))
          ) {
            processVal = 0;
          } else {
            processVal = Number(event?.target?.value);
          }
          node.setDataValue('field_12', processVal ?? '');

          gridRef.current?.api?.refreshCells({ force: true });
        }
      });
    };
    return (
      <FormControl variant="standard" fullWidth={true} sx={{ padding: '0px' }}>
        <TextField
          onChange={onChange}
          value={row?.data?.field_12 ?? ''}
          sx={{
            '& .MuiInputBase-input': {
              paddingTop: '0px',
              fontSize: '12px',
            },
          }}
          variant="standard"
        />
      </FormControl>
    );
  };

  //#endregion Grid

  const loadFacilityGopSettings = async () => {
    if (!(selectedConsumers?.length > 0)) return;
    let request = {
      facilityIds: selectedConsumers.map((facility) => facility.id),
      userId: appuserid,
    };

    const response = await consumptionForcastService.getFacilityGopSettings(request);

    if (response?.statusCode === 200) {
      let tempRowDataList: any = [];
      response.data.forEach((item) => {
        tempRowDataList.push({
          id: item.facilityId,
          field_1: item.isActive,
          field_2: item.facilityName,
          field_3: 'Tümü',
          field_4: item.baseGopLockClosingId === 0 ? lockClose[0] : '',
          field_13: item.closingSettingMinute,
          field_5: item.closingSettingPreview
            ? moment(item.closingSettingPreview).format('DD.MM.YYYY HH:mm:ss')
            : '',
          field_6: item.baseGopLockOpeningId === 0 ? lockClose[0] : '',
          field_7: item.openingSettingMinute,
          field_8: item.openingPreview
            ? moment(item.openingPreview).format('DD.MM.YYYY HH:mm:ss')
            : '',
          field_9: item.mailSettingMinute,
          field_10: item.mailSendingStartDate
            ? moment(item.mailSendingStartDate).format('DD.MM.YYYY HH:mm:ss')
            : '',
          field_11: item.mailSendingRepeatCount,
          field_12: item.mailSendingIntervalMinute,
        });
      });

      setRowDataList(tempRowDataList ?? []);

      //refresh grid
      gridRefList?.current?.api?.refreshCells({ force: true });
    }
  };

  const saveFacilityGopSettings = async () => {
    let processDate = new Date();
    processDate?.setHours(lockClose[0]?.dataValueHour);
    processDate?.setMinutes(lockClose[0]?.dataValueMinute);
    processDate?.setSeconds(lockClose[0]?.dataValueSecond);
    processDate?.setMilliseconds(0);

    let request = {} as any;
    request.facilityGopSettings = [] as any;

    if (!(rowDataList?.length > 0)) return;
    rowDataList?.forEach((item) => {
      request.facilityGopSettings.push({
        isActive: item.field_1,
        facilityId: item.id,
        facilityName: '',
        baseGopLockClosingId: ConsumptionGopLockType.EPIAS_GOP_KAPANIS,
        closingSettingMinute: +item.field_13 ?? 0,
        closingSettingPreview: moment(processDate)
          .add(+item.field_13, 'm')
          .toDate(),
        baseGopLockOpeningId: ConsumptionGopLockType.EPIAS_GOP_KAPANIS,
        openingSettingMinute: +(item.field_7 ?? 0),
        openingPreview: moment(processDate)
          .add(+item.field_7, 'm')
          .toDate(),
        mailSettingMinute: +item.field_9 ?? 0,
        mailSendingStartDate: moment(processDate)
          .add(+item.field_9, 'm')
          .add(+item.field_7, 'm')
          .toDate(),
        mailSendingRepeatCount: +item.field_11 ?? 0,
        mailSendingIntervalMinute: +item.field_12 ?? 0,
      });
    });
    request.userId = appuserid;

    const result = await consumptionForcastService.saveFacilityGopSettings(request);

    if (result?.statusCode === 200) {
      Utils.showSuccessMessage('Ayarlarınız başarıyla yüklendi.');
    } else {
      Utils.showErrorMessage('Ayarlarınız yüklenirken bir hata oluştu.');
    }
  };

  const applyFacilityGopSettings = async () => {
    const processRow = rowData[0];

    let tempRowDataList: any = [];

    rowDataList?.forEach((item) => {
      tempRowDataList.push({
        id: item.id,
        field_1: processRow.field_1,
        field_2: item.field_2,
        field_3: processRow.field_3,
        field_4: processRow.field_4,
        field_13: processRow.field_13,
        field_5: processRow.field_5,
        field_6: processRow.field_6,
        field_7: processRow.field_7,
        field_8: processRow.field_8,
        field_9: processRow.field_9,
        field_10: processRow.field_10,
        field_11: processRow.field_11,
        field_12: processRow.field_12,
      });
    });

    setRowDataList(tempRowDataList);

    //refresh grid
    gridRefList?.current?.api?.refreshCells({ force: true });
  };

  useEffect(() => {
    setColumnDefs(columnDefs);
    setColumnDefsList(columnDefinitionsList);
  }, []);

  useEffect(() => {
    loadFacilityGopSettings();
  }, [selectedConsumers]);

  useEffect(() => {
    if (saveButonClickCount > 0) saveFacilityGopSettings();
  }, [saveButonClickCount]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} display="flex" justifyContent={'flex-start'}>
          <Typography sx={{ fontSize: '14px' }}>Toplu Ayar Atama</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} display="flex" justifyContent={'flex-end'}>
          <Button
            variant="contained"
            color="success"
            onClick={applyFacilityGopSettings}
            style={{ marginRight: '5px' }}
          >
            <Typography style={{ color: '#fff' }}>Uygula</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div style={{ height: gridHeight }} className="ag-theme-balham">
            <WrappedGrid
              ref={gridRef}
              gridOptions={gridOptions}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              rowSelection={'single'}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider sx={{ mt: 2, mb: 2 }} />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} display="flex" justifyContent={'flex-start'}>
          <Typography sx={{ fontSize: '14px' }}>Ayar Özeti</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div style={{ height: '300px' }} className="ag-theme-balham">
            {selectedConsumers?.length > 0 ? (
              <WrappedGrid
                ref={gridRefList}
                gridOptions={gridOptionsList}
                rowData={rowDataList}
                columnDefs={columnDefsList}
                defaultColDef={defaultColDef}
                onGridReady={onGridReadyList}
             />
            ) : (
              <Typography sx={{ fontSize: '12px' }}>Tesis seçiniz...</Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ConsumptionForecastLockGop;
