// @mui
import { Box, Button, Card, CardContent, CardHeader, Container, FormControlLabel, Grid, IconButton, MenuItem, Stack, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

// components
import Page from '../../components/Page';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import * as Utils from '../../utils'

import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";

import { Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '@ag-grid-community/styles/ag-grid.min.css';
import '@ag-grid-community/styles/ag-theme-balham.min.css'
import FilterListIcon from '@mui/icons-material/FilterList';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PublishIcon from '@mui/icons-material/Publish';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import useLocales from '../../hooks/useLocales';
import { GridLayoutModel } from '../../models/grid-layout.model';
import WrappedGrid from 'src/components/grid';
import { ColDef } from '@ag-grid-community/core';

Chart.register(...registerables);


const ResponsiveReactGridLayout = WidthProvider(Responsive);

// ----------------------------------------------------------------------

const SmartPulseWorkspace = forwardRef((props, ref) => {

    const [layout, setLayout] = useState([] as GridLayoutModel[]);
    const [counter, setCounter] = useState(1)
    const { translate } = useLocales();
    const themeStretch = true
    const gridRefTicker = useRef<any>(null);
    const gridRefBuy = useRef<any>(null);
    const gridRefSell = useRef<any>(null);
    const gridRefGrid = useRef<any>(null);
    const gridRefOffer = useRef<any>(null);
    const [toggleOneSelected, setToggleOneSelected] = useState<string | null>('1');;
    const [toggleTwoSelected, setToggleTwoSelected] = useState<string | null>('1');;

    const handleToggleOne = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string | null,
    ) => {
        setToggleOneSelected(newValue);
    };

    const handleToggleTwo = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string | null,
    ) => {
        setToggleTwoSelected(newValue);
    };

    const onGridReadyTicker = useCallback((params) => {
        gridRefTicker.current!.api.sizeColumnsToFit();
    }, []);

    const onGridReadyBuy = useCallback((params) => {
        gridRefBuy.current!.api.sizeColumnsToFit();
    }, []);

    const onGridReadySell = useCallback((params) => {
        gridRefSell.current!.api.sizeColumnsToFit();
    }, []);

    const onGridReadyGrid = useCallback((params) => {
        gridRefGrid.current!.api.sizeColumnsToFit();
    }, []);

    const onGridReadyOffer = useCallback((params) => {
        gridRefOffer.current!.api.sizeColumnsToFit();
    }, []);

    const rowDataOffer = [
        {
            company: "NM Enerji Elektrik Üretim Ltd. Şti.",
            offerNumber: "886942031",
            status: "M",
            price: "3.625,01",
            amount: "5/0",
            totalMatch: "5",
            description: "SmartBot",
            user: "Monenerji",
            date: "10:27:11 (UTC +03:00)"
        },
        {
            company: "NM Enerji Elektrik Üretim Ltd. Şti.",
            offerNumber: "886942031",
            status: "M",
            price: "3.625,01",
            amount: "5/0",
            totalMatch: "5",
            description: "SmartBot",
            user: "Monenerji",
            date: "10:27:11 (UTC +03:00)"
        }
    ]

    const columnDefsOffer = [
        {
            headerName: "Şirket",
            field: "company",
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            sortable: true,
            resizable: true
        },
        {
            headerName: "Teklif No",
            field: "offerNumber",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        },
        {
            headerName: "Durum",
            field: "status",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        },
        {
            headerName: "Fiyat (₺/MWh)",
            field: "price",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        },
        {
            headerName: "Miktar / Kalan (Lot)",
            field: "amount",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        },
        {
            headerName: "Toplam Eşleşme (Lot)",
            field: "totalMatch",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        },
        {
            headerName: "Açıklama",
            field: "description",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        },
        {
            headerName: "User",
            field: "user",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        },
        {
            headerName: "Tarih",
            field: "date",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        }
    ]

    const rowDataTicker = [
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        },
        {
            period: "02:00",
            message: "Sistem yönü değişti",
            time: "03:30"
        }
    ]

    const columnDefsTicker :ColDef[]= [
        {
            headerName: "Period",
            field: "period",
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        },
        {
            headerName: "Message",
            field: "message",
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true,
            autoHeight: true
        },
        {
            headerName: "Time",
            field: "time",
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        }
    ]

    const rowDataBuy = [
        {
            cumAmount: "10",
            amount: "10",
            price: "500"
        }
    ]

    const columnDefsBuy = [
        {
            headerName: "Kümülatif Miktar (Lot)",
            field: "cumAmount",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        },
        {
            headerName: "Miktar (Lot)",
            field: "amount",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        },
        {
            headerName: "Fiyat (₺/MWh)",
            field: "price",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        }
    ]

    const rowDataSell = [
        {
            cumAmount: "3200",
            amount: "10",
            price: "10"
        },
        {
            cumAmount: "3300",
            amount: "10",
            price: "20"
        },
        {
            cumAmount: "3400",
            amount: "10",
            price: "30"
        },
        {
            cumAmount: "3500.02",
            amount: "119",
            price: "149"
        },
        {
            cumAmount: "3600.03",
            amount: "20",
            price: "169"
        },
        {
            cumAmount: "4150",
            amount: "11.160",
            price: "11.329"
        },
        {
            cumAmount: "4150",
            amount: "11.160",
            price: "11.329"
        },
        {
            cumAmount: "4150",
            amount: "11.160",
            price: "11.329"
        },
        {
            cumAmount: "4150",
            amount: "11.160",
            price: "11.329"
        },
        {
            cumAmount: "4150",
            amount: "11.160",
            price: "11.329"
        }
    ]

    const columnDefsSell = [
        {
            headerName: "Kümülatif Miktar (Lot)",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            field: "cumAmount",
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        },
        {
            headerName: "Miktar (Lot)",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            field: "amount",
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        },
        {
            headerName: "Fiyat (₺/MWh)",
            wrapHeaderText: true,
            autoHeaderHeight: true,
            field: "price",
            cellStyle: { fontSize: '10px' },
            wrapText: true,
            sortable: true,
            resizable: true
        }
    ]

    const rowDataGrid = [
        {
            hour: "16:00",
            ptf: "2.942,24",
            direction: "",
            netPosition: "",
            bilateralAgreement: "",
            upRegulation: "",
            downRegulation: ""
        }
    ]

    const columnDefsGrid = [
        {
            headerName: "Saat",
            field: "hour"
        },
        {
            headerName: "PTF",
            field: "ptf"
        },
        {
            headerName: "Yön",
            field: "direction"
        },
        {
            headerName: "Net Pozisyon",
            field: "netPosition"
        },
        {
            headerName: "İkili Anlaşma",
            field: "bilateralAgreement"
        },
        {
            headerName: "YAL",
            field: "upRegulation"
        },
        {
            headerName: "YAT",
            field: "downRegulation"
        }
    ]

    const chartData = {
        labels: ["500", "1000", "1500", "2000", "2500", "3000", "3500", "4000"],
        datasets: [
            {
                label: "Alış",
                data: [33, 53, 85, 41, 44, 65, 12, 23],
                fill: false,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "#7f9f8c"
            },
            {
                label: "PTF",
                data: [43, 25, 35, 51, 54, 76, 56, 12],
                fill: false,
                borderColor: "#58d68d"
            },
            {
                label: "Satış",
                data: [65, 34, 57, 45, 87, 82, 22, 33],
                fill: false,
                borderColor: "#ff7f7f"
            }
        ]
    };

    const chartOptions = {
        maintainAspectRatio: false,
        scales: {
            y: [{
                scaleLabel: {
                    labelString: 'Faction Points'
                }
            }],
            x: [{
                scaleLabel: {
                    labelString: 'Day'
                }
            }]
        }
    } as any


    useEffect(() => {
        addTicker();
        addBuySell();
        addGraph();
        addOffer();
        addGrid();
        addMyOffers();
    }, []);

    const removeWidget = (widget: string) => {
        setLayout(layout.filter(item => item.i !== widget));
    }

    const addTicker = () => {

        var item = new GridLayoutModel();
        item.i = "1";
        item.x = 10;
        item.y = 0;
        item.w = 2;
        item.h = 3;
        item.type = "a";
        item.static = true;

        setLayout((layout: any) => [...layout, item]);
    }

    const addBuySell = () => {

        var item = new GridLayoutModel();
        item.i = "2";
        item.x = 0;
        item.y = 3;
        item.w = 4;
        item.h = 2.5;
        item.type = "b";
        item.static = true;

        setLayout((layout: any) => [...layout, item]);
    }

    const addGraph = () => {

        var item = new GridLayoutModel();
        item.i = "3";
        item.x = 10;
        item.y = 3;
        item.w = 2;
        item.h = 2.5;
        item.type = "c";
        item.static = true;

        setLayout((layout: any) => [...layout, item]);
    }

    const addOffer = () => {
        var item = new GridLayoutModel();
        item.i = "4";
        item.x = 0;
        item.y = 0;
        item.w = 2;
        item.h = 3;
        item.type = "d";
        item.static = true;

        setLayout((layout: any) => [...layout, item]);
    }

    const addGrid = () => {
        var item = new GridLayoutModel();
        item.i = "5";
        item.x = 2;
        item.y = 0;
        item.w = 8;
        item.h = 3;
        item.type = "e";
        item.static = true;

        setLayout((layout: any) => [...layout, item]);
    }

    const addMyOffers = () => {
        var item = new GridLayoutModel();
        item.i = "6";
        item.x = 4;
        item.y = 3;
        item.w = 6;
        item.h = 3;
        item.type = "f";
        item.static = true;

        setLayout((layout: any) => [...layout, item]);
    }

    const onLayoutChange = (newLayout: any) => {

        for (let i = 0; i < layout.length; i++) {
            for (let j = 0; j < newLayout.length; j++) {
                if (layout[i].i == newLayout[j].i) {
                    newLayout[j].type = layout[i].type;
                    break;
                }
            }
        }

        setLayout(newLayout)

        if (gridRefTicker.current != null && gridRefTicker.current.api != null)
            gridRefTicker.current!.api.sizeColumnsToFit();

        if (gridRefBuy.current != null && gridRefBuy.current.api != null)
            gridRefBuy.current!.api.sizeColumnsToFit();

        if (gridRefSell.current != null && gridRefSell.current.api != null)
            gridRefSell.current!.api.sizeColumnsToFit();
    }

    const getTitle = (type: string) => {
        var result = ""

        if (type == "a") {
            result = "Ticker"
        }
        else if (type == "b") {
            result = "Derinlik Tablosu"
        }
        else if (type == "c") {
            result = "Derinlik Grafiği"
        }
        else if (type == "d") {
            result = "İşlemler"
        }
        else if (type == "e") {
            result = "Tablo"
        }
        else if (type == "f") {
            result = "Tekliflerim"
        }
        else {
            result = "unknown"
        }

        return result;
    }

    const generateDOM = () => {
        return _.map(layout, function (l, i) {
            return (
                <Card key={l.i} style={{ borderRadius: '0px' }}>
                    <CardHeader
                        style={{ backgroundColor: '#87c400', color: 'white', padding: '12px', height: '20px' }}
                        title={
                            <Typography gutterBottom variant="caption" style={{ marginTop: '5px' }}>
                                {
                                    getTitle(l.type)
                                }
                            </Typography>
                        }
                        titleTypographyProps={{
                            fontSize: 10,
                        }}
                    // action={
                    //     <IconButton aria-label="close" onClick={() => removeWidget(l.i)} style={{ marginTop: '-20px' }}>
                    //         <CloseIcon style={{ color: 'white', fontSize: '15px' }} />
                    //     </IconButton>
                    // }
                    />
                    <CardContent style={{ padding: '0px' }}>
                        <div>
                            {
                                l.type == 'a' &&
                                <>
                                    <div   style={{ width: '100%', height: '380px' }}>
                                        <Box>
                                            <Grid container spacing={1} padding={1}>
                                                <Grid item xs={7}>
                                                    <TextField
                                                        fullWidth
                                                        variant="standard"
                                                        select>
                                                        {
                                                            rowDataTicker.map((option: any) => (
                                                                <MenuItem
                                                                    key={option.time}
                                                                    value={option.time}
                                                                    sx={{
                                                                        mx: 1,
                                                                        my: 0.5,
                                                                        borderRadius: 0.75,
                                                                        typography: 'body2'
                                                                    }}
                                                                >
                                                                    {option.message}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <div style={{ float: 'right' }}>
                                                        <Button type="button" style={{ maxWidth: '35px', minWidth: '35px', marginRight: 3 }} variant="contained" onClick={() => console.log('burda')} size="small">
                                                            <FilterListIcon style={{ fontSize: '17px' }} />
                                                        </Button>
                                                        <Button type="button" style={{ maxWidth: '35px', minWidth: '35px' }} variant="contained" onClick={() => console.log('burda')} size="small">
                                                            <DeleteSweepIcon style={{ fontSize: '17px' }} />
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <WrappedGrid 
                                            onGridReady={onGridReadyTicker}
                                            ref={gridRefTicker}
                                            columnDefs={columnDefsTicker}
                                            rowData={rowDataTicker}
                                        />
                                    </div>
                                </>
                            }
                            {
                                l.type == 'b' &&
                                <>
                                    <Box>
                                        <Grid container spacing={1} padding={1}>
                                            <Grid item xs={6}>
                                                <div style={{ borderBottom: '2px solid green', textAlign: 'center', color: 'green' }}>
                                                    <Typography gutterBottom variant="subtitle2">
                                                        Alış
                                                    </Typography>
                                                </div>
                                                <div   style={{ width: '100%', height: '230px' }}>
                                                    <WrappedGrid 
                                                        onGridReady={onGridReadyBuy}
                                                        ref={gridRefBuy}
                                                        columnDefs={columnDefsBuy}
                                                        rowData={rowDataBuy}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div style={{ borderBottom: '2px solid red', textAlign: 'center', color: 'red' }}>
                                                    <Typography gutterBottom variant="subtitle2">
                                                        Satış
                                                    </Typography>
                                                </div>
                                                <div   style={{ width: '100%', height: '300px' }}>
                                                    <WrappedGrid 
                                                        onGridReady={onGridReadySell}
                                                        ref={gridRefSell}
                                                        columnDefs={columnDefsSell}
                                                        rowData={rowDataSell}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </>
                            }
                            {
                                l.type == 'c' &&
                                <>
                                    <Line options={chartOptions} style={{ height: '325px' }} data={chartData} />
                                </>
                            }
                            {
                                l.type == 'd' &&
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item
                                            xs={3}
                                            alignItems="center"
                                            justifyContent="center"
                                            textAlign="center">
                                            <div style={{ borderRight: '1px solid black', marginTop: '3px' }}>
                                                <LockOpenIcon style={{ color: 'green' }} />
                                                <Typography variant="caption">
                                                    15:00
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={9} paddingRight={"5px"}>
                                            <Typography variant="caption" fontWeight="bold">
                                                Adına İşlem yap
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                variant="filled"
                                                select>
                                                {
                                                    <MenuItem
                                                        key={"Lorem İpsum"}
                                                        value={"Lorem İpsum"}
                                                        sx={{
                                                            mx: 0.5,
                                                            my: 0.5,
                                                            borderRadius: 0.75,
                                                            typography: 'body2'
                                                        }}
                                                    >
                                                        {"Lorem İpsum"}
                                                    </MenuItem>
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1} padding={1}>
                                                <Grid item xs={6}>
                                                    <div style={{ borderBottom: '2px solid green', textAlign: 'center', color: 'green' }}>
                                                        <Typography gutterBottom style={{ fontSize: '12px' }}>
                                                            Alış
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <Typography gutterBottom style={{ fontSize: '12px' }}>
                                                            Satış
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography gutterBottom variant="caption">
                                                        Fiyat (₺ / MWh)
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        variant="standard"
                                                        type="number"
                                                    />
                                                    <Grid container spacing={1} marginTop={"10px"} marginBottom={"10px"}>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item >
                                                                    <Button type="button" fullWidth variant="contained" onClick={() => console.log("")} size="small" style={{
                                                                        backgroundColor: "#d9d9d9",
                                                                        color: "#000000"
                                                                    }}>
                                                                        <Typography style={{ fontSize: '8px' }}>
                                                                            BUYP
                                                                        </Typography>
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item >
                                                                    <Button type="button" fullWidth variant="contained" onClick={() => console.log("")} style={{
                                                                        backgroundColor: "#d9d9d9",
                                                                        color: "#000000"
                                                                    }} size="small">
                                                                        <Typography style={{ fontSize: '8px' }}>
                                                                            PTF*0.97
                                                                        </Typography>
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item >
                                                                    <Button type="button" fullWidth variant="contained" style={{
                                                                        backgroundColor: "#d9d9d9",
                                                                        color: "#000000"
                                                                    }} onClick={() => console.log("")} size="small">
                                                                        <Typography style={{ fontSize: '8px' }}>
                                                                            PTF
                                                                        </Typography>
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item >
                                                                    <Button type="button" fullWidth variant="contained" style={{
                                                                        backgroundColor: "#d9d9d9",
                                                                        color: "#000000"
                                                                    }} onClick={() => console.log("")} size="small">
                                                                        <Typography style={{ fontSize: '8px' }}>
                                                                            PTF*1.03
                                                                        </Typography>
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item >
                                                                    <Button type="button" fullWidth variant="contained" style={{
                                                                        backgroundColor: "#d9d9d9",
                                                                        color: "#000000"
                                                                    }} onClick={() => console.log("")} size="small">
                                                                        <Typography style={{ fontSize: '8px' }}>
                                                                            SELP
                                                                        </Typography>
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography gutterBottom variant="caption">
                                                        Miktar (Lot)
                                                    </Typography>
                                                    <TextField
                                                        variant="standard"
                                                        fullWidth
                                                        size="small"
                                                        type="number"
                                                    />
                                                    <Grid container spacing={1} marginTop={"10px"}>
                                                        <Grid item xs={4}>
                                                            <Button type="button" fullWidth variant="contained" onClick={() => console.log("")} size="small" style={{
                                                                backgroundColor: "#d9d9d9",
                                                                color: "#000000"
                                                            }}>
                                                                <Typography style={{ fontSize: '10px' }}>
                                                                    BUYQ
                                                                </Typography>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Button type="button" fullWidth variant="contained" onClick={() => console.log("")} style={{
                                                                backgroundColor: "#d9d9d9",
                                                                color: "#000000"
                                                            }} size="small">
                                                                <Typography style={{ fontSize: '10px' }}>
                                                                    SELLQ
                                                                </Typography>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Button type="button" fullWidth variant="contained" style={{
                                                                backgroundColor: "#d9d9d9",
                                                                color: "#000000"
                                                            }} onClick={() => console.log("")} size="small">
                                                                <Typography style={{ fontSize: '10px' }}>
                                                                    NETP
                                                                </Typography>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    <Button type="button" style={{ marginTop: "10px" }} variant="contained" fullWidth onClick={() => console.log("burda")}>
                                                        <PublishIcon style={{ fontSize: '15px' }} />
                                                        <Typography style={{ fontSize: '12px' }}>
                                                            Al
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                            {
                                l.type == 'e' &&
                                <>
                                    <div   style={{ width: '100%', height: '400px' }}>
                                        <WrappedGrid 
                                            onGridReady={onGridReadyGrid}
                                            ref={gridRefGrid}
                                            columnDefs={columnDefsGrid}
                                            rowData={rowDataGrid}
                                        />
                                    </div>
                                </>
                            }
                            {
                                l.type == 'f' &&
                                <>
                                    <div>
                                        <Grid container spacing={0} style={{ height: '100%', overflowY: 'scroll' }}>
                                            <Grid item
                                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', margin: '10px' }}
                                                xs={12}>
                                                <AccessTimeIcon />
                                                <Typography variant="caption" fontWeight="bold">
                                                    Güniçi SAATLİK Tekliflerim
                                                </Typography>
                                                <ToggleButtonGroup color="primary"
                                                    size="small"
                                                    value={toggleOneSelected}
                                                    style={{ marginLeft: '10px' }}>
                                                    <ToggleButton value="1">
                                                        A + M
                                                    </ToggleButton>
                                                    <ToggleButton value="2">
                                                        A
                                                    </ToggleButton>
                                                    <ToggleButton value="3">
                                                        M
                                                    </ToggleButton>
                                                    <ToggleButton value="4">
                                                        TÜMÜ
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div   style={{ width: '100%', height: '120px' }}>
                                                    <WrappedGrid 
                                                        onGridReady={onGridReadyOffer}
                                                        ref={gridRefOffer}
                                                        columnDefs={columnDefsOffer}
                                                        rowData={rowDataOffer}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item
                                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', margin: '10px' }}
                                                xs={12}>
                                                <AccessTimeFilledIcon />
                                                <Typography variant="caption" fontWeight="bold">
                                                    Güniçi BLOK Tekliflerim
                                                </Typography>
                                                <ToggleButtonGroup color="primary"
                                                    size="small"
                                                    value={toggleTwoSelected}
                                                    style={{ marginLeft: '10px' }}>
                                                    <ToggleButton value="1">
                                                        A + M
                                                    </ToggleButton>
                                                    <ToggleButton value="2">
                                                        A
                                                    </ToggleButton>
                                                    <ToggleButton value="3">
                                                        M
                                                    </ToggleButton>
                                                    <ToggleButton value="4">
                                                        TÜMÜ
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div   style={{ width: '100%', height: '120px' }}>
                                                    <WrappedGrid 
                                                        onGridReady={onGridReadyOffer}
                                                        ref={gridRefOffer}
                                                        columnDefs={columnDefsOffer}
                                                        rowData={rowDataOffer}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </>
                            }
                        </div>
                    </CardContent>
                </Card >
            );
        });
    }

    return (
        <Box>
            <ResponsiveReactGridLayout
                className="layout"
                onLayoutChange={onLayoutChange}
                layouts={{
                    lg: layout
                }}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            >
                {
                    generateDOM()
                }
            </ResponsiveReactGridLayout>
        </Box>
    )
});

export default SmartPulseWorkspace;